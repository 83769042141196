import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import ImgRemove from "../../icons/remove.png";
import ImgEdit from "../../icons/edit.png";

function AssetsList({
    setCurrentItem,
    currentItem,
    assets,
    errors,
    paginationString,
    page,
    setPage,
    setSwitchActive,
    setDeleteItem,
    setPatchAsset,
}) {
    const dateStyles = {
        fontSize: "x-small",
    };

    const changeDate = (date) => {
        if (date) {
            const formatDate = date?.split("T");
            const reverseDate =
                formatDate[0].split("-").reverse().join(".") +
                " " +
                formatDate[1].split(".")[0];

            return reverseDate;
        } else return null;
    };
    const getActiveFlag = (device) => {
        if (device.active_flag !== 1)
            return <span>{device.firewall.serial_nr}</span>;
        else return <span>{device.firewall.serial_nr}</span>;
    };

 const changeDateAndCalculate=(date)=>{
const nowIso  = new Date().toISOString();
let color=''
const now  = new Date(nowIso)
const activityDate = new Date(date);
const calculate  = now.getTime() - activityDate.getTime();
if (calculate > 3600000) color = 'red'
else color = 'green'
return color
 }
    return (
        <>
            <Row>
                <Col className="col-3 mt-2 mb-2">
                    <b>Всего: {assets.length}шт.</b>
                </Col>
            </Row>

            <hr style={{ margin: "5px 0" }} />
            <Row style={{ margin: " 0 0 3px", padding: "0 0 0 5px" }}></Row>

            {
                <div
                    style={{
                        position: "relative",
                        minHeight: "375px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-striped table-bordered"
                            id="checkUrlResp"
                        >
                            <thead>
                                <tr style={dateStyles}>
                                    <th>SN</th>
                                    <th>Org</th>
                                    <th>Platform</th>
                                    <th>OS</th>
                                    {/* <th >Last Request{<br />} DateTime</th> */}
                                    <th>
                                        Last FileCheck {<br />}Datetime (UTC)
                                    </th>
                                    <th>Files / Requests{<br/>}( today )</th>
                                    <th>Suspicious{<br/>} files / requests{<br />} (today)
                                       
                                        </th>
                                    <th>Comment</th>
                                    <th>IP </th>
                                    <th>Зарегистрирован / {<br/>}
                                    Посл. активность (UTC)</th>
                                    <th style={{ width: "10%" }}>
                                        Activate/ {<br />} Deactivate
                                    </th>
                                    <th style={{ width: "5%" }}>Изменить</th>
                                    <th style={{ width: "5%" }}>Удалить</th>
                                </tr>
                            </thead>

                            <tbody
                                className="table-group-divider"
                                id={"chDevExt"}
                            >
                                {
                                    assets?.slice(page * 20, page * 20 + 20)
                                    .map((item, index) => (
                                        <tr
                                            key={item.id + "assetsUC"}
                                            style={dateStyles}
                                        >
                                            <td>{getActiveFlag(item)}</td>

                                            <td>
                                                {item.firewall.organisation}
                                            </td>

                                            <td>
                                                {item.firewall.platform_name}
                                            </td>

                                            <td>{item.firewall.os_version}</td>
                                            <td>
                                                {changeDate(
                                                    item.last_request_check_at
                                                )}
                                            </td>
                                            <td>{item.files_today || '0'} / {item.requests_today || '0'}</td>
                                            <td>{item.malware_files_today || '0'} / {item.malware_requests_today || '0'}</td>
                                            <td>{item.comment}</td>
                                            <td>
                                                {item.device_ip?.map(
                                                    (it, index) => (
                                                        <div
                                                            key={
                                                                "ip" +
                                                                index +
                                                                it
                                                            }
                                                        >
                                                            {it}
                                                            <br />
                                                        </div>
                                                    )
                                                )}
                                            </td>
                                            <td>
                                                {" "}
                                                { (
                                                  <div style={{display: "flex", gap: 5, alignItems: "center" }}><div  style={{
                                                    width: "10px",
                                                    height: "9px",
                                                    borderRadius: "50%",
                                                    background: changeDateAndCalculate(item.last_active),
                                                }}></div> <span
                                                        style={{color: changeDateAndCalculate(item.last_active)}}
                                                    >
                                                        {changeDate(item.last_active)}
                                                    </span></div> 
                                                ) }
                                            </td>
                                            <td>
                                                {
                                                    <Col className="col-2">
                                                        <div
                                                            className="form-check form-switch"
                                                            style={{
                                                                minHeight: 0,
                                                            }}
                                                        >
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="statusChecks"
                                                                checked={
                                                                    item.is_active ===
                                                                    !true
                                                                        ? false
                                                                        : true
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setSwitchActive(
                                                                        true
                                                                    );
                                                                    setCurrentItem(
                                                                        item
                                                                    );
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="flexSwitchCheckChecked"
                                                            ></label>
                                                        </div>
                                                    </Col>
                                                }{" "}
                                            </td>
                                            <td>
                                                <img
                                                    width="20"
                                                    className="interactive_hover"
                                                    alt={"edit"}
                                                    src={ImgEdit}
                                                    disabled
                                                    onClick={() => {
                                                        setCurrentItem(item);
                                                        setPatchAsset(true);
                                                    }}
                                                />
                                            </td>
                                            <td
                                                style={{
                                                    cursor: "pointer",
                                                    padding: "4px",
                                                }}
                                            >
                                                <img
                                                    width="20"
                                                    src={ImgRemove}
                                                    alt="delete"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDeleteItem(true);
                                                        setCurrentItem(item);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <nav aria-label="pagination" style={{ marginTop: "auto" }}>
                        <ul
                            className="pagination justify-content-center"
                            id="pagination-menu"
                            style={{ margin: "0, auto" }}
                        >
                            {assets && (
                                <li className="page-item">
                                    <button
                                        disabled={page === 0 ? true : false}
                                        className="page-link"
                                        aria-label="Previous"
                                        onClick={() => setPage(page - 1)}
                                    >
                                        <span aria-hidden="true">&laquo;</span>
                                    </button>{" "}
                                </li>
                            )}
                            {paginationString(assets?.length)}
                            {assets && (
                                <li className="page-item">
                                    <button
                                        disabled={
                                            page + 1 > assets.length / 20
                                                ? true
                                                : false
                                        }
                                        className="page-link"
                                        aria-label="Next"
                                        onClick={() => setPage(page + 1)}
                                    >
                                        <span aria-hidden="true">&raquo;</span>
                                    </button>{" "}
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            }
        </>
    );
}

export default AssetsList;
