import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Alert from "react-bootstrap/Alert";
import Dropdown from "react-bootstrap/Dropdown";
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import AddAssetForm from "./AddAssetForm";
import ImgRemove from "../../icons/remove.png";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import Preloader from "../UI/Preloader/Preloader";
import UrlService from "../../API/UrlService";
import touchIcon from '../../icons/touch.svg';
import DetailAssetArea from "./DetailAsset/DetailAssetArea";
import MyModal from "../UI/MyModal/MyModal";
import MyModalFeed from "../UI/MyModalFeed/MyModalFeed";
import './style.css'
import calendarIcon from '../../icons/calendar.svg'
function DeviceStats({
    deleteInstance,successAdd,setSuccessAdd,
    searchText,setSearchText,
    successActive,setSuccessActive,
    assetStats,assets,errors,
    paginationString,withRequest,
    setWithRequest,withoutOrg,setWithoutOrg,
    orderReq,setOrderReq,orderReqPlatform,setOrderReqPlatform,
    page,setPage,filteredArray,setFilteredArray,
    textFilter,withoutRequest,setWithoutRequest,
    setCurrentItem,currentItem,
    setDeleteItem,deleteItem,
    orderFeed, setOrderFeed,
    dateAfter, dateBefore, setDateAfter, setDateBefore,
    kfeedMode,setKfeedMode,withKfeed,setWithKfeed,
    orderPercent, setOrderPercent, errorsDelete, setErrorsDelete
}) {
    const [activeFilter, setActiveFilter] = useState(false);
    const assetsArr = filteredArray === null ? assets : filteredArray;
    const [addAsset, setAddAsset] = useState(false);
    const [isPreloader, setIsPreloader] = useState(false);
    const [activateItem, setActivateItem] = useState(false);
    const [openViewDetail, setOpenViewDetail]  = useState(false);
    const [switchFallback, setSwitchFallback] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    
    const dateStyles = {
        fontSize: "x-small",
    };
    const titleStyles = {
        textAlign: "center",
        margin: "0 0 10px",
        padding: "0",
        fontSize: "20px",
        backgroundColor: "rgb(13, 110, 253, .2)",
    };

    const getActiveFlag = (device) => {
        if (device.is_active === true)
            return <span style={{ color: "green" }}>{device.sn}</span>;
        else return <span style={{ color: "red" }}>{device.sn}</span>;
    };
    const resetFilter = () => {
        setFilteredArray(null);
        setSearchText("");
        setActiveFilter(false);
        setWithoutRequest(false);
        setOrderReq(false);
        setOrderReqPlatform(false);
        setWithRequest(false);
        setWithoutOrg(false);
        setOrderFeed(false);
        setDateAfter(new Date().toISOString().replace('.', '-').split('T')[0]);
        setDateBefore(null);
        setWithKfeed(false);
        setKfeedMode(false);
        setOrderPercent(false);
    };
    const resetOrder = (filterOrderReq) => {
        setOrderReqPlatform(false);
        setOrderFeed(false)
        setOrderReq(filterOrderReq);
        setOrderPercent(false)

        textFilter(
            assets,
            searchText,
            activeFilter === "Активные"
                ? true
                : activeFilter === "Неактивные"
                ? false
                : null,
            withoutRequest,
            filterOrderReq,
            false,
            withRequest,
            withoutOrg, 
false,
            false,kfeedMode, withKfeed, false
        );
    };

    const resetOrderPlatform = (filterOrderPlatform) => {
        setOrderReq(false);
        setOrderReqPlatform(filterOrderPlatform);
        setOrderFeed(false);
        setOrderPercent(false)
        textFilter(
            assets,
            searchText,
            activeFilter === "Активные"
                ? true
                : activeFilter === "Неактивные"
                ? false
                : null,
            withoutRequest,
            false,
            filterOrderPlatform,
            withRequest,
            withoutOrg,
            false, false, kfeedMode, withKfeed, false
        );
    };
    const resetOrderFeed = (filterOrderFeed) => {
        setOrderReqPlatform(false);
        setOrderReq(false);
        setOrderFeed(filterOrderFeed)
        setOrderPercent(false)
        textFilter(
            assets,
            searchText,
            activeFilter === "Активные"
                ? true
                : activeFilter === "Неактивные"
                ? false
                : null,
            withoutRequest,
            false,
            false,
            withRequest,
            withoutOrg,
            false, filterOrderFeed, kfeedMode, withKfeed, false
        );
    };
    const resetOrderPercent = (filterOrderPercent) => {
        setOrderReqPlatform(false);
        setOrderReq(false);
        setOrderFeed(false)
        setOrderPercent(filterOrderPercent)
        textFilter(
            assets,
            searchText,
            activeFilter === "Активные"
                ? true
                : activeFilter === "Неактивные"
                ? false
                : null,
            withoutRequest,
            false,
            false,
            withRequest,
            withoutOrg,
            false, false, kfeedMode, withKfeed, filterOrderPercent
        );
    };
    const switchActiveAsset = async (item) => {
        setIsPreloader(true);
        let param = !item.is_active;
        await UrlService.switchActivate(item.sn, param)
            .then(() => {
                setIsPreloader(false);
                setActivateItem(false);
                setSuccessActive(!successActive);
            })
            .catch((e) => {
                setIsPreloader(false);
                setErrorsDelete(e.message);
            });
    };
    const switchFallbackAsset = async (item) => {
        setIsPreloader(true);
        let param = !item.urlf_can_set_fallbacks;
        await UrlService.switchFallbackPermissions(item.internal_asset_id, param)
            .then(() => {
                setIsPreloader(false);
                setSwitchFallback(false);
                setSuccessActive(!successActive);
            })
            .catch((e) => {
                setIsPreloader(false);
                setErrorsDelete(e.message);
            });
    };
const getDatePreset=(preset)=> {
    let date = ''
    if (preset === 'today') date = (new Date()).toISOString().replace('.', '-').split('T')[0];
    else {let today = new Date();
    today.setDate(today.getDate()-1); 
   date = today.toISOString().replace('.', '-').split('T')[0]}
    setDateAfter(date)
}
    return (
        <div onClick={()=> setShowCalendar(false)}> {openViewDetail && <MyModalFeed visible={openViewDetail} setVisible={setOpenViewDetail} >
            <DetailAssetArea sn={currentItem.sn} item={currentItem} setVisible={setOpenViewDetail} visible={openViewDetail}/>
            </MyModalFeed>}
            {deleteItem && (
                <MyConfirm visible={deleteItem} setVisible={setDeleteItem}>
                    <Row className="mb-4" style={{ position: "relative" }}>
                        {isPreloader && <Preloader leftCoord={true} />}

                        <h3 className="mb-4 mt-4">
                            Вы точно хотите удалить устройство?
                        </h3>
                    </Row>
                    <Row>
                        {" "}
                        {errorsDelete.length > 0 &&
                            errorsDelete.map((error) => (
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            ))}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                type="submit "
                                style={{ width: "100%" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    deleteInstance(currentItem.sn);
                                }}
                            >
                                Да
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setDeleteItem(false);
                                    setErrorsDelete([]);
                                }}
                                style={{ width: "100%" }}
                            >
                                нет
                            </Button>
                        </Col>
                    </Row>
                </MyConfirm>
            )}
             {switchFallback && (
                <MyConfirm visible={switchFallback} setVisible={setSwitchFallback}>
                    <Row className="mb-4" style={{ position: "relative" }}>
                        {isPreloader && <Preloader leftCoord={true} />}

                        <h3 className="mb-4 mt-4">
                            Вы точно хотите{" "}
                            {currentItem.urlf_can_set_fallbacks === false
                                ? "предоставить"
                                : "отключить"}{" "}
                            {<br />}  FALLBACK доступ {currentItem.sn}?
                        </h3>
                    </Row>
                    <Row>
                        {" "}
                        {errorsDelete.length > 0 &&
                            errorsDelete.map((error) => (
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            ))}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                type="submit "
                                style={{ width: "100%" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    switchFallbackAsset(currentItem);
                                }}
                            >
                                Да
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSwitchFallback(false);
                                    setErrorsDelete([]);
                                }}
                                style={{ width: "100%" }}
                            >
                                нет
                            </Button>
                        </Col>
                    </Row>
                </MyConfirm>
            )}
            {activateItem && (
                <MyConfirm visible={activateItem} setVisible={setActivateItem}>
                    <Row className="mb-4" style={{ position: "relative" }}>
                        {isPreloader && <Preloader leftCoord={true} />}

                        <h3 className="mb-4 mt-4">
                            Вы точно хотите{" "}
                            {currentItem.is_active === false
                                ? "активировать"
                                : "деактивировать"}{" "}
                            {<br />} устройство {currentItem.sn}?
                        </h3>
                    </Row>
                    <Row>
                        {" "}
                        {errorsDelete.length > 0 &&
                            errorsDelete.map((error) => (
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            ))}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                type="submit "
                                style={{ width: "100%" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    switchActiveAsset(currentItem);
                                }}
                            >
                                Да
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActivateItem(false);
                                    setErrorsDelete([]);
                                }}
                                style={{ width: "100%" }}
                            >
                                нет
                            </Button>
                        </Col>
                    </Row>
                </MyConfirm>
            )}
            <Row style={{ margin: " 0 0 10px" }}>
                <h4 style={titleStyles}>DEVICE TABLE</h4>
                <hr style={{ margin: "5px 0" }} />
            </Row>
            {errorsDelete.length > 0 &&
                errors.map((error) => (
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                ))}
            <Row style={{ margin: " 0 0 3px", padding: "0 0 0 5px" }}>
                <Col className="col-3">
                    Всего устройств:{" "}
                    {assetStats?.active_count + assetStats?.inactive_count}шт.
                </Col>
                <Col style={{ color: "green" }} className="col-2">
                    Активных: {assetStats?.active_count}шт.
                </Col>
                <Col style={{ color: "red" }} className="col-2">
                    Неактивных: {assetStats?.inactive_count}шт.
                </Col>
            </Row>

            <hr style={{ margin: "5px 0" }} />
            <Row style={{ margin: " 0 0 3px", padding: "0 0 0 5px" }}>
                {!addAsset && (
                    <Col className="col-2 mt-2 mb-2">
                        <Button
                            variant="outline-success"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={(e) => {
                                setAddAsset(true);
                            }}
                        >
                            Добавить
                        </Button>
                    </Col>
                )}
            </Row>
            {addAsset && (
                <AddAssetForm
                    setAddAsset={setAddAsset}
                    successAdd={successAdd}
                    setSuccessAdd={setSuccessAdd}
                />
            )}
            <Row style={{ margin: " 0 0 3px", padding: "0 0 0 5px" }}>
                <Col className="mb-3 col-2">
                    <Form.Label size="sm">Поиск</Form.Label>
                    <Form.Control
                        onChange={(e) => {
                            setSearchText(e.target.value);
                            textFilter(
                                assets,
                                e.target.value,
                                activeFilter === "Активные"
                                    ? true
                                    : activeFilter === "Неактивные"
                                    ? false
                                    : null,
                                withoutRequest,
                                orderReq,
                                orderReqPlatform,
                                withRequest,
                                withoutOrg, false, orderFeed, kfeedMode, withKfeed, orderPercent
                            );
                        }}
                        placeholder="Начните вводить..."
                        size="sm"
                        style={{ fontSize: "1rem", height: 38 }}
                        value={searchText}
                    />
                </Col>
                <Col className="mb-3 col-2">
                    <Form.Label size="sm">Статус</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle
                            as={MyToggleAsset}
                            id="dropdown-active"
                        >
                            {activeFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setActiveFilter}
                            type="statusfilter"
                            filterActiveAssets={setFilteredArray}
                            filterRequest={setWithoutRequest}
                        >
                            <Dropdown.Item
                                eventKey={"af" + 1}
                                key={"af" + 1}
                                onClick={() => {
                                    setActiveFilter("Активные");
                                    textFilter(
                                        assets,
                                        searchText,
                                        true,
                                        withoutRequest,
                                        orderReq,
                                        orderReqPlatform,
                                        withRequest,
                                        withoutOrg, false, orderFeed, kfeedMode, withKfeed, orderPercent
                                    );
                                }}
                            >
                                {"Активные"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"af" + 2}
                                key={"af" + 2}
                                onClick={() => {
                                    setActiveFilter("Неактивные");
                                    textFilter(
                                        assets,
                                        searchText,
                                        false,
                                        withoutRequest,
                                        orderReq,
                                        orderReqPlatform,
                                        withRequest,
                                        withoutOrg, false, orderFeed, kfeedMode, withKfeed, orderPercent
                                    );
                                }}
                            >
                                {"Неактивные"}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2">
                    <Form.Label size="sm">KFEED MODE</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle
                            as={MyToggleAsset}
                            id="dropdown-kmode"
                        >
                            {kfeedMode|| "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setKfeedMode}
                            type="kfeedfilter"
                            filterActiveAssets={setFilteredArray}
                            filterRequest={setWithoutRequest}
                        >
                            <Dropdown.Item
                                eventKey={"kmr" + 1}
                                key={"kmr" + 1}
                                onClick={() => {
                                    setKfeedMode("replacing");
                                    textFilter(
                                        assets,
                                        searchText,
                                        activeFilter === "Активные"
                                        ? true
                                        : activeFilter === "Неактивные"
                                        ? false
                                        : null,
                                        withoutRequest,
                                        orderReq,
                                        orderReqPlatform,
                                        withRequest,
                                        withoutOrg, false, orderFeed,'replacing', withKfeed, orderPercent
                                    );
                                }}
                            >
                                {"replacing"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"af" + 2}
                                key={"af" + 2}
                                onClick={() => {
                                    setKfeedMode("monitoring");
                                    textFilter(
                                        assets,
                                        searchText,
                                        activeFilter === "Активные"
                                        ? true
                                        : activeFilter === "Неактивные"
                                        ? false
                                        : null,
                                        withoutRequest,
                                        orderReq,
                                        orderReqPlatform,
                                        withRequest,
                                        withoutOrg, false, orderFeed, 'monitoring', withKfeed, orderPercent
                                    );
                                }}
                            >
                                {"monitoring"}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-1">
                    <Form.Label size="sm">Доступ KFEED</Form.Label>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckCheckedWithKfeed"
                            checked={withKfeed}

                            onChange={(e) => {
                                setWithKfeed(e.target.checked);
                                textFilter(
                                    assets,
                                    searchText,
                                    activeFilter === "Активные"
                                        ? true
                                        : activeFilter === "Неактивные"
                                        ? false
                                        : null,
                                    withoutRequest,
                                    orderReq,
                                    orderReqPlatform,
                                    withRequest,
                                    withoutOrg, false, orderFeed, kfeedMode, e.target.checked, orderPercent
                                );
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckChecked"
                        ></label>
                    </div>
                </Col>
                <Col className="mb-3 col-1">
                    <Form.Label size="sm">Без организации</Form.Label>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked={withoutOrg}
                            onChange={(e) => {
                                setWithoutOrg(e.target.checked);
                                textFilter(
                                    assets,
                                    searchText,
                                    activeFilter === "Активные"
                                        ? true
                                        : activeFilter === "Неактивные"
                                        ? false
                                        : null,
                                    withoutRequest,
                                    orderReq,
                                    orderReqPlatform,
                                    withRequest,
                                    e.target.checked, false, orderFeed, kfeedMode, withKfeed, orderPercent
                                );
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckChecked"
                        ></label>
                    </div>
                </Col>
                <Col className="mb-3 col-1">
                    <Form.Label size="sm">Без обращений</Form.Label>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            checked={withoutRequest}
                            disabled={
                                activeFilter === "Неактивные" || withRequest
                                    ? true
                                    : false
                            }
                            onChange={(e) => {
                                setWithoutRequest(e.target.checked);
                                textFilter(
                                    assets,
                                    searchText,
                                    activeFilter === "Активные"
                                        ? true
                                        : activeFilter === "Неактивные"
                                        ? false
                                        : null,
                                    e.target.checked,
                                    orderReq,
                                    orderReqPlatform,
                                    false,
                                    withoutOrg, false, orderFeed, kfeedMode, withKfeed, orderPercent
                                );
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckChecked"
                        ></label>
                    </div>
                </Col>
                <Col className="mb-3 col-1">
                    <Form.Label size="sm">С запросами</Form.Label>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckChecked"
                            checked={withRequest}
                            disabled={
                                activeFilter === "Неактивные" || withoutRequest
                                    ? true
                                    : false
                            }
                            onChange={(e) => {
                                setWithRequest(e.target.checked);
                                textFilter(
                                    assets,
                                    searchText,
                                    activeFilter === "Активные"
                                        ? true
                                        : activeFilter === "Неактивные"
                                        ? false
                                        : null,
                                    false,
                                    orderReq,
                                    orderReqPlatform,
                                    e.target.checked,
                                    withoutOrg, false, orderFeed, kfeedMode, withKfeed, orderPercent
                                );
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckChecked"
                        ></label>
                    </div>
                </Col>
               
                {(searchText ||
                    activeFilter ||
                    withRequest ||
                    withoutRequest ||
                    orderReq ||
                    orderReqPlatform || orderFeed ||( dateAfter!== new Date().toISOString().replace('.', '-').split('T')[0]) || dateBefore || kfeedMode || withKfeed || orderPercent) && (
                    <Col className="col-2">
                        <Button
                            variant="outline-info"
                            size="sm"
                            style={{
                                width: "100%",
                                height: "38px",
                                margin: "30px 0 0",
                            }}
                            onClick={() => resetFilter()}
                        >
                            Сбросить
                        </Button>
                    </Col>
                )}
            </Row>
            <Row style={{ margin: " 0 0 15px", padding: "0 0 0 5px" }}>
           {/* <Col className="mb-3 col-2">
                    <Form.Group  >
                        <Form.Label style={{fontSize: '12px'}}>После</Form.Label>
                        <Form.Control
                            disabled={false}
                           value={dateAfter || ""}
                           onChange={(e) => setDateAfter(e.target.value)}
                            size="sm" type="date"
                            />

                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2">
                    <Form.Group  >
                        <Form.Label style={{fontSize: '12px'}}>До</Form.Label>
                        <Form.Control
                            disabled={false}
                           value={dateBefore || ""}
                           onChange={(e) => setDateBefore(e.target.value)}
                            size="sm" type="date"
                             />

                    </Form.Group>
                </Col> */}
                <Col>
                <div style={{position: "relative", cursor: "pointer"}} onClick={(e)=> {e.stopPropagation(); setShowCalendar(!showCalendar)}}>
                <div><img style={{ width: 30, height: 30 }} src={calendarIcon} alt='date filter'></img><span style={{fontSize: 12}}>{dateAfter?.split('-').reverse().join('.')}{' '}{dateBefore ? '- ' + dateBefore?.split('-').reverse().join('.'): ''}</span> </div>
                    <div style={{border: '1px solid grey', padding: 16, position: "absolute", top: 40, width: 250,  background: 'white', zIndex: 2, display: showCalendar? "flex": "none", flexDirection: "column", borderRadius: '8px'}} onClick={(e)=>e.stopPropagation()}>
                        <span  className="calendarLinks" style={{marginTop: 10}} onClick={()=> {setDateBefore(null); getDatePreset('today')}}>
                            Сегодня
                        </span>
                        <span className="calendarLinks" onClick={()=> {setDateBefore(null); getDatePreset('yesterday')}}>
                          Вчера
                        </span>
                        <hr/>
                        <div style={{display: "flex", gap: 10, flexDirection: "column"}}>
                    <Form.Group  >
                        <Form.Label style={{fontSize: '12px'}}>После</Form.Label>
                        <Form.Control
                            disabled={false}
                           value={dateAfter || ""}
                           onChange={(e) => setDateAfter(e.target.value)}
                            size="sm" type="date"
                            />

                    </Form.Group>
                    <Form.Group  >
                        <Form.Label style={{fontSize: '12px'}}>До</Form.Label>
                        <Form.Control
                            disabled={false}
                           value={dateBefore || ""}
                           onChange={(e) => setDateBefore(e.target.value)}
                            size="sm" type="date"
                             />

                    </Form.Group></div>
                    </div>

  </div></Col>

           </Row>

            {
                <div
                    style={{
                        position: "relative",
                        minHeight: "375px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-striped table-bordered"
                            id="checkUrlResp"
                        >
                            <thead>
                                <tr style={dateStyles}>
                                    <th></th>
                                    <th>Номер</th>
                                    <th>Коммент</th>
                                    <th>
                                        Платформа (db){" "}
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        orderReqPlatform === "↑"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    orderReqPlatform !== "↑"
                                                        ? resetOrderPlatform(
                                                              "↑"
                                                          )
                                                        : resetOrderPlatform(
                                                              false
                                                          )
                                                }
                                            >
                                                ↑
                                            </span>
                                        }
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        orderReqPlatform === "↓"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    orderReqPlatform !== "↓"
                                                        ? resetOrderPlatform(
                                                              "↓"
                                                          )
                                                        : resetOrderPlatform(
                                                              false
                                                          )
                                                }
                                            >
                                                ↓
                                            </span>
                                        }
                                    </th>
                                    <th>Организация</th>
                                    <th>
                                      
                                        Запросы
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        orderReq === "↑"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    orderReq !== "↑"
                                                        ? resetOrder("↑")
                                                        : resetOrder(false)
                                                }
                                            >
                                                ↑
                                            </span>
                                        }
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        orderReq === "↓"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    orderReq !== "↓"
                                                        ? resetOrder("↓")
                                                        : resetOrder(false)
                                                }
                                            >
                                                ↓
                                            </span>
                                        }
                                    </th>
                                   
                                    <th>KFEED
                                    {
                                            <span
                                                style={{
                                                    color:
                                                        orderFeed === "↑"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    orderFeed !== "↑"
                                                        ? resetOrderFeed(
                                                              "↑"
                                                          )
                                                        : resetOrderFeed(
                                                              false
                                                          )
                                                }
                                            >
                                                ↑
                                            </span>
                                        }
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        orderFeed === "↓"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    orderFeed !== "↓"
                                                        ? resetOrderFeed(
                                                              "↓"
                                                          )
                                                        : resetOrderFeed(
                                                              false
                                                          )
                                                }
                                            >
                                                ↓
                                            </span>
                                        }
                                    </th>
                                    <th>{'%'}
                                    {
                                            <span
                                                style={{
                                                    color:
                                                        orderPercent === "↑"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    orderPercent !== "↑"
                                                        ? resetOrderPercent(
                                                              "↑"
                                                          )
                                                        : resetOrderPercent(
                                                              false
                                                          )
                                                }
                                            >
                                                ↑
                                            </span>
                                        }
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        orderPercent === "↓"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    orderPercent !== "↓"
                                                        ? resetOrderPercent(
                                                              "↓"
                                                          )
                                                        : resetOrderPercent(
                                                              false
                                                          )
                                                }
                                            >
                                                ↓
                                            </span>
                                        }</th>
                                    <th>FALLBACK<br/>доступ</th>
                                    <th>KFEED MODE</th>
                                    <th>Активно</th>
                                    <th>Удалить</th>
                                </tr>
                            </thead>
                            <tbody
                                className="table-group-divider"
                                id={"chDevExt"}
                            >
                                {assetsArr
                                    ?.slice(page * 20, page * 20 + 20)
                                    .map((item, index) => (
                                        <tr
                                            key={item.sn + "assetsSta"}
                                            style={dateStyles}
                                        >
                                            <td>
                                                <img
                                                    width="20"
                                                    className="img__asset"
                                                    src={touchIcon}
                                                    alt="linkToAsset"
                                                    onClick={() =>{
                                                        setCurrentItem(item);
                                                       setOpenViewDetail(true)}
                                                    }
                                                />
                                            </td>
                                            <td>{getActiveFlag(item)}</td>

                                            <td>{item.comment}</td>
                                            <td>{item.platform__name}</td>
                                            <td>{item.organisation__name}</td>
                                            <td
                                                style={
                                                    item.curr_day_req_cnt >
                                                    item.platform_curr_day_req_max
                                                        ? { color: "red" }
                                                        : { color: "black" }
                                                }
                                            >
                                                {item.curr_day_req_cnt} /{" "}
                                                {item.platform_curr_day_req_max}
                                            </td>
                                           
                                            <td>{item.kfeed_direct_hits} / {item.kfeed_secondary_hits}</td>
                                            <td>{item.kfeed_hits_total?  ((item.kfeed_direct_hits / item.kfeed_hits_total)*100).toFixed(2): '0.00'}</td>
                                            <td>
                                                <div
                                                    onClick={(evt) => {
                                                        evt.stopPropagation();
                                                        setCurrentItem(item);
                                                        setSwitchFallback(true);
                                                    }}
                                                    className="form-check form-switch"
                                                    style={{
                                                        minHeight: 0,
                                                    }}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="fallbackChecks"
                                                        readOnly={true}
                                                        checked={
                                                            item.urlf_can_set_fallbacks===
                                                            !true
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexSwitchCheckChecked"
                                                    >
                                                    </label>
                                                </div>
                                            </td>
                                            <td>{ 
                                                item.fallbacks?.length> 0 ? 
                                            item.fallbacks?.map(
                                                        (dev, index) => (
                                                            <Col
                                                                key={
                                                                    dev + "fallbacks" + 
                                                                    index
                                                                }
                                                            >
                                                                <span style={{ marginRight: 10, fontSize: 'x-small' }} className="badge bg-success"> {'Replacing'} </span>
                                                               
                                                            </Col>
                                                        )
                                                    ) : <span style={{ marginRight: 10, fontSize: 'x-small' }} className="badge bg-warning"> Monitoring</span>
                                                   
                                                }</td>
                                            <td>
                                                <div
                                                    onClick={(evt) => {
                                                        evt.stopPropagation();
                                                        setCurrentItem(item);
                                                        setActivateItem(true);
                                                    }}
                                                    className="form-check form-switch"
                                                    style={{
                                                        minHeight: 0,
                                                    }}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="statusChecks"
                                                        readOnly={true}
                                                        checked={
                                                            item.is_active ===
                                                            !true
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexSwitchCheckChecked"
                                                    >
                                                     
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    <img
                                                        width="20"
                                                        className="interactive_hover"
                                                        alt={"delete"}
                                                        src={ImgRemove}
                                                        onClick={(evt) => {
                                                            evt.stopPropagation();
                                                            setCurrentItem(
                                                                item
                                                            );
                                                            setDeleteItem(true);
                                                        }}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <nav aria-label="pagination" style={{ marginTop: "auto" }}>
                        <ul
                            className="pagination justify-content-center"
                            id="pagination-menu"
                            style={{ margin: "0, auto" }}
                        >
                            {assetsArr && (
                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        aria-label="Previous"
                                        style={{background: page===0 ? 'rgba(64, 60, 62, 0.09)': ''}}
                                        disabled={page===0}
                                        onClick={() =>
                                            page !== 0
                                                ? setPage(page - 1)
                                                : setPage(0)
                                        }
                                    >
                                        <span aria-hidden="true">&laquo;</span>
                                    </button>{" "}
                                </li>
                            )}
                            {paginationString(assetsArr?.length)}
                            {assetsArr && (
                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        aria-label="Next"
                                        style={{background:  !(page +1< (assetsArr?.length - 1) / 20 )? 'rgba(64, 60, 62, 0.09)': ''}}
                                        disabled={!(page +1< (assetsArr?.length - 1) / 20)}
                                        onClick={() =>
                                           
                                                 setPage(page + 1)
                                                
                                        }
                                    >
                                        <span aria-hidden="true">&raquo;</span>
                                    </button>{" "}
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            }
        </div>
    );
}

export default DeviceStats;
