import axios from 'axios';
import Api from '.';
import { limit } from '../utils/constants';

export default class WarehouseService {

    static async getList(id, filters, nextpage) {

        var url = 'api/v1/asset-equipment-on-warehouse/'

        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.platformFilter) {
                params.platform__name = filters.platformFilter.name;

            };
            if (filters.searchText) {
                params.search = filters.searchText;
            };
            if (filters.newFilter) {
                params.condition = filters.newFilter;
            };
            if (filters.whFilter) {
                params.equipment_location= filters.whFilter==='Да'? 'warehouse': 'tests';
            };

        };
        if (nextpage) {
            params.page = nextpage;
        }
        params.limit = limit
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async newAsset(asset) {

        const url = 'api/v1/asset-equipment-on-warehouse/';
        const method = 'POST';
        const data = asset;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async getStats() {

        const url = 'api/v1/asset-equipment-on-warehouse-stats/';
        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }


    static async getItem(id) {

        const url = `api/v1/asset-equipment-on-warehouse/${id}/`;
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }
    static async patchAsset(asset, id) {
        const url = `api/v1/asset-equipment-on-warehouse/${id}/`;
        const data = asset;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }



    static async deleteAsset(id) {
        const url = `api/v1/asset-equipment-on-warehouse/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }
    static async deletePhoto(photoId, assetId) {
        const url = `api/v1/asset-equipment-on-warehouse/${assetId}/remove_image/${photoId}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }
    static async downloadImage(url) {
    
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            responseType: 'arraybuffer'
        };

        const response = await axios.get(url, config);
        return response;
    }
    static async getExportExlsWarehouse() {
        var url = "api/v1/asset-equipment-on-warehouse/export/";
        const method = "GET";
        const params = {};
        const api = new Api({
            url: url,
            params: params,
            responseType: "arraybuffer",
        });
        const response = await api.request(method);
        return response.data;
    }
}