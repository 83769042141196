import React, { useState, useEffect, useContext } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import QuotaService from "../../API/QuotaService";
import MyModal from "../UI/MyModal/MyModal";
import CoreService from "../../core/CoreService";
import ContractService from "../../API/ContractService";
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import Alert from 'react-bootstrap/Alert';
import { paymentMethod, techStatus} from "../../utils/constants";
import OrgService from "../../API/OrgService";
import Preloader from "../UI/Preloader/Preloader";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";

const QuotaDetail = (props) => {
    registerLocale('ru', ru)
    const [deletingQuota, setDeletingQuota] = useState(false);
    const [contractDate, setContractDate] = useState(CoreService.addMonths(new Date(), 0));
    const [contractEndDate, setContractEndDate] = useState(CoreService.addMonths(new Date(), 12));
    const [visible, setVisible] = useState(false);
    const [contractNumber, setContractNumber] = useState('');
    const [errors, setErrors] = useState([]);
    const [qerrors, setQErrors] = useState(null);
    const [success, setSuccess] = useState(null);
    const [trottError, setTrottError] = useState(null)
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
    const [contractDescription, setContractDescription] = useState(null);
    const [contractTechStatus, setContractTechStatus] = useState('');
    const [isPreloader, setIsPreloader] = useState(false);
    const [contractSummary, setContractSummary] = useState(null)

    //добавление ИНН
    const [partnerVat, setPartnerVat] = useState(false);
    const [endVat, setEndVat] = useState(false);
    const [currentEndVat, setCurrentEndVat] = useState(null);
    const [currentPartnerVat, setCurrentPartnerVat] = useState(null);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => setContractSummary(props.quotaDetails?.total_price_sale_rub), [props.quotaDetails])

    const downloadQuota = async event => {
        let fileType = event.target.firstChild.data.includes('PDF') ? 'pdf' : 'xlsx';
        event.preventDefault();
        const response = await QuotaService.downloadQuota(props.quotaDetails.id, fileType).catch((err) => { if (err.message.includes('429')) setTrottError('Слишком много запросов. Повторите через 10 секунд') });
        var now = (new Date()).toISOString();
        now = now.replace('.', '-');
        const effectiveFileName = `quota${props.quotaDetails.id}-${props.quotaDetails.enduser_org}-${now}.${fileType}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', effectiveFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'sold') soldQuota()
                if (type === 'del') deleteQuota()
                if (type === 'sync') synchronizeQuota()
            })
            .catch(() => changeStatus())
    }
    const downloadQuotaKp = async event => {
        let fileType = 'xlsx';
        event.preventDefault();
        const response = await QuotaService.downloadQuotaKp(props.quotaDetails.id).catch((err) => { if (err.message.includes('429')) setTrottError('Слишком много запросов. Повторите через 10 секунд') });
        var now = (new Date()).toISOString();
        now = now.replace('.', '-');
        const effectiveFileName = `quota${props.quotaDetails.id}-${props.quotaDetails.enduser_org}-${now}.${fileType}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', effectiveFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    const soldQuota = async event => {
        if (event) event.preventDefault();
        let quota = {};
        let contract = {};
        contract.contract_partner_org = (props.quotaDetails.partner_org !== null && props.quotaDetails.partner_org !== undefined) ? props.quotaDetails.partner_org : props.quotaDetails.enduser_org
        contract.quota = props.quotaDetails.id;
        contract.contract_date = contractDate;
        if (contractDescription !== null) contract.comment = contractDescription;
        contract.contract_nr = contractNumber;
        contract.payment_method = currentPaymentMethod;
        contract.contract_enddate = contractEndDate;
        contract.contract_price_sale_rub = contractSummary;
        contract.tech_status = contractTechStatus;
        if (props.quotaDetails.partner_org !== null && props.quotaDetails.partner_org !== undefined) contract.enduser_org = props.quotaDetails.enduser_org;
        quota.status = "SOLD";

        quota.sold_date = contractDate;
        setIsPreloader(true);

        await ContractService.newContract(contract)
            .then(async () => {
                await QuotaService.updateQuota(props.quotaDetails.id, quota)
                    .then(() => { setErrors([]); props.setQuotaSold(true); setTimeout(() => { props.setViewQuota(false); setVisible(false); setIsPreloader(false) }, 500) }).catch((e) => {
                        setErrors(e.message); setIsPreloader(false)
                    })
            })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('sold');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })

    }

    const addVattPartnerOrg = async () => {

        setIsPreloader(true);
        await OrgService.patchOrg(currentPartnerVat, props.quotaDetails?.organisation_info[1].id, false).then(() => { setErrors([]); setIsPreloader(false); setPartnerVat(false) }).catch((e) => { setErrors(e.message); setIsPreloader(false) })
    }
    const addVattEndOrg = async () => {
        setIsPreloader(true);
        await OrgService.patchOrg(currentEndVat, props.quotaDetails?.organisation_info[0].id, false).then(() => { setErrors([]); setIsPreloader(false); setEndVat(false) }).catch((e) => { setErrors(e.message); setIsPreloader(false) })
    }

    useEffect(() => {

        if (props.quotaDetails?.organisation_info[0].vat === null) { setEndVat(true); }
        if (props.quotaDetails?.organisation_info.length > 1) {
            if (props.quotaDetails?.organisation_info[1].vat === null) {
                setPartnerVat(true)
            }
        }

    }, [props])
    const synchronizeQuota = async () => {
        setIsPreloader(true);
        await QuotaService.syncQuota(props.quotaDetails.id)
            .then((res) => { setSuccess(res.message); setTimeout(() => { props.setSyncQuota(!props.syncQuota); setIsPreloader(false) }, 500) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('sync')
                else {setQErrors(e.message); setIsPreloader(false); 
}            })


    }
    const deleteQuota = async () => {
        setIsPreloader(true);
        await QuotaService.deleteQuota(props.quotaDetails.id)
            .then((res) => { setSuccess(res.message); setTimeout(() => { props.setViewQuota(false); setIsPreloader(false) }, 500) })
            .catch((e) => {
                if (e.message[0].includes('token'))refreshToken('del')
                else {setQErrors(e.message); setIsPreloader(false); }
            })


    }

    const quotaStatus = (status) => {
        let quotaStatusColor = null;

        switch (status) {
            case 'SOLD':
                quotaStatusColor = 'green';
                break;

            case 'EXPIRED':
                quotaStatusColor = 'red';
                break;
            default:
                quotaStatusColor = 'black';

        }
        return (<span style={{ color: `${quotaStatusColor}` }}>{status}</span>)
    }
    return (
        <>                {trottError &&
            <Col className="col-6">
                <Alert key="danger" variant="danger">
                    {trottError}
                </Alert>

            </Col>}
            {qerrors !== null &&
                <Alert key="danger" variant="danger">
                    {qerrors}
                </Alert>

            }
            {success && <div className="alert alert-success" role="alert">
                {success}
            </div>
            }
            {deletingQuota &&
                <MyModal visible={deletingQuota} setVisible={setDeletingQuota}>
                    <Row>

                        <h5 className="mb-3">Удалить квоту?</h5>
                    </Row>
                    <Row> {errors.length > 0 &&
                        errors.map(error =>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        )
                    }</Row>
                    <Row>
                        <Col >
                            <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteQuota() }}>
                                Да
                            </Button>

                        </Col>
                        <Col >
                            <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeletingQuota(false); setErrors([]) }} style={{ width: '100%' }}>
                                нет
                            </Button>
                        </Col>
                    </Row>

                </MyModal>}
            <MyModal visible={visible} setVisible={setVisible}>
                <Form className="was-validated" onSubmit={soldQuota}>

                    {errors.length > 0 &&
                        errors.map(error =>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        )
                    }
                    {endVat === true &&
                        <Form.Group className="mb-2">
                            <Form.Label>Введите ИНН организации: {props.quotaDetails?.organisation_info[0].name}</Form.Label>
                            <Form.Control
                                value={currentEndVat || ""}
                                placeholder={'введите 9, 10 или 12 цифр '}
                                onChange={(e) => setCurrentEndVat(e.target.value)}
                                size="sm" type="text"
                                required={true}

                            />
                            <Button variant="outline-primary " className="mt-3" size="sm"
                                onClick={() => { addVattEndOrg(); }}
                                type="button">
                                подтвердить ИНН
                            </Button>

                        </Form.Group>}
                    {partnerVat === true && <Form.Group className="mb-2">
                        <Form.Label>Введите ИНН организации: {props.quotaDetails?.organisation_info[1].name}</Form.Label>
                        <Form.Control
                            required={true}
                            value={currentPartnerVat || ""}
                            placeholder={'введите 9, 10 или 12 цифр'}
                            onChange={(e) => setCurrentPartnerVat(e.target.value)}
                            size="sm" type="text"

                        />

                        <Button variant="outline-primary " size="sm"
                            onClick={() => { addVattPartnerOrg() }}
                            className="mt-3"
                            disabled={isPreloader === true ? true : false}
                            type="button">
                            подтвердить ИНН
                        </Button>
                    </Form.Group>}
                    <Form.Group className="mb-3">
                        <Form.Label size="sm">Тип оплаты</Form.Label>
                        <Form.Select size="sm" id="select" required={true}
                            disabled={partnerVat === true || endVat === true ? true : false}
                            onChange={(e) => setCurrentPaymentMethod(e.target.value)}>
                            <option value="">Откройте меню</option>
                            {paymentMethod?.map((method, index) => <option key={method.type} value={method.type}>{method.title}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-2" style={{ position: "relative" }} >
                        <Form.Label>Номер контракта</Form.Label>
                        <Form.Control
                            value={contractNumber || ""}
                            placeholder={'000'}
                            onChange={(e) => setContractNumber(e.target.value)}
                            size="sm" type="text"
                            disabled={partnerVat === true || endVat === true ? true : false}
                            required={true} />

                        {isPreloader && <Preloader />}
                    </Form.Group>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата договора</Form.Label>
                        <Form.Control
                            value={contractDate || ""}
                            onChange={(e) => setContractDate(e.target.value)}
                            size="sm" type="date"
                            disabled={partnerVat === true || endVat === true ? true : false}
                            required={true} />

                    </Form.Group>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата окончания договора</Form.Label>
                        <Form.Control
                            value={contractEndDate || ""}
                            onChange={(e) => setContractEndDate(e.target.value)}
                            size="sm" type="date"
                            disabled={partnerVat === true || endVat === true ? true : false}
                            required={true} />

                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label size="sm">Сумма договора с НДС</Form.Label>
                        <Form.Control size="sm" type="text" id="contSum" required={true}
                            value={contractSummary || ""}
                            onChange={(e) =>setContractSummary(e.target.value?.replace(/,/g, '.'))}
                            disabled={partnerVat === true || endVat === true ? true : false}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label size="sm">Тех. статус</Form.Label>
                        <Form.Select size="sm" id="select"
                            required={true}
                            onChange={(e) => setContractTechStatus(e.target.value)}>
 <option value="">Выбрать</option>
                            {techStatus?.map((method, index) => <option key={method.type} value={method.type}>{method.title}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Комментарий (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={contractDescription || ""}
                            disabled={partnerVat === true || endVat === true ? true : false}
                            size="sm"
                            onChange={(e) => setContractDescription(e.target.value)}
                        />
                    </Form.Group>

                    {(partnerVat === false && endVat === false) && <Button variant="outline-primary " size="sm"
                        //   onClick={() => { soldQuota(); }}
                        // onSubmit={(event)=>{event.preventDefault();soldQuota()}}
                        disabled={isPreloader === true ? true : false}
                        className='col-md-auto'
                        style={{ marginRight: "3px" }}
                        type="submit">
                        Сохранить
                    </Button>}
                    <Button variant="outline-danger"
                        onClick={() => setVisible(false)}
                        className='col-md-auto'
                        size="sm" >
                        Закрыть
                    </Button>
                </Form>
            </MyModal>

            <Row className="mt-5" style={{ width: '100%' }}>
                <Col className="col-md-auto">
                    <Form.Group className="mb-4" style={{ width: '100%' }}>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => { props.setViewQuota(false); props.setQuotaDetails(); props.setQuotaDetailsItems(); }}
                        >Закрыть</Button>
                        <br />
                    </Form.Group>
                </Col>
                <Col className="col-md-auto">
                    <Form.Group className="mb-4" style={{ width: '100%' }}>
                        <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={downloadQuota}
                        >Скачать в PDF</Button>
                        <br />
                    </Form.Group>
                </Col>
                <Col className="col-md-auto">
                    <Form.Group className="mb-4" style={{ width: '100%' }}>
                        <Button
                            variant="outline-primary"
                            size="sm"

                            onClick={downloadQuota}
                        >Скачать в Excel</Button>
                        <br />
                    </Form.Group>
                </Col>
                <Col className="col-md-auto">
                    <Form.Group className="mb-4" style={{ width: '100%' }}>
                        <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={downloadQuotaKp}
                        >КП в Excel</Button>
                        <br />
                    </Form.Group>
                </Col>
                <Col className="col-md-auto">
                    <Form.Group className="mb-4" style={{ width: '100%' }}>
                        {props.quotaDetails !== undefined && <Button
                            variant="outline-danger"
                            size="sm"
                            disabled={props.quotaDetails.status === "SOLD"}
                            onClick={() => { props.setViewQuota(false); props.setQuotaDetails(); props.setQuotaDetailsItems(); props.setIsQuotaEdit(true); props.setQuotaToEditID(props.quotaDetails.id); }}
                        >Редактировать</Button>}

                        <br />
                    </Form.Group>
                </Col>
                <Col className="col-md-auto">
                    <Form.Group className="mb-4" style={{ width: '100%' }}>
                        {props.quotaDetails !== undefined && <Button
                            variant="outline-danger"
                            size="sm"
                            disabled={props.quotaDetails.status === "SOLD"}
                            onClick={() => { setVisible(true) }}
                        // onClick={() => { soldQuota(); props.setQuotaSold(true); props.setViewQuota(false); }}
                        >Продать квоту</Button>}

                        <br />
                    </Form.Group>
                </Col>
                <Col className="col-md-auto">
                    <Form.Group className="mb-4" style={{ width: '100%' }}>
                        <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={synchronizeQuota}
                        >Сихронизировать цены</Button>
                        <br />
                    </Form.Group>
                </Col>
                <Col className="col-md-auto">
                    <Form.Group className="mb-4" style={{ width: '100%' }}>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={(e) => setDeletingQuota(true)}
                            disabled={true && props?.quotaDetails?.status === "SOLD"}
                        >Удалить</Button>
                        <br />
                    </Form.Group>
                </Col>
            </Row>
            <Table>
                <tbody>
                    {props.quotaDetails &&
                        <tr>
                            <td sm={6} colSpan={4}>

                                <b>ID</b><span> ({props.quotaDetails.id}) </span><b>Статус</b> {quotaStatus(props.quotaDetails.status)} {props.quotaDetails.status === "SOLD" && `дата договора: ${props.quotaDetails.sold_date}`}
                                <br />
                                <b>Owner</b> {props.quotaDetails.owner?.first_name + ' ' + props.quotaDetails.owner?.last_name}
                                <br />
                                <b>Создана</b> {props.quotaDetails.date_created}, {props.quotaDetails.created_by}
                                <br />
                                <b>Обновлено</b> {props.quotaDetails.date_modified}, {props.quotaDetails.updated_by}
                                <br />
                                <b>Истекает</b> {props.quotaDetails.date_expires}
                                <br />
                                <b>Комментарии</b> {props.quotaDetails.description ? props.quotaDetails.description : "нет"}
                                <br />
                                <b>Версия цен</b> {props.quotaDetails.active_priceversion_comment} от {props.quotaDetails.active_priceversion_date}
                                <br />
                            </td>
                            <td colSpan={3}>
                                <b>Заказчик</b> {props.quotaDetails.enduser_org}
                                <br />
                                <b>Партнер</b> {props.quotaDetails.partner_org}
                                <br />
                                <b>GPL USD</b> {props.quotaDetails.total_price}
                                <br />
                                <b>Скидка</b> {props.quotaDetails.discount}
                                <br />
                                <b>Курс</b> {props.quotaDetails.currency_rate}
                                <br />
                                <b>Сумма продажи</b> {props.quotaDetails.total_price_sale_rub} руб
                                <br />
                            </td>
                        </tr>
                    }

                    <tr>
                        <td>
                            <b>Парт-номер</b>
                        </td>
                        <td>
                            <b>Наименование</b>
                        </td>
                        <td>
                            <b>Прайс</b>
                        </td>
                        <td>
                            <b>Количество</b>
                        </td>
                        <td>
                            <b>Прайс Sum</b>
                        </td>
                        <td>
                            <b>Прайс, руб</b>
                        </td>
                        <td>
                            <b>Скидка, %</b>
                        </td>
                        <td>
                            <b>Продажа сумма, руб</b>
                        </td>
                    </tr>
                    {props.quotaDetailsItems?.map((item, index) =>
                        <tr key={item.id + index}>
                            <td>
                                {item.part_number}
                            </td>
                            <td>
                                {item.name}
                            </td>
                            <td>
                                {item.price} USD
                            </td>
                            <td>
                                {item.amount}
                            </td>
                            <td>
                                {item.total_price} USD
                            </td>
                            <td>
                                {item.total_price_rub} р
                            </td>
                            <td>
                                {item.discount} %
                            </td>
                            <td>
                                {item.total_price_sale_rub} р
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </Table>
        </>
    );
}

export default QuotaDetail;