export const limit = 20;
const linkStyle = {
    "cursor": 'pointer',
    "color": 'blue',
    'textDecoration': "underline",
}
export const paymentMethod = [
    {type: "prepaid",
    title: 'оплата 100% '
    },
    {type: "monthly",
    title: 'по-месячно '
    },
    {type: "quarterly",
    title: 'по-квартально '
    },
]
export const techStatus = [
    {type: "patch_needed",
    title: 'нужен патч'
    },
    {type: "keys_needed",
    title: 'нужны ключи'
    },
    {type: "all_done",
    title: 'все сделано'
    },
    {type: "awaits_delivery",
    title: 'ожидание поставки'
    },
]
export const organisationRating = (rating, org) => {
    let badge;
    // eslint-disable-next-line default-case
    switch(rating) {
        default:
            badge ='bg-dark'
            break;
        case(4):
        badge ='bg-info'
            break;
        ;
        case(3):
        badge ='bg-success'
        break;
        ;
        case(2):
        badge ='bg-primary'
        break;

        ;
        case(1):
        badge ='bg-danger'
        break;
        
      
    }
return <div style={{display: "flex", flexWrap: 'nowrap'}}><span style={{ marginRight: 5,  padding: '3px 5px', fontSize: '10px', boxSizing:'border-box', height: '16px' }} className={`badge ${badge} `}>{rating}</span><span style={linkStyle}>{org}</span></div>;
}

export const standartDuration = [{
    value: 2592000,
    valueInDay: 30
}, {
    value: 5184000,
    valueInDay: 60

}, {
    value: 7776000,
    valueInDay: 90
},{
    value: 10368000,
    valueInDay: 120
},{
    value: 12960000,
    valueInDay: 150
},{
    value: 15552000,
    valueInDay: 180
},{
    value: 18144000,
    valueInDay: 210
},{
    value: 20736000,
    valueInDay: 240
},{
    value: 23328000,
    valueInDay: 270
},
{
    value: 25920000,
    valueInDay: 300
},{
    value: 28512000,
    valueInDay: 330
},{
    value: 31104000,
    valueInDay: 360
},{
    value: 33696000,
    valueInDay: 390
},{
    value: 36288000,
    valueInDay: 420
},{
    value: 38880000,
    valueInDay: 450
},{
    value: 41472000,
    valueInDay: 480
},
{
    value: 44064000,
    valueInDay: 510
},
{
    value: 46656000,
    valueInDay: 540
},{
    value: 49248000,
    valueInDay: 570
},{
    value: 51840000,
    valueInDay: 600
},
];
export const trialDuration = [{
    value: 604800,
    valueInDay: 7
}, {
    value: 1209600,
    valueInDay: 14

}, {
    value: 1814400,
    valueInDay: 21
}];

export const handleSetDateFilters = (value,  setDateAfterFilter) => {
    const now = new Date();
    let numberDate;
    // eslint-disable-next-line default-case
    switch (value) {

        case 'last7': numberDate = now.getTime() - 604800000;  break;
        case 'last30': numberDate = now.getTime() - 2592000000; break;
        case 'last90': numberDate = now.getTime() - 7776000000;  break;
        case 'lastY': numberDate = now.getTime() - 31536000000; break;
        case 'today': numberDate = now.setHours(0, 0, 0, 0); break;

    }
    const actualDate = new Date(numberDate);
    setDateAfterFilter(actualDate.toLocaleString('Ru-ru').split(',')[0].split('.').reverse().join('-'));
}