import React, { useEffect, useState } from "react";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import
{
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    PointElement,
    LineElement,
    Tooltip,
    Title,
    Filler,

} from "chart.js";
import { Line } from "react-chartjs-2";
import { Colors } from 'chart.js';
import DashboardService from "../../API/DashboardService";
import Preloader from "../UI/Preloader/Preloader";
export default function DashboardAssetsSubsGraph({})
{
    ChartJS.register(
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        PointElement,
        LineElement,
        Tooltip,
        Title,
        Filler,
        ChartDataLabels,
        Colors
    );
    const [data, setData] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false)
  
    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector("div");
      
        if (!tooltipEl) {
          tooltipEl = document.createElement("div");
          tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
          tooltipEl.style.borderRadius = "5px";
          tooltipEl.style.boxShadow = "3px 3px 3px rgba(100,100,100,0.4)";
          tooltipEl.style.color = "#4B4F55";
          tooltipEl.style.backgroundColor = "#FFFFFF";
          tooltipEl.style.opacity = 1;
          tooltipEl.style.pointerEvents = "none";
          tooltipEl.style.position = "absolute";
          tooltipEl.style.transform = "translate(-50%, 0)";
          tooltipEl.style.transition = "all .1s ease";
      
          const table = document.createElement("table");
          table.style.margin = "0px";
      
          tooltipEl.appendChild(table);
          chart.canvas.parentNode.appendChild(tooltipEl);
        }
      
        return tooltipEl;
      };
const externalTooltipHandler = (context, typeChart) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);
      
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
      
        // Set Text
        if (tooltip.body) {
          const titleLines = tooltip.title || [];
          const bodyLines = tooltip.body.map((b) => b.lines);
      
          const tableHead = document.createElement("thead");
      
          titleLines.forEach((title) => {
            const tr = document.createElement("tr");
            tr.style.borderWidth = "0";
      
            const th = document.createElement("th");
            th.style.borderWidth = "0";
            const text = document.createTextNode(title);
      
            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
          });
      
          const tableBody = document.createElement("tbody");
      
          bodyLines.forEach((body, i) => {
            const span = document.createElement("span");
            const colorSpan = document.createElement("span");
      
            colorSpan.style.height = "10px";
            colorSpan.style.width = "10px";
            colorSpan.style.backgroundColor = chart.data.datasets[i].borderColor;
            colorSpan.style.borderRadius = "50%";
            colorSpan.style.display = "inline-block";
      
            span.style.borderWidth = "2px";
            span.style.height = "10px";
            span.style.width = "10px";
            span.style.display = "inline-block";
      
            const tr = document.createElement("tr");
            tr.style.backgroundColor = "inherit";
            tr.style.borderWidth = "0";
      
            const td = document.createElement("td");
            td.style.borderWidth = "0";
      
            const text = document.createTextNode(body);
      
            td.appendChild(colorSpan);
            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tableBody.appendChild(tr);
          });
      
          const tableRoot = tooltipEl.querySelector("table");
      
          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }
      
          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);
        }
      
        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
      
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left =
          positionX +
          (typeChart === "epd" ? tooltip.caretX / 1 : tooltip.caretX / 1) +
          "px";
        tooltipEl.style.top = positionY + tooltip.caretY / 2 + "px";

        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding =
          tooltip.options.padding + "px " + tooltip.options.padding + "px";
      };
        useEffect(() => {
        
            (async () => {   
                 setIsPreloader(true)
              await DashboardService.getAssetsSubsGraph().then((res) => setData(res)).finally(()=> setIsPreloader(false))
        
            })()
          }, [])
       



    const chartData = data.length> 0 ? {
        labels: data?.map(v => v.date?.split('-').reverse().join('.')) || [],
        datasets: Object.keys(data[0]?.stats)?.map((slug, index)=> {return {label: slug, tension: 0.1 , fill: false,pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 15,
           data: data.map((item)=> {return item.stats[slug]})}})
       ,
        options: {
            fill: false,
            interaction: {
                intersect: false
            },
            radius: 0,
        }
    } : {labels: ['no data'], datasets: [0,0,0]}
    const options = {
        bezierCurve: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1  

                }
            }
        },
        // animation: {
        //     onComplete: function () {
        //       getImage();
        //     },},
        indexAxis: "x",
        interaction: {
            mode: "index"
        },
        elements: {
            point: {
                borderWidth: 0,
                radius: 10,
                backgroundColor: 'rgba(0,0,0,0)'
            },
            line: {
                borderJoinStyle: 'round'
            }
        },
       // scales: scalesOptions(data),
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: 'Количество устройств с активными подписками по месяцам',
              },
            datalabels: {
                display: false
              },
              colors: {
                enabled: true,
                forceOverride: true
            },
            tooltip: {
                titleFont: {
                    size: 18
                  },
                  bodyFont: {
                    size: 12
                  },
                  footerFont: {
                    size: 12 // there is no footer by default
                  },
                enabled: false,
               displayColors: true,
               external: externalTooltipHandler,
                callbacks: {
                    title: data => data[0].label,
                    label: (ctx )=> {   return ctx.dataset.label + ": " + ctx.dataset.data[ctx.dataIndex]},
                },
            }
        },
    };

    return (
       <div style={{position: "relative"}}>
        {isPreloader && <Preloader/>}
                    <div style={{ height: "600px", width: '100%', padding: '20px 40px', opacity: isPreloader? 0.1: 1}}>
                    <Line options={options} data={chartData} />
                    </div>
</div>
    )
}