import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Table from 'react-bootstrap/Table';
import ImgEdit from '../../icons/edit.png';
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import Preloader from "../UI/Preloader/Preloader";
import DocumentationService from "../../API/DocumentationService";
import AddDocumentationForm from "./AddDocumentationForm";
import ImgRemove from '../../icons/remove.png';
import IconSave from '../../icons/save.svg';
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import Alert from 'react-bootstrap/Alert';
import PatchDocumentationForm from "./PatchDocumentationForm";
import CopyIcon from "../../icons/copy.svg";
const typeFileApp = (value) => {
    let type = ''
    switch (value) {
        case 'txt': type = ''; break;
        case 'zip': type = 'application/zip'; break;
        case 'xlsx': type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'; break;
        case 'xls': type = 'application/vnd.ms-excel'; break;
        case 'doc': type = 'application/msword'; break;
        case 'docx': type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
        default: type = `application/${value}`;
    }
    return type
}
const DocumentationList = () => {
    const [patchDocument, setPatchDocument] = useState(false);
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState([]);
    const [addDocument, setAddDocument] = useState(false);
    const [successAdd, setSuccessAdd] = useState(false);
    const [successPatch, setSuccessPatch] = useState(false);
    const [currentInstance, setCurrentInstance] = useState(null);
    const [isPreloader, setIsPreloader] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false)
    const [copytext, setCopyText] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const basicStyles = {
        "fontSize": '10px',
        cursor: 'pointer'
    }
    useEffect(() => {
        (async () => {
            setIsPreloader(true)
            await DocumentationService.getDocumentationList().then((res) => { setData(res.results); }).catch((err) => { setErrors(err.message) }).finally(() => { setIsPreloader(false); })
        }
        )()
    }, [successAdd, successDelete, successPatch])

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') deleteInstance();

            })
            .catch(() => changeStatus())
    }

    const deleteInstance = async (id) => {
        setIsPreloader(true);
        await DocumentationService.deleteInstance(id)
            .then(() => { setIsPreloader(false); setDeleteItem(false); setSuccessDelete(!successDelete) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('delete');
                else { setIsPreloader(false); setErrors(e.message); }
            });
    }

    const downloadFile = async (file) => {

        const string = file.doc_file.slice(-5);
        const index = string.lastIndexOf('.');
        const response = await DocumentationService.downloadDocs(file);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: typeFileApp(string.slice(index + 1)) }));
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', file.name)
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
    const copyBufferText = ({ text, field }) => {
        navigator.clipboard
          .writeText(text)
          .then((res) => {
            setCopyText({ text: "Copy", field: field });
          })
          .catch((err) => {
            setCopyText({ text: "Error", field: field });
          });
      };
    return (
        <>
            {deleteItem && <MyConfirm visible={deleteItem} setVisible={setDeleteItem} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите удалить запись?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteInstance(currentInstance.id) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItem(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyConfirm>}
            <Row style={{ position: "relative" }}>
                {isPreloader && <Preloader />}
                {(!addDocument && !patchDocument) && <Col className="mb-3 col-2">
                    <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                        onClick={() => { setAddDocument(true) }}
                    >Добавить</Button>

                </Col>}
            </Row>
            {addDocument && <AddDocumentationForm open={setAddDocument} setSuccessAdd={setSuccessAdd} successAdd={successAdd} />}
            {patchDocument && <PatchDocumentationForm open={setPatchDocument} setSuccessPatch={setSuccessPatch} successPatch={successPatch} itemData={currentInstance} />}
            <hr />
            <Table striped bordered hover id='tableTokens' >
                <thead style={basicStyles}>
                    <tr>
                        <th>Сервис</th>
                        <th>Файл</th>
                        <th>Описание</th>
                        <th>Создан</th>
                        <th>Обновлен</th>
                        <th>Публичный</th>
                        <th style={{ width: '5%' }}>Скачать</th>
                        <th style={{ width: '5%' }}>Изменить</th>
                        <th style={{ width: '5%' }}>Удалить</th>

                    </tr>
                </thead>
                <tbody>

                    {data?.map((item, index) =>
                        <tr key={item.id + 'doc'}
                            style={basicStyles}
                        >
                            <td>{item.service}</td>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>{item.date_created} <br /> {item.created_by}</td>
                            <td>{item.date_modified} <br /> {item.updated_by}</td>
                            <td>{item.is_public? (<><span>Да</span> <img
                        width="16"
                        className="interactive_hover"
                        alt={"copy"}
                        src={CopyIcon}
                        disabled
                        onClick={() => {
                          setCurrentItem(item);
                          copyBufferText({ text: item.doc_file, field: "public" });
                        }}
                      />
                      {item.id ===
                        currentItem?.id  &&
                      copytext?.field === "public" ? (
                        <span
                          style={
                            copytext.text === "Ошибка"
                              ? { color: "red" }
                              : { color: "green" }
                          }
                        >
                          {copytext.text}
                        </span>
                      ) : (
                        <span></span>
                      )}</>): "Нет"}</td>
                            <td><img width="20" className="interactive_hover" alt={'edit'} src={IconSave} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                    downloadFile(item)

                                }
                            } />
                            </td>
                            <td><img width="20" className="interactive_hover" alt={'edit'} src={ImgEdit} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                    setCurrentInstance(item)
                                    setPatchDocument(true)
                                }
                            } />
                            </td>
                            <td><img width="20" className="interactive_hover" alt={'delete'} src={ImgRemove} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    setCurrentInstance(item)
                                    setDeleteItem(true)
                                }
                            } />
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </Table>
        </>
    )
}

export default DocumentationList;
