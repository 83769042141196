import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Table from 'react-bootstrap/Table';
import ImgDelete from '../../icons/delete.svg';
import ImgDownload from '../../icons/download.svg';
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import Preloader from "../UI/Preloader/Preloader";
import IconEdit from '../../icons/edit.svg';
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import Alert from 'react-bootstrap/Alert';
import PimService from "../../API/PimService";
import AddPimDocForm from "./AddPimDocForm";
import PatchPimDocForm from "./PatchPimDocForm";


const PimDocumentationList = () => {
    const [patchDocument, setPatchDocument] = useState(false);
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState([]);
    const [addDocument, setAddDocument] = useState(false);
    const [successAdd, setSuccessAdd] = useState(false);
    const [successPatch, setSuccessPatch] = useState(false);
    const [currentInstance, setCurrentInstance] = useState(null);
    const [isPreloader, setIsPreloader] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const typeFileApp = (value) => {
        let type = ''
        switch (value) {
            case 'txt': type = ''; break;
            case 'zip': type = 'application/zip'; break;
            case 'xlsx': type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'; break;
            case 'xls': type = 'application/vnd.ms-excel'; break;
            case 'doc': type = 'application/msword'; break;
            case 'docx': type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
            default: type = `application/${value}`;
        }
        return type
    }
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const basicStyles = {
        "fontSize": '14px',
        cursor: 'pointer'
    }
    const fetchData=async (add)=> {
        setIsPreloader(true)
            await PimService.getPimDocumentationList(add ? nextPage : 1).then((res) => { 
                if (add === 'add') {setNextPage(res.next ? nextPage + 1 : null);
                    res.results?.map(item => setData(arr => [...arr, item]));}
                    else {
                        setData(res.results);
                        setNextPage(res.next ? 2 : null);
                    } }).catch((err) => { setErrors(err.message) }).finally(() => { setIsPreloader(false); })
    }
    useEffect(() => {
       fetchData()
    }, [successAdd, successDelete, successPatch])
    useEffect(() => {

        if (isPreloader) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage && data) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreloader]);
    useEffect(() => {

        ( () => {

            if (needToLoad && nextPage) {
               
                fetchData('add');
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);
    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') deleteInstance();

            })
            .catch(() => changeStatus())
    }

    const deleteInstance = async (id, type) => {
        setIsPreloader(true);
        await PimService.deletePimDoc(id)
            .then(() => { setIsPreloader(false); setDeleteItem(false); setSuccessDelete(!successDelete) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken('delete');
                else { setIsPreloader(false); setErrors(e.message); }
            });
    }
    const downloadFile = async (file) => {

        const string = file.file_name.slice(-5);
        const index = string.lastIndexOf('.');
        const response = await PimService.downloadPimDocs(file);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: typeFileApp(string.slice(index + 1)) }));
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', file.file_name)
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
    return (
        <>
            {deleteItem?.open && <MyConfirm visible={deleteItem?.open} setVisible={setDeleteItem} >
                <div style={{width: 700}}>
                <Row className="mb-4" style={{ position: "relative",}}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4" >Вы точно хотите удалить {deleteItem?.item.name}?</h3>
                </Row>
                <Row > {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row >
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteInstance(deleteItem.id ) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItem(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row></div>
            </MyConfirm>}
            <Row style={{ position: "relative" }}>
                {isPreloader && <Preloader />}
               
            </Row>
            {addDocument && <AddPimDocForm open={setAddDocument} setSuccessAdd={setSuccessAdd} successAdd={successAdd} />}
            {patchDocument && <PatchPimDocForm open={setPatchDocument} setSuccessAdd={setSuccessAdd} successAdd={successAdd} itemData={currentInstance} />}
            <hr />
                <Col style={{boxSizing: "border-box"}}>
               <div style={{display: "flex", justifyContent: "space-between", marginBottom: 5}}> <span >Документация</span>
               <Button variant="outline-success" size="sm" style={{ fontSize: '1rem',  }}
                        onClick={() => { setAddDocument(true) }}
                    >Добавить</Button>
               </div>
               <div style={{maxHeight: 400, overflowY: "scroll"}}>
            <Table striped bordered hover id='tableTokens' >
                <thead style={basicStyles}>
                    <tr>
                        <th>Название</th>
                        <th>Дата изменения</th>
                        <th>Файл</th>
                        <th style={{width: '10%'}}></th>
                     
                       

                    </tr>
                </thead>
                <tbody>

                    {data?.map((item, index) =>
                        <tr key={item.id + 'docsPim'}
                            style={basicStyles}
                        >
                        <td >{item.name}</td>
                        <td>{item.date_modified}</td>
                    <td>{item.doc_file}</td> 

                        <td>
                        <div  style={{marginBottom: 5}}>
                            <img width="14"  style={{marginRight: 5,}}className="interactive_hover" alt={'download'} src={ImgDownload} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                  
                                    downloadFile(item)
                                }
                            } />
                            <img width="14" style={{marginRight: 5,}}className="interactive_hover" alt={'edit'} src={IconEdit} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                    setPatchDocument(true);
                                    setCurrentInstance(item)

                                }
                            } /><img width="14" className="interactive_hover" alt={'delete'} src={ImgDelete} onClick={
                                (evt) => {

                                    evt.stopPropagation();
                                    setDeleteItem({open: true, item: item, id: item.id})

                                }
                            } /></div>

                        </td>

                           
                        </tr>
                    )
                    }
                </tbody> 
                <tfoot>
                <tr ref={lastItem} style={{ height: 20 }} ></tr>
                </tfoot>
            </Table>        
           
            </div>
            </Col>
  
        </>
    )
}

export default PimDocumentationList;
