import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Table from 'react-bootstrap/Table';
import EdlService from "../../API/EdlService";
import ImgEdit from '../../icons/edit.png';
import MyModal from "../UI/MyModal/MyModal";
import AddEdlSubForm from "./AddEdlSubForm";
import PatchEdlSubForm from "./PatchEdlSubForm";
import PatchPlatformLimit from "./PatchPlatformLimit";
import Preloader from "../UI/Preloader/Preloader";

const EdlSubsList = () => {
    const [isPreloader, setIsPreloader] = useState(false);
    const [patchSub, setPatchSub] = useState(false);
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState([]);
    const [addSub, setAddSub] = useState(false);
    const [successAdd, setSuccessAdd] = useState(false);
    const [successPatch, setSuccessPatch] = useState(false);
    const [currentSub, setCurrentSub] = useState(null);
    const [isListLoading, setIsListLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const basicStyles = {
        "fontSize": '12px',
    }
    useEffect(() => {
        (async () => {
            setNextPage(null);
            setIsListLoading(true);
            await EdlService.getSubsList(false).then((res) => { setData(res.results); setNextPage(res.next); }).catch((err) => { setErrors(err.message) }).finally(() => setIsListLoading(false))
        }
        )()
    }, [successAdd, successPatch]);
    useEffect(() => {

        (async () => {

            if (needToLoad && nextPage) {

                setIsPreloader(true);
                setIsListLoading(true);
                await EdlService.getSubsList(nextPage).then((res) => {
                    res.results?.map(item => setData(arr => [...arr, item]));
                    setNextPage(res.next);
                }).finally(() => setIsPreloader(false));

                setIsListLoading(false);
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {

        if (isListLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

    }, [isListLoading]);

    return (
        <>
            <Row>
                {!addSub && <Col className="mb-3 col-2">
                    <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                        onClick={() => { setAddSub(true) }}
                    >Добавить подписку</Button>

                </Col>}
            </Row>
            {addSub && <AddEdlSubForm setAddSub={setAddSub} setSuccessAdd={setSuccessAdd} successAdd={successAdd} />}
            {patchSub && currentSub && <MyModal visible={patchSub} setVisible={setPatchSub}>
                <PatchEdlSubForm currentSub={currentSub} setPatchSub={setPatchSub} setSuccessPatch={setSuccessPatch} successPatch={successPatch} />
            </MyModal>}
            <Table striped bordered hover id='tableEdlSubs' >
                <thead style={basicStyles}>
                    <tr>

                        <th>Имя</th>
                        <th>Лимит списков</th>
                        <th>Лимит активных списков</th>
                        <th>IP лимит</th>
                        <th>DOMAIN лимит</th>
                        <th>URL лимит</th>
                        <th>HASH лимит</th>
                        <th style={{ width: '5%' }}>Изменить</th>

                    </tr>
                </thead>
                <tbody>

                    {data?.map((item, index) =>
                        <tr key={item.id + 'edlSubs'}
                            style={basicStyles}

                        >
                            <td>{item.name}</td>
                            <td>{item.lists_max_limit}</td>
                            <td>{item.active_lists_max_limit}</td>
                            <td>{item.ip_addresses_max_limit}</td>
                            <td>{item.domains_max_limit}</td>
                            <td>{item.urls_max_limit}</td>
                            <td>{item.hashes_max_limit}</td>
                            <td><img width="20" className="interactive_hover" alt={'edit'} src={ImgEdit} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    setCurrentSub(item)
                                    setPatchSub(true)
                                }
                            } />
                            </td>

                        </tr>
                    )
                    }
                </tbody>

            </Table>
            <div ref={lastItem} style={{ height: 20 }} /></>
    )
}

export default EdlSubsList;

