import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import PimService from "../../API/PimService";
import { Dropdown } from "react-bootstrap";
import MyMenu from "../UI/MyDropdown/MyMenu";
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
function AddServerSoftForm({ open, setSuccessAdd, successAdd }) {

    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [description, setDescription] = useState('');
    const [majorVersion, setMajorVersion] = useState('');
    const [releaseVersion, setReleaseVersion] = useState('');
    const [newRelease, setNewRelease] = useState({new: true, title: 'Создать новую'})
    const [files, setFiles] = useState([]);
    const [fileName, setFileName] = useState('')
    const [issuedDate, setIssuedDate] = useState(null);
    const [releaseList, setReleaseList] = useState([]);
    const [fullPacket, setFullPacket] = useState(true);
    const [knownIssues, setKnownIssues] = useState('');
    const [visible, setVisible] = useState(true);
    const [isNode, setIsNode] = useState(false)
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveInstance();

            })
            .catch(() => changeStatus())
    }
    useEffect(() => {
        (async () => {
            await PimService.getPimServerMajorReleaseDropdown().then((res) =>
                setReleaseList(res)
            );
        })();
    }, []);

    const saveInstance = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const formData = new FormData();
        const arrDescription = description?.split('\n');
        const finalArr = arrDescription.map((item)=> item + '\*');
        const stringDescription = finalArr.join('');
if (knownIssues) {
let arr = knownIssues?.split('\n')
const arrIssues = arr?.map((item)=> item? item + '*' : '')
formData.append('known_issues', arrIssues.join(''))
}
else  formData.append('known_issues', '')
formData.append('is_node', isNode)
        formData.append('release_notes', stringDescription);
        formData.append('major_version', majorVersion);
        formData.append('release_version', releaseVersion);
        if (newRelease?.new) formData.append('release_date', issuedDate);
       // else formData.append('release_date', '2024-09-11')
        formData.append('release_package', files);
        formData.append('is_full', fullPacket)
        formData.append('is_visible', visible)
if (!newRelease?.new) {
        await PimService.newServersRelease(formData, newRelease?.id)
            .then(() => { open(false); setIsPreloader(false); setSuccessAdd(!successAdd) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
} else { await PimService.newServersMajorRelease(formData)
    .then(() => { open(false); setIsPreloader(false); setSuccessAdd(!successAdd) })
    .catch((err) => {
        if (err.message[0].includes('token')) refreshToken('save');
        else {
            setErrors(err.message); setIsPreloader(false);
        }
    })}

    }
    return (
        <>
        <h5>Добавить Серверное ПО</h5>
        <Form id="form" onSubmit={saveInstance} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}


            <Row>
            <Col className="mb-3 col-3">
                        <Form.Label size="sm">Версия релиза</Form.Label>
                        <Dropdown>
                            <Dropdown.Toggle
                                as={MyToggleAsset}
                                id="dropdown-release"
                            >
                                {newRelease?.title|| "создать новую или выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                filterAssets={setNewRelease}
                                type="releaseFilter"
                            >
                                <Dropdown.Item
                                 onClick={() => {
                              setNewRelease({new: true, title: 'Создать новую'});
                    
                                }}
                                >Создать новую</Dropdown.Item>
                                {releaseList?.map((release) => (
                                    <Dropdown.Item
                                        eventKey={"apl" + release?.version}
                                        key={"apl" + release?.version}
                                        onClick={() => {
                                           setNewRelease({new: false, title: release?.version, id: release?.major_version_id});
                                            setMajorVersion(release?.version)
                                        }}
                                    >
                                        {release?.version} 
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
           {newRelease?.new&& <Col className="mb-3 col-3">
                    <Form.Group>
                        <Form.Label size="sm">Версия релиза</Form.Label>
                        <Form.Control size="sm" type="text" id={'mVersion'} required={true} placeholder={'Введите версию'} pattern="^\d+\.\d+\.\d+$"

                        onChange={(e)=> setMajorVersion(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        Мажорная версия релиза в формате "Х.0.0", где X - целое число
                        </Form.Text>
                    </Form.Group>
                </Col>}
                {newRelease?.new&&<Col className="col-2 mb-3">
                    <div
                        className="form-check form-switch"
                        style={{
                            minHeight: 0, display: "flex", flexDirection: "column"
                        }}
                    >
                           <Form.Label size="sm" style={{marginLeft: '-40px'}}>Visible</Form.Label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="statusChecks"
                            checked={visible}
                            style={{ height: "25px", width: "50px"}}
                            onChange={(evt) => setVisible(evt.target.checked)}
                        />
                    </div>
                </Col>}
            </Row>
            <Row >
            

                {/* <Col className="mb-3 col-3">
                    <Form.Group>
                        <Form.Label size="sm">Версия релиза</Form.Label>
                        <Form.Control size="sm" type="text" id={'rVersion'} required={true} placeholder={'Введите версию'} pattern="^\d+\.\d+\.\d+$"

                        onChange={(e)=> setReleaseVersion(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        Версия релиза в формате 'X.Y.Z'
                        </Form.Text>
                        
                    </Form.Group>
                </Col> */}
                {newRelease?.new&& <Col className="mb-3 col-2">
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата релиза </Form.Label>
                        <Form.Control
                            value={issuedDate || ""}
                            onChange={(e) => setIssuedDate(e.target.value)}
                            type="date"
                            size="sm"
                            required={true} />

                    </Form.Group>
                </Col>}
                <Col className="mb-3 col-6">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Файл</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            required={true}
                            style={{ width: '100%' }}
                            onChange={(e) => {setFiles(e.target.files[0])}}
                        // onChange={(e) => { validateFiles(e.target.files[0], index) }}
                        // setFiles([...files, { file: e.target.files, name: nameFile(index,), field: index }]) 
                        accept="/*,.deb,.rpm"
                        />

                    <Form.Text className="text-muted">
                    Пакет установки ПО (.deb или .rpm)
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Row>
                <Row>
                    <Col className="mb-3 col-2">
                    <div style={{display: "flex", gap: 10}}>
                    {/* <span>Апдейт</span> */}
                    <div className="form-check form-switch"
                     style={{
                        minHeight: 0, display: "flex", flexDirection: "column"
                    }}
                    >
                         <Form.Label size="sm" style={{marginLeft: '-40px'}}>Полный пакет</Form.Label>
  <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={(e)=>setFullPacket(e.target.checked)} checked={fullPacket}  style={{ height: "25px", width: "50px"}}/>
  {/* <label className="form-check-label" style={{color: "black", fontSize: 16, fontWeight: 400}} htmlFor="flexSwitchCheckChecked">Полный пакет</label> */}
</div></div>
                    </Col>
                <Col className="col-2 mb-3">
                    <div
                        className="form-check form-switch"
                        style={{
                            minHeight: 0, display: "flex", flexDirection: "column"
                        }}
                    >
                           <Form.Label size="sm" style={{marginLeft: '-40px'}}>is Node</Form.Label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="statusChecks"
                            checked={isNode}
                            style={{ height: "25px", width: "50px"}}
                            onChange={(evt) => setIsNode(evt.target.checked)}
                        />
                    </div>
                </Col>   
                </Row>
                {newRelease?.new && <Col className="mb-3 col-4">
                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Описание релиза</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                type="text"
                                placeholder="Описание "
                                value={description || ""}
                                size="sm"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col> }
                    {newRelease?.new &&  <Col className="mb-3 col-4">
                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Известные проблемы</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                type="text"
                                placeholder="Известные проблемы"
                                value={knownIssues || ""}
                                size="sm"
                                onChange={(e) => setKnownIssues(e.target.value)}
                            />
                        </Form.Group>
                    </Col>} 
                </Row>
                {/* <Col>
                    <img width="20" className="interactive_hover" alt={'delete'} src={ImgRemove} style={{ marginTop: '33px' }}
                        onClick={() => { setFilesCount(filesCount.filter((it) => it.count !== item.count)); setFiles(files.filter((it) => it.field + 1 !== item.count)) }} /></Col> */}
            </Row>
            {/* <Row>
                <Col className="mb-3 col-4">
                    <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                        onClick={() => { setFilesCount([...filesCount, { count: filesCount.length + 1 }]) }}
                    >Добавить документ</Button></Col></Row> */}
            <hr />
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            open(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Добавить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
        </>
    );
}
export default AddServerSoftForm;
