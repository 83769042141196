import React, { useState, useEffect, useRef, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

import { AuthContext } from "../../context";
import SandboxService from "../../API/SandboxService";
import Button from "react-bootstrap/esm/Button";
import Preloader from "../UI/Preloader/Preloader";
import MyModal from "../UI/MyModal/MyModal";
import CopyIcon from "../../icons/copy.svg";
import { Alert, Dropdown, Form, Table } from "react-bootstrap";
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import MyModalFeed from "../UI/MyModalFeed/MyModalFeed";
import downloadPdf from "../../icons/pdf.svg";
import downloadZip from "../../icons/zip.svg";
import PreloaderTableCenter from "../UI/Preloader/PreloaderTableCenter";
import { changeHandler } from "./utils";
import RequestsDetail from "./RequestsDetail";

function RequestsList({firewalls, orgs}) {
    const [currentItem, setCurrentItem] = useState(null);
    const [typeFileFilter, setTypeFileFilter] = useState(false);
    const [sandboxData, setSandboxData] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [verdictFilter, setVerdictFilter] = useState([]);
    const [verdictTVFilter, setVerdictTVFilter] = useState(false);
    const [verdictKasperFilter, setVerdictKasperFilter] = useState(false);
    const [verdictSourceFilter, setVerdictSourceFilter] = useState(false);
    const [isPreloader, setIsPreloader] = useState(false);
    const [isAssetLoading, setIsAssetLoading] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [errors, setErrors] = useState([]);
    const [copytext, setCopyText] = useState(false);
    const [serialFilter, setSerialFilter] = useState(false);
    const [orgFilter, setOrgFilter] = useState(false);
    const [viewFileDetail, setviewFileDetail] = useState(false);
    const [malwareOnly, setMalwareOnly] = useState(false);
    const [ordering, setOrdering] = useState("-created_at");
    const [statusFilter, setStatusFilter] = useState([]);
    const [renew, setRenew]=  useState(false);
    const [positiveFilter, setPositiveFilter] = useState(false);
  const [total, setTotal]  = useState(0)
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const basicStylesTitles = {
      fontSize: "smaller",
      width: "30%",
      textAlign: "right",
  };
    const fetchData = async (add) => {
        let filters = false;
        if (
            searchText ||
            verdictFilter ||
            typeFileFilter ||
            serialFilter ||
            verdictSourceFilter ||
            verdictKasperFilter ||
            verdictTVFilter ||
            malwareOnly ||
            ordering || 
            statusFilter || 
            positiveFilter || 
            orgFilter
        ) {
            filters = {
                malware_only: malwareOnly,
                search: searchText,
                verdict: verdictFilter,
                verdict_source__in: verdictSourceFilter,
                file_type: typeFileFilter,
                ordering: ordering,
                status__in: statusFilter,
                firewall_id__in: serialFilter.serial,
                organisation_id: orgFilter?.id,
                false_positive: positiveFilter,

            };
        }

        setIsPreloader(true);
        setIsAssetLoading(true);

        await SandboxService.getRequestsList(filters, add ? nextPage : 1)
            .then((res) => {
                if (add === "add") {
                    res.items?.map((item) =>
                        setSandboxData((arr) => [...arr, item])
                    );
                    setNextPage(res.items?.length > 0 ? nextPage + 1 : null);
                } else {
                    setSandboxData(res.items);
                    setNextPage(res.items?.length > 0 ? 2 : null);
                }
                // setNextPage(res.next ? nextPage + 1 : null);
                setIsAssetLoading(false);setTotal(res.total);
            } )
            .catch(() => changeStatus())
            .finally(() => setIsPreloader(false));
    };
    useEffect(() => {
        setIsPreloader(true);
        const timerRefresh = setTimeout(fetchData, 500);
        return () => {
            clearTimeout(timerRefresh);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        verdictFilter,
        searchText,
        typeFileFilter,
        serialFilter,
        verdictSourceFilter,
        malwareOnly,
        verdictKasperFilter,
        verdictTVFilter,
        ordering,
        statusFilter,
        renew, orgFilter, positiveFilter
    ]);

    useEffect(() => {
        (async () => {
            if (needToLoad && nextPage) {
                await fetchData("add");
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {
        if (isAssetLoading) return;
        if (observer.current) observer.current.disconnect();
        var callback = function (entries, observer) {
            if (
                entries[0].isIntersecting &&
                nextPage &&
                sandboxData?.length > 0
            ) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);
    }, [isAssetLoading]);
    const dateStyles = {
        fontSize: "x-small",
    };
    const changeDate = (date) => {
        if (date) {
            const formatDate = date?.split("T");
            const reverseDate = (
                <>
                    <span>{formatDate[0].split("-").reverse().join(".")}</span>{" "}
                    <span>{formatDate[1].split(".")[0]}</span>
                </>
            );

            return reverseDate;
        } else return null;
    };

    // const refreshToken = async (type) => {
    //     await Auth.refreshToken(localStorage.getItem('freshToken'))
    //         .then((res) => {
    //             localStorage.setItem('token', res.data.access); setErrors([]);
    //             if (type === 'delete') deleteAsset(currentItem.id)

    //         })
    //         .catch(() => changeStatus())
    // }
    // function changeHandler(current, arr, set) {

    //     if (arr?.some((item) => item === current) !== true) {
    //         set([...arr, current]);
    //         //setSlugFilter(true)
    //     } else { const index = arr?.findIndex((item) => current === item); set([...arr?.slice(0, index), ...arr?.slice(index + 1)]) }
    // }
    const getVerdictBadge = (verdict, item) => {
        if (verdict === "Clean")
            return (
                <span
                    style={{
                       
                        fontSize: "x-small",
                        background: "black",
                    }}
                    className="badge bg-success"
                >
                    {verdict}
                </span>
            );
        else if (verdict === "Unsupported" || verdict === 'Grayware')
            return (
                <span
                    style={{  fontSize: "x-small" }}
                    className="badge bg-warning"
                >
                    {verdict}
                </span>
            );
        else if (verdict === "Error")
            return (
                <span
                    style={{
                       
                        fontSize: "x-small",
                        background: "#9e1068",
                    }}
                    className="badge"
                >
                    {verdict}
                </span>
            );
        else if (verdict === "Unknown")
            return (
                <span
                    style={{
                      
                        fontSize: "x-small",
                        background: "grey",
                    }}
                    className="badge "
                >
                    {verdict}
                </span>
            );
        else if (verdict === null)
            return (
                <span
                    style={{  fontSize: "x-small" }}
                    className="badge bg-light text-dark"
                >
                    No data
                </span>
            );
        else
            return (
                <span
                    style={{  fontSize: "x-small" }}
                    className="badge bg-danger"
                >
                    {verdict}
                </span>
            );
    };
    const getStatusBadge = (status, item) => {

       let element =  <span
                    style={{  fontSize: "x-small" }}
                    className="badge bg-light text-dark"
                >
                    No data
                </span>
                switch (status) {
                    case('accepted'):element =  <span
                    style={{  fontSize: "x-small" , background: 'grey'}}
                    className="badge"
                >
                    Accepted
                </span>;  break;
                    case('stage_01'):element =  <span
                    style={{  fontSize: "x-small" }}
                    className="badge bg-warning"
                >
                    Stage 1
                </span>; break;
                    case('stage_02'): element =  <span
                    style={{  fontSize: "x-small" }}
                    className="badge bg-warning"
                >
                    Stage 2
                </span>; break;
                    case('stage_03'): element =  <span
                    style={{ fontSize: "x-small" }}
                    className="badge bg-warning"
                >
                    Stage 3
                </span>; break;
                    case('succeeded'): element =  <span
                    style={{  fontSize: "x-small" }}
                    className="badge bg-success"
                >
                    Succeeded
                </span>; break;
                    case('finished'): element =  <span
                    style={{  fontSize: "x-small" }}
                    className="badge bg-success"
                >
                Finished
                </span>; break;
                 case('timeout'): element =  <span
                 style={{  fontSize: "x-small" , background: '#ff7a45'}}
                 className="badge"
             >
             Timeout
             </span>; break;
              case('failed'): element =  <span
              style={{  fontSize: "x-small" }}
              className="badge bg-danger"
          >
          Failed
          </span>; break;
                }
                return element
        
    };
    
    const copyBufferText = ({ text, field }) => {
        navigator.clipboard
            .writeText(text)
            .then((res) => {
                setCopyText({ text: "Copy", field: field });
            })
            .catch((err) => {
                setCopyText({ text: "Error", field: field });
            });
    };
    const clearFilters = () => {
        setSearchText("");
        setVerdictFilter([]);
        setTypeFileFilter(false);
        setSerialFilter(false);
        setVerdictSourceFilter(false);
        setMalwareOnly(false);
        setVerdictKasperFilter(false);
        setVerdictTVFilter(false);
        setOrdering("-created_at");
        setStatusFilter([]);
        setOrgFilter(false);
        setPositiveFilter(false)
    };
    const downloadFile = async (item, type) => {
        setIsPreloader(true);
        await SandboxService.getExportSampleFileSandbox(item?.checked_file_sha256)
            .then((resp) => {
                setErrors(null);
                const date = new Date()
                    .toISOString()
                    .replace(".", "-")
                    .split("T")[0];

                const effectiveFileName = `${item.checked_file_sha256}-${date}`;
                const url = window.URL.createObjectURL(
                    new Blob([resp.data], {
                        type: resp.headers["content-type"],
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", effectiveFileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                setErrors(
                    err.message?.length > 0 ? err.message : ["Не найдено"]
                );
            })
            .finally(() => setIsPreloader(false));
    };
    const downloadReport=async (item, type='pdf', producer='palo')=> {

        setIsPreloader(true)
        await SandboxService.getExportFileSandbox(item?.checked_file_sha256, type, producer).then((resp)=>{
            setErrors(null)
            const date = (new Date()).toISOString().replace('.', '-').split('T')[0];
    
            const effectiveFileName = `${item.checked_file_sha256}-${date}`;
            const url = window.URL.createObjectURL(new Blob([resp.data],{type: resp.headers['content-type']}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', effectiveFileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
    
        }).catch((err) => {
            if (producer==='palo')downloadReport(item, 'pdf', producer='forti')
            setErrors(err.message?.length> 0 ? err.message: ['Не найдено'])}).finally(()=> setIsPreloader(false));
    
    }
    return (
        <>
            {viewFileDetail?.open && (
                <MyModalFeed
                    visible={viewFileDetail?.open}
                    setVisible={setviewFileDetail}
                >
                   <RequestsDetail sha256={viewFileDetail?.item?.checked_file_sha256}  setviewFileDetail={setviewFileDetail} url={viewFileDetail?.item?.file_url} ip={viewFileDetail?.item?.file_ip}/>
                </MyModalFeed>
            )}

            <Row
                className="justify-content-between"
                style={{ margin: " 0 0 ", padding: "0 0" }}
            >
                {/* <Col className="col-3 mt-2 mb-2" style={{padding: 0}}>
                    <b>Всего {dataCount} </b>
                </Col> */}
            </Row>
            {errors?.length > 0 &&
                errors?.map((error, index) => (
                    <Row key={"danger" + error + index}>
                        <Col>
                            <Alert variant="danger">{error}</Alert>
                        </Col>
                    </Row>
                ))}
            <Row className="justify-content-md-between">
            <Col className="col-3 mt-2 mb-2">
                    <b>Всего: {total}</b>
                </Col>
            <Col className="col-2 mt-2 mb-2">
                        <Button
                            variant="primary"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={(e) => {
                                setRenew(!renew);
                            }}
                        >
                            Обновить
                        </Button>
                    </Col>
            </Row>
            <Row className="mb-2" style={{ position: "relative" }}>
                <Col className="mb-3 col-4">
                    <Form.Label size="sm">Поиск </Form.Label>
                    <Form.Control
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        placeholder="Поиск по sha256 и File name"
                        size="sm"
                        style={{ fontSize: "1rem", height: 38 }}
                        value={searchText}
                    />
                </Col>
                {/* <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Firewall</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-firewall" >
                            {serialFilter?.serial|| "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setVerdictFilter}
                            type='verdictFilter'

                        >
                            {firewalls?.map((item, index)=> 
                            <Dropdown.Item eventKey={item.firewall.serial_nr + 'fre'} key={item.firewall.serial_nr + 'fre'} onClick={() => { setSerialFilter({deviceId: item.firewall.serial_nr , serial: item.firewall.serial_nr }) }}>{item.firewall.serial_nr }</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col> */}
                
                <Col className="mb-3 col-2">
                    <Form.Label size="sm">Status</Form.Label>
                    <Dropdown autoClose="outside">
                        <Dropdown.Toggle
                         size={'min'}
                            as={MyToggleAsset}
                            id="dropdown-verdict-source"
                        >
                              {(statusFilter?.length && statusFilter?.map((item, index) => item + ' ')) || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            
                            filterAssets={setStatusFilter}
                            type="statusFilter"
                        >
                        <Dropdown.Item
                        className={statusFilter.some((item) => item === "Processing") === true ? 'active' : ""}
                                eventKey={"processing" + 4}
                                key={"processing" + 1}
                                onClick={() => {
                                    changeHandler("Processing", statusFilter, setStatusFilter)
                                   // setStatusFilter("accepted");
                                }}
                            >
                                {"Processing"}
                            </Dropdown.Item>
                            <Dropdown.Item
                        className={statusFilter.some((item) => item === "Finished") === true ? 'active' : ""}
                                eventKey={"finished" + 4}
                                key={"finished" + 1}
                                onClick={() => {
                                    changeHandler("Finished", statusFilter, setStatusFilter)
                                   // setStatusFilter("accepted");
                                }}
                            >
                                {"Finished"}
                            </Dropdown.Item>
                            <Dropdown.Item
                            className={statusFilter.some((item) => item === "Stage 1") === true ? 'active' : ""}
                                eventKey={"stage" + 1}
                                key={"stage1" + 1}
                                onClick={() => {
                                    changeHandler('Stage 1', statusFilter, setStatusFilter)
                                   // setStatusFilter("stage_01");
                                }}
                            >
                                {"Stage 1"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"vf" + 2}
                                key={"stage2" + 2}
                                className={statusFilter.some((item) => item === "Stage 2") === true ? 'active' : ""}
                                onClick={() => {
                                    changeHandler('Stage 2', statusFilter, setStatusFilter)
                                   // setStatusFilter("stage_02");
                                }}
                            >
                                {"Stage 2"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"vf" + 3}
                                key={"stage3" + 3}
                                className={statusFilter.some((item) => item === "Stage 3") === true ? 'active' : ""}
                                onClick={() => {
                                    changeHandler('Stage 3', statusFilter, setStatusFilter)
                                    //setStatusFilter("stage_03");
                                }}
                            >
                                {"Stage 3"}
                            </Dropdown.Item>
                            <Dropdown.Item
                            className={statusFilter.some((item) => item === "Error / Timeout") === true ? 'active' : ""}
                                eventKey={"Error/Timeout" + 1}
                                key={"Error/Timeout" + 1}
                                onClick={() => {
                                    changeHandler('Error / Timeout', statusFilter, setStatusFilter)
                                   // setStatusFilter("stage_01");
                                }}
                            >
                                {"Error / Timeout"}
                            </Dropdown.Item>
                           
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2">
                    <Form.Label size="sm">Verdict</Form.Label>
                    <Dropdown autoClose="outside">
                        <Dropdown.Toggle
                        size={ 'min' }
                            as={MyToggleAsset}
                            id="dropdown-verdict"
                        >
                               {(verdictFilter?.length && verdictFilter?.map((item, index) => item + ' ')) || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            type="multi"
                        >
                            <Dropdown.Item
                                eventKey={"v" + 1}
                                key={"v" + 1}
                                className={verdictFilter.some((item) => item === "Clean") === true ? 'active' : ""}
                                onClick={() => {
                                  //  setVerdictFilter("Benign");
                                  changeHandler("Clean", verdictFilter, setVerdictFilter)
                                }}
                            >
                                {"Clean"}
                            </Dropdown.Item>

                            <Dropdown.Item
                                eventKey={"v" + 3}
                                key={"v" + 3}
                                className={verdictFilter.some((item) => item === "Grayware") === true ? 'active' : ""}
                                onClick={() => {
                                   // setVerdictFilter("Grayware");
                                   changeHandler("Grayware", verdictFilter, setVerdictFilter)
                                }}
                            >
                                {"Grayware"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"v" + 2}
                                key={"v" + 2}
                                className={verdictFilter.some((item) => item === "Malware") === true ? 'active' : ""}
                                onClick={() => {
                                  //  setVerdictFilter("Malware");
                                  changeHandler("Malware", verdictFilter, setVerdictFilter)
                                }}
                            >
                                {"Malware"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"v" + 5}
                                key={"v" + 5}
                                className={verdictFilter.some((item) => item === "Unknown") === true ? 'active' : ""}
                                onClick={() => {
                                   // setVerdictFilter("Unsupported");
                                   changeHandler("Unknown", verdictFilter, setVerdictFilter)
                                }}
                            >
                                {"Unknown"}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2">
                    <Form.Label size="sm">Verdict source</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle
                          size={'min'}
                            as={MyToggleAsset}
                            id="dropdown-verdict-source"
                        >
                            {verdictSourceFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setVerdictSourceFilter}
                            type="verdictFilter"
                        >
                            <Dropdown.Item
                                eventKey={"source" + 1}
                                key={"source" + 1}
                                onClick={() => {
                                    setVerdictSourceFilter("stage_01");
                                }}
                            >
                                {"Stage 1"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"vf" + 2}
                                key={"vf" + 2}
                                onClick={() => {
                                    setVerdictSourceFilter("stage_02");
                                }}
                            >
                                {"Stage 2"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"vf" + 3}
                                key={"vf" + 3}
                                onClick={() => {
                                    setVerdictSourceFilter("stage_03");
                                }}
                            >
                                {"Stage 3"}
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2">
                    <Form.Label size="sm">File Type</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-type"   size={'min'}>
                            {typeFileFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setTypeFileFilter}
                            type="typeFilter"
                        >
                            <Dropdown.Item
                                eventKey={"t" + 1}
                                key={"t" + 1}
                                onClick={() => {
                                    setTypeFileFilter("pdf");
                                }}
                            >
                                {"pdf"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"t" + 2}
                                key={"t" + 2}
                                onClick={() => {
                                    setTypeFileFilter("ms-office");
                                }}
                            >
                                {"ms-office"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"t" + 3}
                                key={"t" + 3}
                                onClick={() => {
                                    setTypeFileFilter("pe");
                                }}
                            >
                                {"pe"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"t" + 4}
                                key={"t" + 4}
                                onClick={() => {
                                    setTypeFileFilter("jar");
                                }}
                            >
                                {"jar"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"t" + 5}
                                key={"t" + 5}
                                onClick={() => {
                                    setTypeFileFilter("flash");
                                }}
                            >
                                {"flash"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"t" + 7}
                                key={"t" + 7}
                                onClick={() => {
                                    setTypeFileFilter("archive");
                                }}
                            >
                                {"archive"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"t" + 8}
                                key={"t" + 8}
                                onClick={() => {
                                    setTypeFileFilter("script");
                                }}
                            >
                                {"script"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"t" + 9}
                                key={"t" + 9}
                                onClick={() => {
                                    setTypeFileFilter("apk");
                                }}
                            >
                                {"apk"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"t" + 10}
                                key={"t" + 10}
                                onClick={() => {
                                    setTypeFileFilter("linux");
                                }}
                            >
                                {"linux"}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Firewall</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-firewall"   size={'min'}>
                            {serialFilter?.serial|| "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setSerialFilter}
                            type='verdictFilter'

                        >
                            {firewalls?.map((item, index)=> 
                            <Dropdown.Item eventKey={item.firewall.serial_nr + 'fre'} key={item.firewall.serial_nr + 'fre'} onClick={() => { setSerialFilter({deviceId: item.firewall.serial_nr , serial: item.firewall.serial_nr }) }}>{item.firewall.serial_nr }</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Организация</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-orgs"   size={'min'} >
                            {orgFilter?.name|| "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setOrgFilter}
                            type='orgFilter'

                        >
                            {orgs?.map((item, index)=> 
                            <Dropdown.Item eventKey={item.name + 'or'} key={item.name + 'or'} onClick={() => { setOrgFilter(item) }}>{item.name}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                {(searchText ||
                    verdictFilter?.length > 0||
                    orgFilter || 
                    typeFileFilter ||
                    serialFilter ||
                    verdictSourceFilter ||
                    positiveFilter || 
                    malwareOnly ||
                    verdictKasperFilter ||
                    verdictTVFilter ||
                    statusFilter?.length> 0|| 
                    ordering !== "-created_at") && (
                    <Col className="col-2">
                        <Button
                            variant="outline-info"
                            size="sm"
                            style={{
                                width: "100%",
                                height: "38px",
                                margin: "32px 0 10px",
                            }}
                            onClick={() => clearFilters()}
                        >
                            Сбросить
                        </Button>
                    </Col>
                )}
                </Row>


            {
                <div
                    style={{
                        position: "relative",
                        minHeight: "375px",
                        display: "flex",
                        opacity: isPreloader ? 0.4 : 1,
                        flexDirection: "column",
                    }}
                >
                    {isPreloader && <PreloaderTableCenter />}
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-striped table-bordered"
                            id="checkUrlResp"
                        >
                            <thead>
                                <tr style={dateStyles}>
                                    <th style={{ width: "5%" }}>
                                        Start time {<br/>}
                                        UTC
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        ordering ===
                                                        "created_at"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "created_at"
                                                        ? setOrdering(
                                                              "created_at"
                                                          )
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↑
                                            </span>
                                        }
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        ordering ===
                                                        "-created_at"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "-created_at"
                                                        ? setOrdering(
                                                              "-created_at"
                                                          )
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↓
                                            </span>
                                        }
                                    </th>
                                    <th style={{ width: "5%" }}>
                                        Finish time{<br/>}
                                        UTC
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        ordering ===
                                                        "finished_check_at"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "finished_check_at"
                                                        ? setOrdering(
                                                              "finished_check_at"
                                                          )
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↑
                                            </span>
                                        }
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        ordering ===
                                                        "-finished_check_at"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !== "-finished_check_at"
                                                        ? setOrdering(
                                                              "-finished_check_at"
                                                          )
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↓
                                            </span>
                                        }
                                    </th>
                                    <th style={{ width: "5%" }}>Sender</th>
                                    <th style={{ width: "5%" }}>
                                        Organization
                                    </th>
                                    <th style={{ width: "5%" }}>Status</th>
                                    <th style={{ width: "5%" }}>SHA256</th>
                                    <th style={{ width: "5%" }}>
                                        Verdict
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        ordering ===
                                                        "check_duration"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !==
                                                    "check_duration"
                                                        ? setOrdering(
                                                              "check_duration"
                                                          )
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↑
                                            </span>
                                        }
                                        {
                                            <span
                                                style={{
                                                    color:
                                                        ordering ===
                                                        "-check_duration"
                                                            ? "blue"
                                                            : "black",
                                                    marginLeft: "5px",
                                                    fontWeight: 900,
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    ordering !==
                                                    "-check_duration"
                                                        ? setOrdering(
                                                              "-check_duration"
                                                          )
                                                        : setOrdering(false)
                                                }
                                            >
                                                ↓
                                            </span>
                                        }
                                    </th>
                                    <th style={{ width: "5%" }}>File type</th>
                                    <th style={{ width: "3%",wordWrap: 'break-word' }}>File name</th>
                                    <th style={{ width: "2%" }}>Details</th>
                                    {/* <th style={{ width: "4%" }}>
                                        Download{<br />}Report / Sample
                                    </th> */}
                                </tr>
                            </thead>

                            <tbody
                                className="table-group-divider"
                                id={"chDevExt"}
                            >
                                {sandboxData?.map((item, index) => (
                                    <tr
                                        key={item.checked_file_sha256 + "sandFiles" + item.uid}
                                        style={dateStyles}
                                    >
                                        <td>{changeDate(item.created_at)}</td>
                                        <td>{changeDate(item.finished_check_at)}</td>
                                        <td>
                                            {item.firewall_id || item.client_id}
                                        </td>
                                        <td>{item.organisation_name || ''}</td>
                                        <td>{getStatusBadge(item.status)}</td>
                                        <td title={item.checked_file_sha256}>
                                            <span>
                                                ...{item.checked_file_sha256?.slice(-10)}{" "}
                                            </span>
                                            <img
                                                width="16"
                                                className="interactive_hover"
                                                alt={"copy"}
                                                src={CopyIcon}
                                                disabled
                                                onClick={() => {
                                                    setCurrentItem(item);
                                                    copyBufferText({
                                                        text: item.checked_file_sha256,
                                                        field: "hash",
                                                    });
                                                }}
                                            />
                                            {item.checked_file_sha256 ===
                                                currentItem?.checked_file_sha256 &&
                                            copytext?.field === "hash" ? (
                                                <span
                                                    style={
                                                        copytext.text ===
                                                        "Ошибка"
                                                            ? { color: "red" }
                                                            : { color: "green" }
                                                    }
                                                >
                                                    {copytext.text}
                                                </span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </td>
                                        <td style={{ cursor: "pointer" }}>
                                           <div style={{display: "flex", gap: '5px'}}> {getVerdictBadge(
                                                item.verdict,
                                                item
                                            )}
                                           
                                              </div>
                                           
                                            <span style={{display: 'block'}}>{item.verdict_source}</span>
                                  
                                            <span style={{display: 'block'}}>{item.check_duration}</span> 
                                        </td>
                                        <td>{item.file_type}</td>
                                        <td><div style={{wordWrap: "break-word", maxWidth: '250px'}}>{item.file_name}</div></td>
                                        <td>
                                            <Button
                                                variant="primary"
                                                style={{
                                                    fontSize: "10px",
                                                    padding: 3,
                                                }}
                                                onClick={() => {
                                                    setviewFileDetail({
                                                        open: true,
                                                        item: item,
                                                    });
                                                }}
                                            >
                                                Details
                                            </Button>
                                        </td>
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div ref={lastItem} style={{ height: 20 }} />
                </div>
            }
        </>
    );
}

export default RequestsList;
