import React, { useState, useEffect, useRef, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import MyModal from "../UI/MyModal/MyModal";
import PriceItemCreateForm from "./PriceItemCreateForm";
import PlatformService from "../../API/PlatformService";
import MySelect from "../UI/MySelect";
import PriceService from "../../API/PriceService";
import PriceList from "./PriceList";
import PriceItemEditForm from "./PriceItemEditForm";
import FileUploadForm from "./FileUploadForm";
import PlatformAddForm from "./PlatformAddForm";
import CategoryAddForm from "./CategoryAddForm";
import PriceVersionList from "./PriceVersionList";
import Badge from 'react-bootstrap/Badge';
import Preloader from "../UI/Preloader/Preloader";
import CategoryList from "./CategoryList";
import { AuthContext, UserContext } from "../../context";
import Auth from "../../API/Auth";
import Alert from 'react-bootstrap/Alert';
function PriceArea() {
    const [modal, setModal] = useState(false);
    const [fileModal, setFileModal] = useState(false);
    const {userinfo} = useContext(UserContext);
    // загрузка файлов
    // eslint-disable-next-line no-unused-vars
    const [fileUploading, setFileUploading] = useState(false);
    const [file, setFile] = useState();

    // добавление платформ и категорий
    const [platformAdding, setPlatformAdding] = useState(false);
    const [categoryAdding, setCategoryAdding] = useState(false);
    const [platformAdded, setPlatformAdded] = useState(false);
    const [categoryAdded, setCategoryAdded] = useState(false);
    const [categoryList, setCategoryList] = useState(false);
    const [categoryEdited, setCategoryEdited] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [categoryName, setCategoryName] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [categoryDiscount, setCategoryDiscount] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [categoryDescription, setCategoryDescription] = useState(null);
    const [errors, setErrors] = useState([]);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    // загрузка позиций прайса
    const [priceitems, setPriceitems] = useState();
    const [totalpriceitems, setTotalPriceitems] = useState();
    const [isPriceItemsLoading, setIsPriceItemsLoading] = useState(false);
    const [nextPage, setNextPage] = useState();
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();

    // перечень параметров для фильтров прайса
    const [platformtypes, setPlatformTypes] = useState();
    const [platforms, setPlatforms] = useState();
    const [categories, setCategories] = useState();
    const [curPlatformType, setCurPlatformType] = useState("");
    const [curPlatform, setCurPlatform] = useState("");
    const [curCategory, setCurCategory] = useState("");

    // фильтрация элементов прайса
    const [platformTypeFilter, setPlatformTypeFilter] = useState(false);
    const [platformFilter, setPlatformFilter] = useState(false);
    const [categoryFilter, setCategoryFilter] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [text, setText] = useState('')

    // дефолтные значения для формы добавления записи в прайс
    const [currentPlatform, setCurrentPlatform] = useState();
    const [currentCategory, setCurrentCategory] = useState();
    const [currentDescription, setCurrentDescription] = useState("");
    const [currentPrice, setCurrentPrice] = useState("");
    const [curPriceVersion, setCurPriceVersion] = useState({});


    // обслуживание изменений в прайсе
    const [itemToEdit, setItemToEdit] = useState({});
    const [priceChanged, setPriceChanged] = useState(false);
    const [isItemEditing, setIsItemEditing] = useState(false);
    const [changeCurrentRate, setChangeCurrentRate] = useState(false);
    const [currentRate, setCurrentRate] = useState(null);
    // переключение версии прайса
    const [switchPriceVersion, setSwitchPriceVersion] = useState(false);

    const [isPreloader, setIsPreloader] = useState(false)
    useEffect(() => {

        (async () => {
            await PriceService.getPlatformTypesList(1).then((res) => setPlatformTypes(res)).catch(() => changeStatus());;

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            await PlatformService.getDropList().then((res) => {
                setPlatforms(res);
                setCurrentPlatform(res[0].name);
            }).catch(() => changeStatus());;

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platformAdded]);

    useEffect(() => {

        (async () => {
            await PriceService.getCategoryDropList().then((res) => {
                setCategories(res);
                setCurrentCategory(res[0].name);
            }).catch(() => changeStatus());

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryAdded, categoryEdited]);

    useEffect(() => {

        (async () => {
            await PriceService.getPriceConfig(1).then((res) => {
                setCurPriceVersion(
                    {
                        id: res.id,
                        dateCreated: res.active_priceversion_date,
                        decription: res.active_priceversion_comment
                    });
                setCurrentRate(res.currency_rate);
            }).catch(() => changeStatus());;

        })();

        (async () => {

            let filters = false;
            if (platformTypeFilter || platformFilter || categoryFilter || searchText) {
                filters = {
                    platformTypeFilter: platformTypeFilter,
                    platformFilter: platformFilter,
                    categoryFilter: categoryFilter,
                    searchText: searchText,
                };
            };
            if (priceChanged) {
                setPriceChanged(false);
            }
            setIsPreloader(true);
            setIsPriceItemsLoading(true);
            await PriceService.getList(filters, false).then((res) => {
                setPriceitems(res.results);
                setTotalPriceitems(res.count);
                setNextPage(res.next);
                setIsPriceItemsLoading(false);
            }).catch(() => changeStatus()).finally(() => setIsPreloader(false));


        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platformTypeFilter, platformFilter, categoryFilter, searchText, priceChanged]);

    useEffect(() => {

        (async () => {

            if (needToLoad && nextPage) {
                setIsPreloader(true);
                setIsPriceItemsLoading(true);
                await PriceService.getList(false, nextPage).then((res) => {
                    res.results?.map(item => setPriceitems(arr => [...arr, item]));
                    setNextPage(res.next);
                }).finally(() => setIsPreloader(false));

                setNeedToLoad(false);
                setIsPriceItemsLoading(false);

            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {

        if (isPriceItemsLoading) return;
        if (observer.current) observer.current.disconnect();
        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPriceItemsLoading]);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access);
                download();
            })
            .catch(() => changeStatus())
    }
    const download = async event => {
        if (event) event.preventDefault();
        setIsPreloader(true)
        await PriceService.downloadFilePrice()
            .then((res) => {
                var now = (new Date()).toISOString();
                now = now.replace('.', '-');
                const effectiveFileName = `currentpricelist${now}`;
                const url = window.URL.createObjectURL(new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', effectiveFileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setIsPreloader(false)
            }).catch((err) => {
        
                if (err.message.includes('401')) refreshToken();
                else {
                    setIsPreloader(false);
                }
            })

    }
    const patchPrice = async () => {
        setIsPreloader(true);
        await PriceService.patchPriceConfig(curPriceVersion.id, currentRate).then((res) => {
            setChangeCurrentRate(false);
        }).catch((err) => setErrors(err.message)).finally(() => setIsPreloader(false))
    }
    return (
        <Col>
            <div><h3>Прайс-лист (отображено {totalpriceitems} позиций)</h3></div>
            <div style={{ fontSize: 15, marginBottom: 10 }}>Активная версия: <Badge bg="secondary">{curPriceVersion.decription}, загружен {curPriceVersion.dateCreated}</Badge></div>
            {userinfo.role!=='MANAGER' && <div className="d-flex align-items-center gap-3">
                <div><Button variant="outline-dark" size="sm" onClick={download}>Скачать прайс</Button>
                </div>
                <div>
                    <Button variant="outline-dark" size="sm" onClick={() => {
                        setFileModal(true);
                        setFileUploading(true);
                    }}>Загрузить новый</Button>
                    <MyModal visible={fileModal} setVisible={setFileModal}>
                        <FileUploadForm
                            setFile={setFile}
                            file={file}
                            setFileUploading={setFileUploading}
                            setVisible={setFileModal}
                            PriceChanged={setPriceChanged}
                        />
                    </MyModal>
                </div>
                <div><Button variant="outline-dark" size="sm" onClick={(e) => { e.preventDefault(); setSwitchPriceVersion(true) }}>Переключить прайс</Button>
                </div>
                <div><Button variant="outline-dark" size="sm" onClick={(e) => { setCategoryList(true) }}>Категории</Button>
                </div>
                <div><Button variant="outline-dark" size="sm" onClick={(e) => { e.preventDefault(); setChangeCurrentRate(true) }}>Установить курс</Button>
                </div>
            </div>}
            <hr />
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {
                changeCurrentRate
                &&
                <>
                    <Row className="mb-3">
                        <Col sm={2} >
                            <Form.Group>
                                <Form.Label size="sm">Текущий курс</Form.Label>
                                <Form.Control size="sm" type="text" value={currentRate || ""}
                                    onChange={(e) => setCurrentRate(e.target.value)}
                                />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-2">
                            <Button variant="outline-danger" size="sm" style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); setChangeCurrentRate(false) }}>Закрыть</Button>
                        </Col>
                        <Col className="col-2">
                            <Button variant="outline-success" size="sm" style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); patchPrice() }}>Установить</Button>
                        </Col>
                    </Row>
                    <hr />
                </>

            }
            <Row style={{position: "relative"}}>
                <Col sm={2}>
                    Тип платформы
                    <MySelect
                        platformtypes={platformtypes}
                        setPlatformTypeFilter={setPlatformTypeFilter}
                        setCurPlatformType={setCurPlatformType}
                        value={curPlatformType}
                    />
                </Col>
                <Col sm={2}>
                    Платформа {userinfo.role!== 'MANAGER' && (<a href="#none" onClick={(e) => { e.preventDefault(); setPlatformAdding(true) }}>добавить</a>)}
                    <MySelect
                        platforms={platforms}
                        setPlatformFilter={setPlatformFilter}
                        setCurPlatform={setCurPlatform}
                        value={curPlatform}
                    />
                </Col>
                <Col sm={2}>
                    Категория {userinfo.role!== 'MANAGER' && (<a href="#none" onClick={(e) => { e.preventDefault(); setCategoryAdding(true); setCategoryAdded(false) }}>добавить</a>)}
                    <MySelect
                        categories={categories}
                        setCategoryFilter={setCategoryFilter}
                        setCurCategory={setCurCategory}
                        value={curCategory}
                    />
                </Col>
                <Col sm={2}>
                    Поиск
                    <Form.Control onChange={(e) => { setSearchText(e.target.value) }}
                        placeholder="Начните вводить..."
                        size="sm"
                        value={searchText}
                        // disabled={isPriceItemsLoading}
                    />
                </Col>
                
                {(platformTypeFilter || platformFilter || categoryFilter || searchText)
                    ?
                    <Col sm={2}>
                        <br />
                        <Button variant="outline-info" size="sm" onClick={() => {
                            setPlatformTypeFilter(false);
                            setPlatformFilter(false);
                            setCategoryFilter(false);
                            setSearchText("");
                            setCurPlatformType("");
                            setCurPlatform("");
                            setCurCategory("");
                            setText('')
                        }}>Сбросить фильтры</Button>
                    </Col>
                    :
                    <Col sm={1}></Col>
                }

                <Col sm={2}>
                    <br />
                    {userinfo.role!== 'MANAGER' && <Button variant="outline-danger" size="sm" onClick={() => { setModal(true); setIsItemEditing(false) }}>Добавить позицию</Button>}
                    {isItemEditing
                        ?
                        <MyModal visible={modal} setVisible={setModal}>
                            <PriceItemEditForm
                                currentdescription={currentDescription}
                                currentprice={currentPrice}
                                setIsItemEditing={setIsItemEditing}
                                itemToEdit={itemToEdit}
                                setItemToEdit={setItemToEdit}
                                setVisible={setModal}
                                PriceChanged={setPriceChanged}
                            />
                        </MyModal>
                        :
                        <MyModal visible={modal} setVisible={setModal}>
                            <PriceItemCreateForm
                                platforms={platforms}
                                categories={categories}
                                currentplatform={currentPlatform}
                                currentcategory={currentCategory}
                                setVisible={setModal}
                                PriceChanged={setPriceChanged}
                            />
                        </MyModal>
                    }

                </Col>

            </Row>
            {
                switchPriceVersion
                &&
                <Row>
                    <Col sm={8}>
                        <br />
                        <PriceVersionList setSwitchPriceVersion={setSwitchPriceVersion} setPriceChanged={setPriceChanged} />
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setSwitchPriceVersion(false) }}>Закрыть</Button>
                    </Col>
                </Row>
            }

            {
                categoryList && <Row>
                    <Col sm={8}>
                        <br />
                        <CategoryList
                            setCategoryList={setCategoryList}
                            categories={categories}
                            setCategoryName={setCategoryName}
                            setCategoryDiscount={setCategoryDiscount}
                            setCategoryDescription={setCategoryDescription}
                            setCategoryEdited={setCategoryEdited}
                        />
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setCategoryList(false) }}>Закрыть</Button>
                    </Col>
                </Row>
            }
            {
                platformAdding
                &&
                <Row>
                    <Col sm={3}>
                        <br />
                        <PlatformAddForm
                            platformtypes={platformtypes}
                            setCurPlatformType={setCurPlatformType}
                            setPlatformTypeFilter={setPlatformTypeFilter}
                            curPlatformType={curPlatformType}
                            setPlatformAdding={setPlatformAdding}
                            setPlatformAdded={setPlatformAdded}
                        />
                    </Col>
                </Row>
            }
            {
                categoryAdding
                &&
                <Row>
                    <Col sm={3}>
                        <br />
                        <CategoryAddForm
                            setCategoryAdding={setCategoryAdding}
                            setCategoryAdded={setCategoryAdded}
                        />
                    </Col>
                </Row>
            }
            <hr />
            <Row>
                <Col style={{ position: "relative" }}>
                    {isPreloader && <Preloader />}
                    <PriceList
                        priceitems={priceitems}
                        itemChanged={setPriceChanged}
                        setModal={setModal}
                        setIsItemEditing={setIsItemEditing}
                        setItemToEdit={setItemToEdit}
                        setCurrentDescription={setCurrentDescription}
                        setCurrentPrice={setCurrentPrice}
                        userinfo={userinfo}
                    />
                    <div ref={lastItem} style={{ height: 20 }} />
                </Col>
            </Row>
        </Col >
    );
}
export default PriceArea;