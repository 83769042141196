import React, { useState, useEffect, useContext, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import DocumentationService from "../../API/DocumentationService";
import ImgRemove from '../../icons/remove.png';


function PatchDocumentationForm({ open, setSuccessPatch, successPatch, itemData }) {

    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [description, setDescription] = useState(itemData?.description);
    const [service, setService] = useState(itemData?.service);
    // const [docsFiles, setDocsFiles] = useState(itemData.files)
    const [files, setFiles] = useState(null);
    const [filesCount, setFilesCount] = useState([]);
    const [fileName, setFileName] = useState(itemData?.name);
    const [deleteFile, setDeleteFile] = useState(false);
    const refInput = useRef(null);
    const [isPublic, setIsPublic] = useState(itemData?.is_public)
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveInstance();

            })
            .catch(() => changeStatus())
    }


    const saveInstance = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const formData = new FormData();
        formData.append('service', service);
        formData.append('description', description);
        formData.append('name', fileName);
        formData.append('is_public', isPublic)

        if (files) {
            formData.append('doc_file', files);
        }
        // if (files.length > 0) {
        //     for (let i = 0; i < files.length; i = i + 1) {
        //         const item = document.getElementById('nameFile' + i);
        //         formData.append(`files[${i}]name`, item.value)
        //         formData.append(`files[${i}]doc_file`, files[i].file)
        //     }
        // }
        await DocumentationService.patchInstance(formData, itemData.id)
            .then(() => { open(false); setIsPreloader(false); setSuccessPatch(!successPatch) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })


    }
    const handleUploadInputClick = () => {
        refInput.current.click();
      };
    // const nameFile = (index) => {
    //     const item = document.getElementById('nameFile' + index);
    //     return item.value;
    // }

    // const validateFiles = (value, index) => {
    //     let arr = [];
    //     arr = files?.filter((item) => item.field !== index);
    //     setFiles([...arr, { file: value, name: nameFile(index), field: index }])
    // }

    // const deleteDoc = async (id) => {
    //     await DocumentationService.deleteDoc(itemData.id, id).then(() => { setDocsFiles(docsFiles.filter(item => item.id !== id)) }).catch((err) => setErrors(err.message))
    // }

    // const deleteAllDocs = async () => {
    //     await DocumentationService.deleteAllDocs(itemData.id).then(() => { setDocsFiles([]) }).catch((err) => setErrors(err.message))
    // }
    return (
        <Form id="form" onSubmit={saveInstance} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}

            <Row>
<Col className="col-2 mb-3">
                    <div
                        className="form-check form-switch"
                        style={{
                            minHeight: 0, display: "flex", flexDirection: "column"
                        }}
                    >
                           <Form.Label size="sm" style={{marginLeft: '-40px'}}>Публичный</Form.Label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="statusChecks"
                            disabled
                            checked={isPublic}
                            style={{ height: "25px", width: "50px"}}
                            onChange={(evt) => setIsPublic(evt.target.checked)}
                        />
                    </div>
                </Col>
</Row>
            <Row>
                <Col className="col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Сервис</Form.Label>
                        <Form.Select size="sm" id="service" onChange={(e) => { setService(e.target.value) }} value={service}>
                            <option value={'url_f'}>url_f</option>
                            <option value={'dtl'}>dtl</option>
                            <option value={'sandbox'}>sandbox</option>
                            <option value={'common'}>common</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

            </Row>
            <Row> <Col className="mb-3 col-4" >
                <Form.Group>
                    <Form.Label size="sm">Название файла </Form.Label>
                    <Form.Control size="sm" type="text" value={fileName} required={true} onChange={(e) => setFileName(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>
            </Col>

                {!deleteFile && <Col className="mb-3 col-4"> <Button variant="outline-danger" size="sm" style={{ fontSize: '1rem', width: '100%', marginTop: '32px' }}
                    onClick={() => { setDeleteFile(true); handleUploadInputClick() }}
                >Выбрать другой файл</Button>

                </Col>}
                <Col className="mb-3 col-4" style={{display: deleteFile? 'block' : 'none'}}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Файл</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            ref={refInput}
                            required={deleteFile}
                            style={{ width: '100%',  }}
                            onChange={(e) => { setFiles(e.target.files[0]) }}
                        // setFiles([...files, { file: e.target.files, name: nameFile(index,), field: index }]) 
                        // accept="/image*,.png,.jpg,.jpeg"
                        />


                    </Form.Group>
                </Col>

            </Row>
            <Row> <Col sm={4}>
                <Form.Group className="mb-4">
                    <Form.Label size="sm">Описание</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        type="text"
                        placeholder="Описание "
                        value={description || ""}
                        size="sm"
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Group>
            </Col>

            </Row>
            <hr />
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            open(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Изменить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
    );
}
export default PatchDocumentationForm;
