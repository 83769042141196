/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert, Dropdown, Form } from "react-bootstrap";
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import Button from "react-bootstrap/esm/Button";
import AssetService from "../../API/AssetService";
import OrgService from "../../API/OrgService";
import UrlService from "../../API/UrlService";
import KfeedSummaryPAN from "./KfeedSummaryPAN";
import PanBadCatGraph from "./PanBadCatGraph";
import PanDangerCatGraph from "./PanDangerCatGraph";
import KfeedSummary from "./KfeedSummary";
import KfeedBadCat from "./KfeedBadCat";
import PanBadCatWrapper from "./PanBadCatWrapper";
import PanDangerCatWrapper from "./PanDangerCatWrapper";
import Graph from "../UrlFilter/DetailAsset/Graph";
function KfeedArea() {
    const [dateAfter, setDateAfter] = useState(null);
    const [dateBefore, setDateBefore] = useState(null);
    const [assetFilter, setAssetFilter] = useState('');
    const [assetFilterlist, setAssetFilterList] = useState([]);
    const [organisationFilterlist, setOrganisationFilterList] = useState([]);
    const [organisationFilter, setOrganisationFilter] = useState(null);
    const [statsSummaryPan, setStatsSummaryPan] = useState(null);
    const [badCategoriesPan, setBadCategoriesPan] = useState(null);
    const [dangerCategoriesPan, setDangerCategoriesPan] = useState(null);
    const [statsSummaryPanLoading, setStatsSummaryPanLoading] = useState(null);
    const [badCategoriesPanLoading, setBadCategoriesPanLoading] = useState(null);
    const [dangerCategoriesPanLoading, setDangerCategoriesPanLoading] = useState(null);
    const [statsSummaryKfeedLoading, setStatsSummaryKfeedLoading] = useState(null);
    const [badCategoriesKfeedLoading, setBadCategoriesKfeedLoading] = useState(null);
    const [statsSummaryKfeed, setStatsSummaryKfeed] = useState(null);
    const [badCategoriesKfeed, setBadCategoriesKfeed] = useState(null);
    const [allAssets, setAllAssets] = useState([])
    const clearFilters = () => {
        setDateAfter(null);
        setDateBefore(null);
        setAssetFilter('');
        setOrganisationFilter(null);
    };
    const fetchData = async () => {
        let filters = false;
        setBadCategoriesPanLoading(true);
        setDangerCategoriesPanLoading(true);
        setStatsSummaryPanLoading(true);
        setStatsSummaryKfeedLoading(true);
        setBadCategoriesKfeedLoading(true)
        if (dateAfter || dateBefore || assetFilter || organisationFilter) {
            filters = {
                date_after: dateAfter?.split("-").reverse().join("."),
                date_before: dateBefore?.split("-").reverse().join("."),
               serial_nr: assetFilter,
 
                organisation_id: organisationFilter?.id,
            };
        }
        await UrlService.checkStatsSummaryPAN(filters).then((res) =>
            setStatsSummaryPan(res)
        ).finally(()=> setStatsSummaryPanLoading(false));

        await UrlService.getBadCatStatsPAN(filters).then((res) =>
        setBadCategoriesPan(res)
   ).finally(()=> setBadCategoriesPanLoading(false));

        await UrlService.getDangerCatStatsPAN(filters).then((res) =>
        setDangerCategoriesPan(res)
        ).finally(()=> setDangerCategoriesPanLoading(false));

        await UrlService.getBadCatKfeed(filters).then((res) =>
        setBadCategoriesKfeed(res)
        ).finally(()=> setBadCategoriesKfeedLoading(false));

        await UrlService.getKfeedSummaryStats(filters).then((res) =>
        setStatsSummaryKfeed(res)
        ).finally(()=>  setStatsSummaryKfeedLoading(false));
    };
    useEffect(() => {
        const timerRefresh = setTimeout(fetchData, 500);
      return () => {
        clearTimeout(timerRefresh);
      };

    }, [dateAfter, dateBefore, organisationFilter, assetFilter]);
    useEffect(() => {
        (async () => {
            await OrgService.getDropList(false).then((res) =>
                setOrganisationFilterList(res)
            );
        })();
    }, []);
    useEffect(() => {
        (async () => {
            await UrlService.getUrlfAssetsDropdown(false).then((res) =>
                setAssetFilterList(res)
            );
        })();
    }, []);

    return (
        <>
            <Col>
                <h3 className="mb-3">KFEED</h3>
                <div style={{display: "flex", justifyContent: "flex-end", gap: 10}}>
<a href='#pandbid' style={{color: "blue", fontSize: 14}}>PAN DB</a>
<a href='#kfeedid'style={{color: "blue", fontSize: 14}}>KFEED</a>

                </div>
                <Row>
                {/* <Col className="mb-3 col-4" >
                    <Form.Label size="sm" >Поиск</Form.Label>
                    <Form.Control onChange={(e) => { setAssetFilter(e.target.value) }}
                        placeholder="Поиск по серийному номеру"
                        size="sm"
                        style={{ fontSize: '1rem', height: 38 }}
                        value={assetFilter}
                    />
                </Col> */}
                   <Col className="mb-3 col-3">
                         <Form.Label size="sm">Устройства</Form.Label>
                        <Dropdown>
                            <Dropdown.Toggle
                                as={MyToggleAsset}
                                id="dropdown-assetKfeed"
                            >
                                {assetFilter?.serial_nr || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                filterAssets={setAssetFilter}
                                type="assetFilter"
                            >
                                {assetFilterlist?.map((asset) => (
                                    <Dropdown.Item
                                        eventKey={"apl" + asset?.serial_nr}
                                        key={"apl" + asset?.serial_nr}
                                        onClick={() => {
                                            setAssetFilter(asset);
                                            setOrganisationFilter(null)
                                        }}
                                    >
                                        {asset?.serial_nr} 
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown> 
                    </Col>
                    <Col className="mb-3 col-2">
                        <Form.Label size="sm">Организация</Form.Label>
                        <Dropdown>
                            <Dropdown.Toggle
                                as={MyToggleAsset}
                                id="dropdown-orgKfeed"
                            >
                                {organisationFilter?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                filterAssets={setOrganisationFilter}
                                type="orgFilter"
                            >
                                {organisationFilterlist?.map((org) => (
                                    <Dropdown.Item
                                        eventKey={"aplOrg" + org?.id}
                                        key={"aplOrg" + org?.id}
                                        onClick={() => {
                                            setOrganisationFilter(org);
                                            setAssetFilter(null)
                                        }}
                                    >
                                        {org?.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-2">
                        <Form.Group>
                            <Form.Label style={{ fontSize: "12px" }}>
                                После
                            </Form.Label>
                            <Form.Control
                                disabled={false}
                                value={dateAfter || ""}
                                onChange={(e) => setDateAfter(e.target.value)}
                                type="date"
                            />
                        </Form.Group>
                    </Col>
                    <Col className="mb-3 col-2">
                        <Form.Group>
                            <Form.Label style={{ fontSize: "12px" }}>
                                До
                            </Form.Label>
                            <Form.Control
                                disabled={false}
                                value={dateBefore || ""}
                                onChange={(e) => setDateBefore(e.target.value)}
                                type="date"
                            />
                        </Form.Group>
                    </Col>
                    <Col className="col-2">
                       {(dateAfter || dateBefore || organisationFilter || assetFilter) &&  <Button
                            variant="outline-info"
                            size="sm"
                            style={{
                                width: "100%",
                                height: "38px",
                                margin: "32px 0 10px",
                            }}
                            onClick={() => clearFilters()}
                        >
                            Сбросить
                        </Button>}
                    </Col>
                </Row>
                <Row className="mb-3 mt-2 " style={{ minHeight: '300px', margin: '0 auto', padding: 0 }}>
                   <KfeedSummaryPAN data={statsSummaryPan} isLoading={statsSummaryPanLoading}/>
                   <PanBadCatWrapper  data={badCategoriesPan} isLoading={badCategoriesPanLoading}/>
                </Row>
                {/* <Row className="mb-3 mt-2 justify-content-center" style={{ minHeight: '300px', margin: '0 auto', padding: 0 }}>
                  <PanBadCatWrapper  data={badCategoriesPan} isLoading={badCategoriesPanLoading}/>
                </Row> */}
                <Row className="mb-3 mt-2 justify-content-center" style={{ minHeight: '400px', margin: '0 auto', padding: 0 }}>
                  <PanDangerCatWrapper  data={dangerCategoriesPan} isLoading={dangerCategoriesPanLoading}/>
                </Row>
                {/* <Row className="mb-3 mt-2 justify-content-start"  id="pandbid"style={{ minHeight: '300px', margin: '0 auto', padding: 0 }}>
                    <PanBadCatGraph dataGraph={badCategoriesPan} isLoading={badCategoriesPanLoading}/>
                    <PanDangerCatGraph dataGraph={dangerCategoriesPan} isLoading={dangerCategoriesPanLoading}/>
                </Row> */}
                <Row className="mb-3 mt-2" id="kfeedid"style={{ minHeight: '300px', margin: '0 auto', padding: 0 }}>
                    <KfeedSummary data={statsSummaryKfeed} isLoading={statsSummaryKfeedLoading}/>
                    <KfeedBadCat data={badCategoriesKfeed} isLoading={badCategoriesKfeedLoading}/>
                </Row>
                <Row className="mb-3 mt-2" id="kfeedid"style={{ minHeight: '300px', margin: '0 auto', padding: 0 }}>
                    <Graph sn={assetFilter?.serial_nr} visible={true} dateAfter={dateAfter} dateBefore={dateBefore}/>
                    </Row>
            </Col>
        </>
    );
}
export default KfeedArea;
