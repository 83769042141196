import Api from '.';
import axios from 'axios';
import { limit } from '../utils/constants';

export default class QuotaService {

    static async getList(filters, nextpage, allQuotas) {

        var url =  'api/v1/quotas/'
        const method = 'GET';
        const params = {};
    
        if (filters) {
            if (filters.partnerFilterAll) {
                params.partner_or_enduser_org = filters.partnerFilterAll;
            };
            
            if (filters.endUserFilter) {
                params.enduser_org = filters.endUserFilter;
            };
            if (filters.partnerFilter) {
                params.partner_org = filters.partnerFilter;
            };
            if (filters.statusFilter) {
                params.status = filters.statusFilter;
            };
            if (filters.searchText) {
                params.search = filters.searchText;
            };
            if (filters.isMainFilter) {
                params.is_main= filters.isMainFilter==='Да'? true: false;
            };
            if (filters.isSortId) {
               if(filters.isSortId==='↑') params.ordering='id'
                    else params.ordering='-id'
            };
            if (filters.isSortSumm) {
                if(filters.isSortSumm==='↑') params.ordering='total_price_sale_rub'
                     else params.ordering='-total_price_sale_rub'
             };
             if (filters.isSortDate) {
                if(filters.isSortDate==='↑') params.ordering='date_modified'
                     else params.ordering='-date_modified'
             };
             if (filters?.id) params.id = filters.id;

        };
    
        if (nextpage) {
            params.page = nextpage;
        }
        if (allQuotas) params.page='all'
        else params.limit = limit
        const api = new Api({url: url, params: params});
        const response = await api.request(method);
        return response.data;
    };

    static async getItem(id) {
        const url = `api/v1/quotas/${id}/`;
        const method = 'GET';

        const api = new Api({url: url});
        const response = await api.request(method);
        return response.data;
    };

    static async newQuota(quota) {
        const url = 'api/v1/quotas/';
        const method = 'POST';
        const data = quota;
        const api = new Api({url: url});
        const response = await api.request(method, data);
        return response.data;
    };

    static async updateQuota(id, quota) {

        const url = `api/v1/quotas/${id}/`;
        const method = 'PATCH';
        const data = quota;

        const api = new Api({url: url});
        const response = await api.request(method, data);
        return response.data;
    };
    static async downloadQuota(id, typeFile) {
    
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                responseType: 'arraybuffer',
            };
            var server = `https://${process.env.REACT_APP_API_SERVER}`;
            if (process.env.REACT_APP_API_SERVER.includes('localhost') ) {
                server = `http://${process.env.REACT_APP_API_SERVER}`;
            }
            let typeLink = typeFile === 'pdf' ? "generate_quota" : 'generate_excel'
            const response = await axios.get(`${server}/api/v1/quotas/${id}/${typeLink}/`, config);
            return response;
        }
        static async downloadQuotaKp(id) {
    
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                responseType: 'arraybuffer',
            };
            var server = `https://${process.env.REACT_APP_API_SERVER}`;
            if (process.env.REACT_APP_API_SERVER.includes('localhost') ) {
                server = `http://${process.env.REACT_APP_API_SERVER}`;
            }
        
            const response = await axios.get(`${server}/api/v1/quotas/${id}/generate_excel_co/`, config);
            return response;
        }
        static async getExportQuotaExls(filters) {
            var url = 'api/v1/quotas/export/'
            const method = 'GET';
            const params = {};
            if (filters) {
                if (filters.date_created_after) {
                    params.date_created_after = filters.date_created_after;
                };
                if (filters.date_created_before) {
                    params.date_created_before = filters.date_created_before;
                };
                
            };
            const api = new Api({ url: url, params: params, responseType:'arraybuffer' });
            const response = await api.request(method);
            return response.data;
        };
    
        static async syncQuota(id) {

            const url = `api/v1/quotas/${id}/sync-prices/`;
            const method = 'PATCH';
    
            const api = new Api({url: url});
            const response = await api.request(method);
            return response.data;
        };
        static async deleteQuota(id) {
            const url = `api/v1/quotas/${id}/`;
            const method = 'DELETE';
    
            const api = new Api({url: url});
            const response = await api.request(method);
            return response.data;
        };

}