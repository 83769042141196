import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import UrlService from "../../API/UrlService";
import Preloader from "../UI/Preloader/Preloader";
import { Alert, Button } from "react-bootstrap";
import help from "../../icons/help.svg";
function DetailFeed({ dataTable, mask, setVisible, url }) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState(null)
    const basicStylesTitles = {
        fontSize: "smaller",
        width: "20%",
        textAlign: "right",
    };
   
    const gradient = {
        width: '80%',
        height: '10px',
        background: 'linear-gradient(to right, yellow, #B00000)',
        position: 'relative'
      }
     const triangle = {
        width: 0, 
        height: 0,
        opacity: .4,
        borderLeft: '7px solid transparent',
        borderRight: '7px solid transparent',
        borderTop: '15px solid grey',
       }
      
    const [data, setData] = useState(dataTable || []);

    const fetchData = async () => {
        setIsPreloader(true);
        
        await UrlService.checkUrlKfeed(mask, url)
            .then((res) => {

                    setData(res);
                }

            )
            .catch((e) => setErrors(e.message))
            .finally(() => setIsPreloader(false));
    };
    useEffect(() => {
        if (mask) {
            fetchData();
        }
    }, [mask]);
    const getColor=(index)=> {
        let color ='black'
        switch(index){
            case '1': color = '#fad201'; break;
            case '2': color = '#FFCE03'; break;
            case '3' : color = '#FD9A01'; break;
            case '4': color = '#FD6104'; break;
            case '5' : color = '#B00000'; break; 
        }
        return color
    }
    return (
        <>
        <div style={{position: "relative", display: "flex", justifyContent:  'flex-end', marginBottom: '5px', }}><Button style={{height: 40}}variant="outline-primary" onClick={()=> setVisible(false)}>Закрыть</Button></div>
        <h5 style={{width: '580px', wordWrap: 'break-word'}}>{ mask || dataTable?.mask|| url}</h5>
        {isPreloader && <Preloader leftCoord={true}/>}
     
        {errors && errors?.map((item, index)=><Alert key="danger" variant="danger">{item}
            </Alert>)}
            <Table bordered striped hover id="idT" style={{ width: "600px"}}>
                
                <tbody>
                {data?.receipted_at  &&  <tr>
                        <th style={basicStylesTitles}>По данным {<br/>}kaspersky от: </th>
                        <td>
                           
                            {data?.receipted_at}
                           
                        </td>
                    </tr>}
                 {data?.attack_type  &&  <tr>
                        <th style={basicStylesTitles}>Attack type:</th>
                        <td>
                           
                            {data?.attack_type}
                           
                        </td>
                    </tr>}
                    
                   {data?.categories?.length>0 &&  <tr>
                        <th style={basicStylesTitles}>Categories</th>
                        <td>
                            {data?.categories?.map((item, index) => (
                                <span key={item.cat_id + "cat"}>
                                    {item.name}
                                    {index<(data?.categories?.length-1)? ", ": ''}
                                </span>
                            ))}
                        </td>
                    </tr>}
                    {data?.pa_categories?.length>0 &&  <tr>
                        <th style={basicStylesTitles}>PA Categories</th>
                        <td>
                            {data?.pa_categories?.map((item, index) => (
                                <span key={item + "pa_cat" + index}>
                                    {item}
                                    {index<(data?.pa_categories?.length-1)? ", ": ''}
                                </span>
                            ))}
                        </td>
                    </tr>}
                 {data?.files?.length > 0   &&  <tr>
                        <th style={basicStylesTitles}>Files</th>
                        <td>
                           
                                {data?.files?.map((item, index) => (
                                      <div
                                          key={index + "files"}
                                          style={{ fontSize: "10px" }}
                                      >
                                          {" "}
                                          <span>
                                              MD5: {item.MD5}
                                              <br />
                                          </span>
                                          <span>
                                              SHA1: {item.SHA1}
                                              <br />
                                          </span>
                                          <span>
                                              SHA256:{item.SHA256}
                                              <br />
                                          </span>
                                          <span>
                                              Threat: {item.threat}
                                              <br />
                                          </span>
                                          <hr />
                                      </div>
                                  ))}
                             
                        </td>
                    </tr>}
                
                {data?.first_seen   &&   <tr>
                        <th style={basicStylesTitles}>First seen</th>
                        <td>
                           {data?.first_seen}
                        </td>
                    </tr>}
                 {data?.geo?.length > 0 &&   <tr>
                        <th style={basicStylesTitles}>Geo</th>
                        <td>
                           
                                {data?.geo?.map((item) => (
                                      <span key={item}>
                                          {item}
                                          <br />
                                      </span>
                                  ))}
                          
                        </td>
                    </tr>}
                 {data?.industry?.length > 0 &&    <tr>
                        <th style={basicStylesTitles}>Industry</th>
                        <td>
                           
                                {data?.industry?.map((item) => (
                                      <span key={item}>
                                          {item}
                                          <br />
                                      </span>
                                  ))}
                              
                        </td>
                    </tr>}
                  {data?.ip?.length > 0 &&  <tr>
                        <th style={basicStylesTitles}>IP</th>
                        <td>
                            
                                 {data?.ip?.map((item) => (
                                      <span key={item}>
                                          {item}
                                          <br />
                                      </span>
                                  ))}
                                
                        </td>
                    </tr>}
                     {data?.last_seen && <tr>
                        <th style={basicStylesTitles}>Last seen</th>
                        <td>
                           {data?.last_seen}
                        </td>
                    </tr>}
                   {data?.lenth>0 && data?.phishing_kit!=='[]'  && <tr>
                        <th style={basicStylesTitles}>Phishing kit</th>
                        <td>
                           
                                 {data?.phishing_kit}
                            
                        </td>
                    </tr>}
                     {data?.popularity &&  <tr>
                        <th style={basicStylesTitles}>Popularity{' '}<img
                            title={'популярность — индекс, определяющий популярность записи (на сколько пользователей повлияла эта запись). Самый популярный индекс 5, а наименее популярный  1.'}
                            style={{ width: 20, height: 20, cursor: "pointer" }}
                            src={help}
                            alt="tooltip"
                        ></img></th>
                        
                        <td>{data?.popularity==='0'? 'Нет данных': <div style={{display: "flex", alignItems: "flex-end", height: '40px', width: '100%'}}>
                        <div style={gradient}>
                            <div style={{position: "absolute", top: '-38px', left: `${((data?.popularity -1)* 25)-2}%`, display: "flex", flexDirection: "column", alignItems: "center", color: getColor(data?.popularity) }}><span>{data?.popularity}</span><div style={triangle}></div></div></div>
                        </div>}
                        </td>
                    </tr>}
                 {data?.stolen_data_type?.length > 0  &&   <tr>
                        <th style={basicStylesTitles}>Stolen data type</th>
                        <td>
                            
                                {data?.stolen_data_type?.map((item, index) => (
                                      <div key={item + index}>
                                          <span>{item}</span>
                                      </div>
                                  ))}
                             
                        </td>
                    </tr>}
                      {data?.targeted_organization && <tr>
                        <th style={basicStylesTitles}>Targeted organization</th>
                        <td>
                        
                                 {data?.targeted_organization}
                               
                        </td>
                    </tr>}
                  {data?.type &&  <tr>
                        <th style={basicStylesTitles}>Type</th>
                        <td>{data?.type}</td>
                    </tr>}
                  {/* {data?.whois && <tr>
                        <th style={basicStylesTitles}>Whois</th>
                        <td>
                            
                                <div style={{ fontSize: "10px" }}>
                                    {" "}
                                    <span>
                                        MX: {data?.whois.MX}
                                        <br />
                                    </span>
                                    <span>
                                        MX ips: {data?.whois.MX_ips}
                                        <br />
                                    </span>
                                    <span>
                                        NS: {data?.whois.NS}
                                        <br />
                                    </span>
                                    <span>
                                        NS ips: {data?.whois.NS_ips}
                                        <br />
                                    </span>
                                    <span>
                                        City: {data?.whois.city}
                                        <br />
                                    </span>
                                    <span>
                                        Country: {data?.whois.country}
                                        <br />
                                    </span>
                                    <span>
                                        Created: {data?.whois.created}
                                        <br />
                                    </span>
                                    <span>
                                        Domain: {data?.whois.domain}
                                        <br />
                                    </span>
                                    <span>
                                        Email: {data?.whois.email}
                                        <br />
                                    </span>
                                    <span>
                                        Expires: {data?.whois.expires}
                                        <br />
                                    </span>
                                    <span>
                                        Name: {data?.whois.name}
                                        <br />
                                    </span>
                                    <span>
                                        Org: {data?.whois.org}
                                        <br />
                                    </span>
                                    <span>
                                        Registrar email:{" "}
                                        {data?.whoisregistrar_email}
                                        <br />
                                    </span>
                                    <span>
                                        Registrar name:{" "}
                                        {data?.whois.registrar_name}
                                        <br />
                                    </span>
                                    <span>
                                        Updated: {data?.whois.updated}
                                        <br />
                                    </span>
                                    <hr />
                                </div>
                             
                        </td>
                    </tr>} */}
                </tbody>
            </Table>
        </>
    );
}

export default DetailFeed;
