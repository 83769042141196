/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Preloader from "../UI/Preloader/Preloader";
import Button from 'react-bootstrap/Button';
import EdlList from "./EdlList";
import AddEdlForm from "./AddEdlForm";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import MyModal from "../UI/MyModal/MyModal";
import { AuthContext } from "../../context";
import Alert from 'react-bootstrap/Alert';
import EdlService from "../../API/EdlService";
import PatchEdlForm from "./PatchEdlForm";
import EdlItemCard from "./EdlItemCard";
import PlatformService from "../../API/PlatformService";
import EdlPlatformList from "./EdlPlatformList";
import EdlSubsList from "./EdlSubsList";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import EdlTrialList from "./EdlTrialList";
import bolt from '../../icons/bolt.svg';
function EdlArea() {

    const [isPreloader, setIsPreloader] = useState(false);
    const [key, setKey] = useState('access');
    const [errors, setErrors] = useState([]);
    const [viewDetail, setViewDetail] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [viewPlatforms, setViewPlatforms] = useState(false);
    const [platformsList, setPlatformsList] = useState(null);
    const [platformPatch, setPlatformPatch] = useState(false);
    const [viewSubs, setViewSubs] = useState(false)
    //пагинация
    const [isListLoading, setIsListLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isAddingInstance, setIsAddingInstance] = useState(false);
    const [patchItem, setPatchItem] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false)
    const [edlItems, setEdlItems] = useState();
    const [renew, setRenew] = useState(false);
    const [switchSub, setSwitchSub] = useState(false);
    const [successSwitch, setSuccessSwitch] = useState(false);
    const [stats, setStats] = useState(false)

    // поиск и фильтрация
    const [searchText, setSearchText] = useState("");
    const [statusFilter, setStatusFilter] = useState(false);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    useEffect(() => {

        (async () => {

            await PlatformService.getList().then((res) => setPlatformsList(res)).catch(() => changeStatus());
            await EdlService.getEdlStats().then((res)=> setStats(res)).catch(()=> changeStatus())

        })();
    }, [platformPatch]);
    const fetchData = async (add) => {
        let filters = {is_trial: false};

        if (searchText || statusFilter) {
          

                filters.searchText = searchText
                filters.statusFilter= statusFilter
     
        }

        setIsPreloader(true);
        setIsListLoading(true);

        await EdlService.getList(filters, add ? nextPage : 1)
            .then((res) => {
                if (add === 'add') { res.results?.map(item => setEdlItems(arr => [...arr, item])); setNextPage(res.next ? nextPage + 1 : null); }
                else { setEdlItems(res.results); setNextPage(res.next ? 2 : null); }
                setIsListLoading(false);
            })
            .catch((err) => { changeStatus(); setIsListLoading(false); setErrors(err.message) }).finally(() => setIsPreloader(false))
    }
    useEffect(() => {
        if (!isAddingInstance && !patchItem ) {
            setIsPreloader(true)
            const timerRefresh = setTimeout(fetchData, 1000);
            return () => {

                clearTimeout(timerRefresh)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingInstance, searchText, statusFilter, patchItem, successDelete, renew, successSwitch]);

    useEffect(() => {

        (async () => {

            if (needToLoad && nextPage) {
                await fetchData('add');
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {

        if (isListLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

    }, [isListLoading]);

    const clearFilters = () => {

        setSearchText("");

        setStatusFilter(false);

    }
    const deleteInstance = async (id) => {
        setIsPreloader(true);
        await EdlService.deleteItem(id)
            .then(() => { setIsPreloader(false); setDeleteItem(false); setSuccessDelete(!successDelete) })
            .catch((e) => {
                
                 setIsPreloader(false); setErrors(e.message) }
            );
    }
    const switchKfeedSub = async (item) => {
        setIsPreloader(true);
        await EdlService.switchKfeedSubscription(item.id, !item.subscription_extras?.kfeed)
            .then(() => { setIsPreloader(false); setSwitchSub(false); setSuccessSwitch(!successSwitch) })
            .catch((e) => {
                
                 setIsPreloader(false); setErrors(e.message) }
            );
    }

    return (
        <>

            {viewDetail && <MyModal visible={viewDetail} setVisible={setViewDetail} align={true}>
                <div style={{ width: '50vw', margin: '0 auto', maxHeight: '80vh', overflowY: 'scroll' }}>
                    <EdlItemCard data={currentItem} />
                </div>
            </MyModal>}
            {deleteItem && <MyConfirm visible={deleteItem} setVisible={setDeleteItem} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите удалить доступ?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteInstance(currentItem.id) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItem(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyConfirm>}
            {switchSub&& <MyConfirm visible={switchSub} setVisible={setSwitchSub} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите {currentItem?.subscription_extras?.kfeed? 'отключить': 'подключить'} kfeed подписку?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); switchKfeedSub(currentItem) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setSwitchSub(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyConfirm>}

            <Col>

               <Row className="justify=content-between"><Col><h3 className="mb-3">EDL CLOUD</h3></Col> 
              <Col> <div style={{ height: 90, padding: '10px 10px 3px 0 ', fontSize: '20px', display: 'flex', flexDirection: 'row', marginTop: 10 }} className="badge bg-light text-dark">
                            <img style={{ width: 50, height: 50 }} src={bolt} alt='badge'></img>
                            <div style={{display: "flex", flexDirection:"column", width: '400px', gap: 20}}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", width: '100%', alignItems: "center" }}>
                               
                                <span style={{ fontSize: '10px' }}>Время последнего получения фидов{<br/>} Касперского на DTL</span>
                                <span>{stats?.received || ""}</span></div>
                       
                        
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", width: '100%', alignItems: "center" }}>
                               
                               <span style={{ fontSize: '10px' }}>Кол-во записей в полученном фиде</span>
                               <span>{stats?.entries_cnt || ""}</span></div>
                       </div>
                        </div>
                        </Col>
               </Row>

                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Tabs
                    id="edl-tabs"
                    activeKey={key}
                    onSelect={(key) => setKey(key)}
                    className="mb-3"
                >
                    <Tab eventKey="access" title="Доступы" >
                        {!isAddingInstance && <Col className="mb-3 col-2">
                            <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                                onClick={() => { setIsAddingInstance(true) }}
                            >Добавить доступ</Button>

                        </Col>}
                        {isAddingInstance && <AddEdlForm isAddingInstance={isAddingInstance} setIsAddingInstance={setIsAddingInstance} />} <hr />
                        {patchItem &&
                            <PatchEdlForm item={currentItem} setPatchItem={setPatchItem} />
                        }
                        <Row className="justify-content-start">

                            <Col className="mb-3 col-2" >
                                <Form.Label size="sm">Статус</Form.Label>
                                <Dropdown >
                                    <Dropdown.Toggle as={MyToggleAsset} id="dropdown-rma" >
                                        {statusFilter || "выбрать"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        as={MyMenu}
                                        filter={setStatusFilter}
                                        type='status'
                                    >
                                        <Dropdown.Item eventKey={"sf" + 1} key={"sf" + 1} onClick={() => { setStatusFilter('Активен') }}>{'Активен'}</Dropdown.Item>
                                        <Dropdown.Item eventKey={"sf" + 2} key={"sf" + 2} onClick={() => { setStatusFilter('Не активен') }}>{'Не активен'}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {(searchText || statusFilter) &&
                                <Col className="col-2">

                                    <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', margin: '32px 0 10px' }} onClick={() => clearFilters()}>Сбросить</Button>
                                </Col>}

                        </Row>

                        <Row>
                            <Col style={{ position: "relative" }}>

                                <EdlList edlItems={edlItems} 
                                currentItem={currentItem} setCurrentItem={setCurrentItem}
                                 setPatchItem={setPatchItem} setViewDetail={setViewDetail} 
                                 setDeleteItem={setDeleteItem}
                                 setSwitchSub={setSwitchSub}
                                 />
                                {isPreloader && <Preloader />}
                            </Col>
                        </Row>
                        <div ref={lastItem} style={{ height: 20 }} />
                    </Tab>
                    <Tab eventKey="trial" title="Доступы (trial)" >
                      {key==='trial' &&  <EdlTrialList renew={renew} setRenew={setRenew}/>}
                    </Tab>
                    <Tab eventKey="limits" title="Лимиты (платформы)" >
                        <EdlPlatformList data={platformsList} platformPatch={platformPatch} setPlatformPatch={setPlatformPatch} /></Tab>
                    <Tab eventKey="subs" title="Подписки" >
                        <EdlSubsList />
                    </Tab>
                </Tabs>



            </Col>
        </>
    );
}
export default EdlArea;