export const fileTypeSandboxCreate= [
    {title: 'pe', key: 'pe'},
    {title: 'pdf', key: 'pdf'},
    {title: 'ms-office', key: 'ms-office'},
    {title: 'jar', key: 'jar'},
    {title: 'flash', key: 'flash'},
    {title: 'archive', key: 'archive'},
    {title: 'script', key: 'script'},
    {title: 'apk', key: 'apk'},
    {title: 'linux', key: 'linux'},
]
export function changeHandler(current, arr, set) {

    if (arr?.some((item) => item === current) !== true) {
        set([...arr, current]);
        //setSlugFilter(true)
    } else { const index = arr?.findIndex((item) => current === item); set([...arr?.slice(0, index), ...arr?.slice(index + 1)]) }
}
export const getVerdictBadgeKasper = (verdict, item) => {
    if (verdict === "Malware")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Not applicable")
      return (
        <span
          style={{
            marginRight: 10,
            fontSize: "x-small",
            background: "#ff7a45",
          }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Adware and other")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Not categorized")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Clean")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
      else if (verdict === "NotAVirus")
      return (
        <span
        style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
  };
  export const getVerdictPaBadge = (verdict, item) => {
    if (verdict === "Benign")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "black" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unsupported")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Error")
      return (
        <span
          style={{
            marginRight: 10,
            fontSize: "x-small",
            background: "#9e1068",
          }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge "
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  export const getVerdictBadgeForti = (verdict, item) => {
    if (verdict === "Clean")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Low Risk")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  export const getVerdictTVBadge = (verdict, item) => {
    if (verdict === "Benign")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "black" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge "
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  export const getSeverityBadge = (severity, item) => {
    if (severity=== 0)
      return (
        <span
          key={item.cat_id}
          style={{ fontSize: "x-small", background: "black" }}
          className="badge bg-success"
        >
          {item?.name}
        </span>
      );
    else if (severity=== 1)
      return (
        <span
        key={item.cat_id}
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {item.name}
        </span>
      );
    else if (severity === 2)
      return (
        <span
        key={item.cat_id}
          style={{
            
            fontSize: "x-small",
   
          }}
          className="badge bg-danger"
        >
     {item.name}
        </span>
      );
   
  };
  export const sortFileTypeS=(arr, order)=> {
    if (arr) {
   
      const sortedArray = arr.sort((a,b)=>  order? b.localeCompare(a): a.localeCompare(b));
      return sortedArray
    }
    else return []
  }
  export const manualFilter = (arr, producer, fileType, online, ordering, setArr) => {
    let filterProducer = []  
    if (producer) {
      filterProducer = arr.filter((item)=> item.type === producer)
    }
    else filterProducer = arr.slice()
    let filterType= []

    if (fileType) filterType = filterProducer?.filter((item)=> { return item.supported_file_types?.includes(fileType)})
    else filterType = filterProducer.slice()
  let onlineFilter = []
  if (online) onlineFilter = filterType.filter((item)=> {return getStatus(item.status, item.type, item.is_active)}) 
  else onlineFilter = filterType.slice()

let queueArr= []
if (ordering) {
  if (ordering[0]==='-')  queueArr = onlineFilter?.sort((a, b)=> ordering?.includes('queue')? b[ordering.replace('-','')]- a[ordering.replace('-','')] :b[ordering.replace('-','')].localeCompare(a[ordering.replace('-','')]) )
  else queueArr = onlineFilter?.sort((a, b)=> ordering?.includes('queue')?a[ordering.replace('-','')]- b[ordering.replace('-','')]: a[ordering.replace('-','')].localeCompare(b[ordering.replace('-','')]))

} else queueArr = onlineFilter.slice();
setArr(queueArr)
  }

  const getStatus=(status, type, active)=> {
    if (type==="kaspersky") return true
    if (type==="kaspersky_sandbox") {
      return active
    }
    if (status?.status===200) return true
    return false
  }

