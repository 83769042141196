import Api from ".";

export default class AssetService {
    static async getList(id, filters, nextpage) {
        var url = "api/v1/assets/";
        // var url = 'api/v1/assets/'
        const method = "GET";
        const params = {};

        if (filters) {
            if (filters.platform__id) {
                params.platform__id = filters.platform__id;
            }
            if (filters.search) {
                params.search = filters.search;
            }
            if (filters.city) {
                params.city = filters.city;
            }
            if (filters.country) {
                params.country = filters.country;
            }
            if (filters.slugFilter) {
             params.slug = filters.slugFilter.map((item, index) =>   item).join(',');
            }
            if (filters.rma) {
                params.rma = filters.rma;
            }
        }

        if (nextpage) {
            params.page = nextpage;
            // url = process.env.REACT_APP_API_SERVER.includes('localhost') ? nextpage : nextpage.slice(23);
        }
        if (id) {
            params.page = "all";
            params.organisation__id = id;
        } else {
            params.limit = 20;
        }

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    }

    static async newAsset(asset) {
        const url = "api/v1/assets/";
        const method = "POST";
        const data = asset;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    }
    static async assetDropdown( filters) {
        const params = {};
        if (filters) {
      
        }
        const url = "api/v1/assets/dropdown/";
        const method = "GET";
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    }
    static async getStats() {
        const url = "api/v1/asset-stats/";
        const method = "GET";
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }

    static async getCities(filters) {
        var url = "api/v1/asset-cities/?page=all";
        const method = "GET";
        const params = {};

        if (filters) {
            params.search = filters.searchText;
        }

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    }
    static async getCountries(filters) {
        var url = "api/v1/asset-countries/?page=all";
        const method = "GET";
        const params = {};

        if (filters) {
            params.search = filters.searchText;
        }

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    }

    static async getItem(id) {
        const url = `api/v1/assets/${id}/`;
        const method = "GET";

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }
    static async patchAsset(asset) {
        const url = `api/v1/assets/${asset.id}/`;
        const data = asset;
        const method = "PATCH";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }

    static async postSubAsset(id, sub) {
        const url = `api/v1/assets/${id}/subscriptions/`;
        const data = sub;
        const method = "POST";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }

    static async patchSubAsset(id, subId, sub) {
        const url = `api/v1/assets/${id}/subscriptions/${subId}/`;
        const data = sub;

        const method = "PATCH";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }

    static async deleteSubAsset(id, subId) {
        const url = `api/v1/assets/${id}/subscriptions/${subId}/`;

        const method = "DELETE";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

    static async postServiceAsset(id, service) {
        const url = `api/v1/assets/${id}/service/`;
        const data = service;
        const method = "POST";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }

    static async deleteServiceAsset(id, serviceId) {
        const url = `api/v1/assets/${id}/service/${serviceId}/`;

        const method = "DELETE";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

    static async patchServiceAsset(id, serviceId, service) {
        const url = `api/v1/assets/${id}/service/${serviceId}/`;
        const data = service;

        const method = "PATCH";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    static async postCloudAsset(id, cloud) {
        const url = `api/v1/assets/${id}/clouds/`;
        const data = cloud;
        const method = "POST";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }

    static async deleteCloudAsset(id, cloudId) {
        const url = `api/v1/assets/${id}/clouds/${cloudId}/`;

        const method = "DELETE";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

    static async patchCloudAsset(id, cloudId, cloud) {
        const url = `api/v1/assets/${id}/clouds/${cloudId}/`;
        const data = cloud;

        const method = "PATCH";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    static async getSlug() {
        var url = "api/v1/asset-slugs/?page=all";
        const method = "GET";

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }

    static async dublicateAsset(id, serial) {
        const url = `api/v1/assets/${id}/duplicate/`;
        const data = {};
        data.serial_nr = serial;
        const method = "POST";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    static async deleteAsset(id) {
        const url = `api/v1/assets/${id}/`;
        const method = "DELETE";
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

    static async getExportExls() {
        var url = "api/v1/assets/export/";
        const method = "GET";
        const api = new Api({
            url: url,
            responseType: "arraybuffer",
        });
        const response = await api.request(method);
        return response.data;
    }
}
