import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Table from "react-bootstrap/Table";
import ImgDelete from "../../icons/delete.svg";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import Preloader from "../UI/Preloader/Preloader";
import DocumentationService from "../../API/DocumentationService";
import ImgNotes from "../../icons/notes.svg";
import IconEdit from "../../icons/edit.svg";
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import Alert from "react-bootstrap/Alert";
import PimService from "../../API/PimService";
import linux from "../../icons/linux.svg";
import windows from "../../icons/windows.svg";
const AgentSoftwareList = ({
    addDocumentAgent,
    setAddDocumentAgent,
    successAdd,
    setSuccessAdd,
    setViewDetail,
    setPatchDocument,
    patchDocument,
}) => {
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState([]);
    const [addDocument, setAddDocument] = useState(false);
    // const [successAdd, setSuccessAdd] = useState(false);
    const [successPatch, setSuccessPatch] = useState(false);
    const [currentInstance, setCurrentInstance] = useState(null);
    const [isPreloader, setIsPreloader] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const basicStyles = {
        fontSize: "14px",
        cursor: "pointer",
    };
    const viewExtension = (file) => {
        if (file) {
            let arr = file.split("/");
            let string = arr[arr.length - 1];

            //  let index = stringType.lastIndexOf(".");
            return string;
        } else return "";
    };
    const fetchData = async (add) => {
        setIsPreloader(true);
        setIsPreloader(true);
        await PimService.getPimAgentsSoftList(add ? nextPage : 1)
            .then((res) => {
                if (add === "add") {
                    setNextPage(res.next ? nextPage + 1 : null);
                    res.results?.map((item) =>
                        setData((arr) => [...arr, item])
                    );
                } else {
                    setData(res.results);
                    setNextPage(res.next ? 2 : null);
                }
            })
            .catch((err) => {
                setErrors(err.message);
            })
            .finally(() => {
                setIsPreloader(false);
            });
    };
    useEffect(() => {
        fetchData();
    }, [successAdd, successDelete, successPatch]);

    useEffect(() => {
        if (isPreloader) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreloader]);

    useEffect(() => {
        (() => {
            if (needToLoad && nextPage) {
                fetchData("add");
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem("freshToken"))
            .then((res) => {
                localStorage.setItem("token", res.data.access);
                setErrors([]);
                if (type === "save") deleteInstance();
            })
            .catch(() => changeStatus());
    };

    const deleteInstance = async (id, type, major) => {
        setIsPreloader(true);
        await PimService.deleteAgentInstance(id, type, major)
            .then(() => {
                setIsPreloader(false);
                setDeleteItem(false);
                setSuccessDelete(!successDelete);
            })
            .catch((e) => {
                if (e.message[0].includes("token")) refreshToken("delete");
                else {
                    setIsPreloader(false);
                    setErrors(e.message);
                }
            });
    };

    return (
        <>
            {deleteItem?.open && (
                <MyConfirm
                    visible={deleteItem?.open}
                    setVisible={setDeleteItem}
                >
                    <div style={{ width: 700 }}>
                        <Row className="mb-4" style={{ position: "relative" }}>
                            {isPreloader && <Preloader leftCoord={true} />}
                            <h3 className="mb-4 mt-4">
                                Вы точно хотите удалить{" "}
                                {deleteItem?.type === "major" ? (
                                    <span>
                                        версию &nbsp;релиза&nbsp;
                                        {deleteItem?.item.major_version}? {<br/>}Это
                                        так-же удалит все файлы
                                        относящиеся к ней.
                                    </span>
                                ) : (
                                    <span>
                                        файл?
                                    </span>
                                )}
                            </h3>
                        </Row>
                        <Row>
                            {" "}
                            {errors.length > 0 &&
                                errors.map((error) => (
                                    <Alert key="danger" variant="danger">
                                        {error}
                                    </Alert>
                                ))}
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    type="submit "
                                    style={{ width: "100%" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteInstance(
                                            deleteItem.id,
                                            deleteItem?.type,
                                            deleteItem?.major
                                        );
                                    }}
                                >
                                    Да
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setDeleteItem(false);
                                        setErrors([]);
                                    }}
                                    style={{ width: "100%" }}
                                >
                                    нет
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </MyConfirm>
            )}
            <Row style={{ position: "relative" }}>
                {isPreloader && <Preloader />}
            </Row>
            <Row>
                <Col>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 5,
                        }}
                    >
                        {" "}
                        <span>Агентское ПО</span>
                        <Button
                            variant="outline-success"
                            size="sm"
                            style={{ fontSize: "1rem" }}
                            onClick={() => {
                                setAddDocumentAgent(true);
                            }}
                        >
                            Добавить
                        </Button>
                    </div>
                    <div
                        style={{
                            maxHeight: 400,
                            height: 400,
                            overflowY: "scroll",
                        }}
                    >
                        <Table striped bordered hover id="tableTokens">
                            <thead style={basicStyles}>
                                <tr>
                                    <th style={{ width: "25%" }}>
                                        Версия {<br/>}релиза
                                    </th>
                                    <th>Тип ОС</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, index) => (
                                    <tr
                                        key={
                                            item.major_version_id + "agentsSoft"
                                        }
                                        style={basicStyles}
                                    >
                                        <td>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center"    
                                                }}
                                            >
                                                <span style={{fontWeight: 600}}>
                                                    {item.major_version}<br/>
                                                </span>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: 0,
                                                        
                                                    }}
                                                >
                                                    <img
                                                        width="14"
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                        className="interactive_hover"
                                                        alt={"release notes"}
                                                        src={ImgNotes}
                                                        onClick={(evt) => {
                                                            evt.stopPropagation();
                                                            setViewDetail({
                                                                open: true,
                                                                id: item.major_version_id,
                                                                type: "agent",
                                                            });
                                                        }}
                                                    />
                                                    <img
                                                        width="14"
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                        className="interactive_hover"
                                                        alt={"edit"}
                                                        src={IconEdit}
                                                        onClick={(evt) => {
                                                            evt.stopPropagation();
                                                            setPatchDocument({
                                                                open: true,
                                                                id: item.major_version_id,
                                                                type: "agent",
                                                            });
                                                        }}
                                                    />
                                                    <img
                                                        width="14"
                                                        className="interactive_hover"
                                                        alt={"download"}
                                                        src={ImgDelete}
                                                        onClick={(evt) => {
                                                            evt.stopPropagation();
                                                            setDeleteItem({
                                                                open: true,
                                                                type: "major",
                                                                item: item,
                                                                id: item.major_version_id,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div><span style={{fontSize: 12}}>{item.release_date}</span>
                                            <div
                                                                style={{
                                                                    background:
                                                                      item?.is_visible ?   "#adc6ff" : '#ffbb96',
                                                                    textAlign:
                                                                        "center",
                                                                    width: 55,    
                                                                    borderRadius:
                                                                        "4px",
                                                                    color: "white",
                                                                    padding:
                                                                        "0 4px",
                                                                        height: 20
                                                                }}
                                                            >
                                                            {item?.is_visible? 'Visible': 'Hidden'}
                                                            </div> 
                                            </div>
                                        </td>
                                        <td>
                                            {item.releases?.map((release) => {
                                                return (
                                                    <div
                                                        style={{
                                                            marginBottom: 5,
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                        key={
                                                            release?.release_id +
                                                            "versionAgents" +
                                                            release.os_type
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                    gap: 5
                                                            }}
                                                        >
                                                            <img
                                                                width="14"
                                                             
                                                                className="interactive_hover"
                                                                alt={"os type"}
                                                                src={
                                                                    release.os_type ===
                                                                    "linux"
                                                                        ? linux
                                                                        : windows
                                                                }
                                                            />
                                                            <span>
                                                                {release.os_type ===
                                                                "linux"
                                                                    ? "Linux"
                                                                    : "Windows"}
                                                            </span>
                                                            <div
                                                                style={{
                                                                    background:
                                                                        "#e6f7ff",
                                                                    textAlign:
                                                                        "center",
                                                                    borderRadius:
                                                                        "4px",
                                                                
                                                                    padding:
                                                                        "0 2px",
                                                                }}
                                                            >
                                                                {viewExtension(
                                                                    release.release_package
                                                                )}
                                                            </div>
                                                          
                                                            <span>
                                                                (
                                                                {(
                                                                    release.release_size /
                                                                    (1024 *
                                                                        1024)
                                                                ).toFixed(
                                                                    2
                                                                )}{" "}
                                                                Мб.)
                                                            </span>

                                                        </div>
                                                        <div
                                                            key={
                                                                release?.release_version +
                                                                "actions"
                                                            }
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <img
                                                                width="14"
                                                                className="interactive_hover"
                                                                alt={"delete"}
                                                                src={ImgDelete}
                                                                onClick={(
                                                                    evt
                                                                ) => {
                                                                    evt.stopPropagation();
                                                                    setDeleteItem(
                                                                        {
                                                                            open: true,
                                                                            type: "release",
                                                                            item: release,
                                                                            id: release.release_id,
                                                                            major: item?.major_version_id,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div ref={lastItem} style={{ height: 20 }}></div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default AgentSoftwareList;
