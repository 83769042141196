import PricePage from "../pages/PricePage"
import QuotatorPage from "../pages/QuotatorPage";
import LoginPage from "../pages/LoginPage";
import PassRecoverPage from "../pages/PassRecoverPage";
import MyOrgPage from "../pages/MyOrgPage";
import AllOrgsPage from "../pages/AllOrgsPage";
import PasswordRecoverConfirm from "../components/UI/PasswordRecoverConfirm";
import ContractPage from "../pages/ContractPage";
import AssetPage from "../pages/AssetPage";
import OrgDetailPage from "../pages/OrgDetailPage";
import ContractDetailPage from "../pages/ContractDetailPage";
import AssetDetailPage from "../pages/AssetDetailPage";
import ServicePage from "../pages/ServicePage";
import Dashboard from "../pages/Dashboard";
import UrlFilterPage from "../pages/UrlFilterPage";
import WarehousePage from "../pages/WarehousePage";
import ThreatVaultPage from "../pages/ThreatVaultPage";
import CloudStatusPage from "../pages/CloudStatusPage";
import EdlPage from "../pages/EdlPage";
import UsersPage from "../pages/UsersPage";
import UserDetailPage from "../pages/UserDetailPage";
import UpdatesCloudPage from "../pages/UpdatesCloudPage";
import ApiGatewayPage from "../pages/ApiGatewayPage";
import DocumentationPage from "../pages/DocumentationPage";
import SandboxPage from "../pages/SandboxPage";
import PimPage from "../pages/PimPage";
import KfeedPage from "../pages/KfeedPage";

export const vendorRoutes = [
    
    { path: 'price', component: <PricePage /> },
    { path: 'quotator', component: <QuotatorPage /> },
    { path: 'orglist', component: <AllOrgsPage /> },
    { path: 'myorg', component: <MyOrgPage /> },
    { path: 'contract', component: <ContractPage /> },
    { path: 'asset', component: <AssetPage /> },
    { path: 'service', component: <ServicePage /> },
    { path: '*', component: <Dashboard /> },
    { path: 'orglist/:idOrg', component: <OrgDetailPage/> },
    { path: 'contract/:idCont', component: <ContractDetailPage/> },
    { path: 'asset/:idAss', component: <AssetDetailPage /> },
    { path: 'dashboard', component: <Dashboard /> },
    { path: 'urlfilter', component: <UrlFilterPage /> },
    { path: 'warehouse', component: <WarehousePage /> },
    { path: 'threatvault', component: <ThreatVaultPage /> },
    { path: 'cloud_status', component: <CloudStatusPage /> },
    { path: 'edl', component: <EdlPage /> },
    { path: 'users', component: <UsersPage /> },
    { path: 'users/:idUser', component: <UserDetailPage/> },
    { path: 'updates_cloud', component: <UpdatesCloudPage /> },
    { path: 'gateway', component: <ApiGatewayPage /> },
    { path: 'documentation', component: <DocumentationPage /> },
    { path: 'sandbox', component: <SandboxPage /> },
    { path: 'pim', component: <PimPage /> },
    { path: 'kfeed', component: <KfeedPage /> },
]

export const otherPrivateRoutes = [
    { path: 'myorg', component: <MyOrgPage /> },
    { path: '*', component: <MyOrgPage /> },
]

export const publicRoutes = [
    { path: 'login/:error', component: <LoginPage /> },
    { path: 'passrecover', component: <PassRecoverPage /> },
    { path: 'passrecoverconfirm/:uid/:token/', component: <PasswordRecoverConfirm /> },
    { path: '*', component: <LoginPage /> },
]
export const managerRoutes = [
    { path: '*', component: <PricePage /> },
    { path: 'quotator', component: <QuotatorPage /> },
    { path: 'contract', component: <ContractPage /> },
    { path: 'contract/:idCont', component: <ContractDetailPage/> },
    { path: 'myorg', component: <MyOrgPage /> },
]
export const salesRoutes = [
    { path: '*', component: <Dashboard /> },
    { path: 'price', component: <PricePage /> },
    { path: 'quotator', component: <QuotatorPage /> },
    { path: 'contract', component: <ContractPage /> },
    { path: 'contract/:idCont', component: <ContractDetailPage/> },
    { path: 'myorg', component: <MyOrgPage /> },
    { path: 'warehouse', component: <WarehousePage /> },
]