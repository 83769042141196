import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import PlatformService from "../../API/PlatformService";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import WarehouseService from "../../API/WarehouseService";
import './Warehouse.css';
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

function AddWarehouseForm(props) {
    registerLocale('ru', ru)
    const [serialNumber, setSerialNumber] = useState('');
    const [valid, setVaild] = useState(false);
    const [validPlatform, setValidPlatform] = useState(false)
    const [assetOrg, setAssetOrg] = useState(null);
    const [assetPlatform, setAssetPlatform] = useState(null);
    const [allPlatforms, setAllPlatforms] = useState(null);
    const [price, setPrice] = useState(null);
    const [buyDate, setBuyDate] = useState(null);
    const [files, setFiles] = useState(null);
    const [supplier, setSupplier] = useState('');
    const [isPreloader, setIsPreloader] = useState(false);
    const [patchDate, setPatchDate] = useState(null);
    const [assetComment, setAssetComment] = useState('');

    const [isDocuments, setIsDocuments] = useState(true);
    const [isNew, setIsNew] = useState('new');
    const [isBox, setIsBox] = useState(true);
    const [isWarehouse, setIsWarehouse] = useState(true);

    const [allOrganisations, setAllOrganisations] = useState(null);
    const [errors, setErrors] = useState([]);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {

        (async () => {

            await OrgService.getDropList().then((res) => setAllOrganisations(res)).catch(()=> changeStatus());

            await PlatformService.getList().then((res) => setAllPlatforms(res)).catch(()=> changeStatus());;

        })();

    }, []);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                saveAsset();
            })
            .catch(() => changeStatus())
    }

    const saveAsset = async event => {
        if (event) event.preventDefault();
        setIsPreloader(true)
        const formData = new FormData();
        formData.append('serial_nr', serialNumber);
        formData.append('platform', assetPlatform.name);
        formData.append('comment', assetComment);
        formData.append('is_documents', isDocuments);
        formData.append('condition', isNew);
        if (supplier) formData.append('supplier', supplier);
        if (price !== null) formData.append('purchase_price', price);
        if (buyDate !== null) formData.append('purchase_date', buyDate.toISOString().split('T')[0]);
        formData.append('is_in_box', isBox);
        if (isWarehouse === false || isWarehouse === 'false') {
            formData.append('equipment_location', 'tests');
            formData.append('testing_organisation', assetOrg);
            if (patchDate !== null)
                formData.append('testing_end_date', patchDate.toISOString().split('T')[0]);
        } else formData.append('equipment_location', 'warehouse');

        if (files !== null) {
            for (let i = 0; i < files.length; i = i + 1) {
                formData.append(`images[${i}]image`, files[i])
            }
        }
        await WarehouseService.newAsset(formData)
        .then(() => { setTimeout(() => { setIsPreloader(false); props.setIsAddingAsset(false) }, 1000) })
        .catch((err) => {
            if (err.message[0].includes('token')) refreshToken();
            else {
                setErrors(err.message); setIsPreloader(false);
            }
        })
    }

    const calculateFileSize = (selectFiles) => {
        const fileSizeBoolean = Array.from(selectFiles).some((item) => item.size > 5000000);
        if (!fileSizeBoolean) { setErrors([]); setFiles(selectFiles) }
        else setErrors(['Размер файла не должен превышать 5 Мб'])
    }
    const dateCheckValid = (date) => {
        if (date !== null) date.setHours(15, 0, 0, 0);
        setPatchDate(date)
    }

    return (
        <Form id="form" onSubmit={saveAsset} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            props.setIsAddingAsset(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit" >
                            Добавить 
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Серийный номер устройства</Form.Label>
                        <Form.Control size="sm" type="text" value={serialNumber} required={true} placeholder={'Введите серийный номер'}
                            onChange={(e) => setSerialNumber(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Платформа</Form.Label>

                        <Dropdown style={!validPlatform ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                Платформа: {assetPlatform?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assPlat'}
                                filterQa={true}
                            >
                                {allPlatforms?.map(platform =>
                                    <Dropdown.Item eventKey={"e" + platform.id} key={"e" + platform.id} onClick={() => { setAssetPlatform(platform); setValidPlatform(true) }}>{platform.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group>
                        <Form.Label size="sm">Поставщик (опционально)</Form.Label>
                        <Form.Control size="sm" type="text" placeholder={''}
                            onChange={(e) => setSupplier(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Есть документы</Form.Label>
                        <Form.Select size="sm" id="isDocs" onChange={(e) => setIsDocuments(e.target.value)} >
                            <option value={true}>Да</option>
                            <option value={false}>Нет</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Состояние</Form.Label>
                        <Form.Select size="sm" id="isNew" onChange={(e) => setIsNew(e.target.value)} >
                            <option value={'new'}>New</option>
                            <option value={'mint'}>Mint</option>
                            <option value={'near-mint'}>Near-mint</option>
                            <option value={'used'}>Used</option>
                            <option value={'parts'}>Parts</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Устройство в коробке</Form.Label>
                        <Form.Select size="sm" id="isBox" onChange={(e) => setIsBox(e.target.value)} >
                            <option value={true}>Да</option>
                            <option value={false}>Нет</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>


                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Где находится</Form.Label>
                        <Form.Select size="sm" id="isDocs" onChange={(e) => setIsWarehouse(e.target.value)} >
                            <option value={true}>Склад</option>
                            <option value={false}>Тестируется</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                {(isWarehouse === false || isWarehouse === 'false') && <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Организация</Form.Label>

                        <Dropdown style={!valid ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                Организация: {assetOrg || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assOrg'}
                                filterQa={true}
                            >
                                {allOrganisations?.map(org =>
                                    <Dropdown.Item eventKey={"e" + org.id} key={"e" + org.id} onClick={() => { setAssetOrg(org.name); setVaild(true) }}>{org.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>}
                {(isWarehouse === false || isWarehouse === 'false') && <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Выберите дату завершения</Form.Label>
                        <DatePicker
                            dateFormat="dd.MM.yyyy"
                            selected={patchDate}
                            onChange={(date) => { dateCheckValid(date) }}
                            locale="ru"
                        />
                    </Form.Group>
                </Col>}

            </Row>
            <Row>
                <Col className="mb-3 col-4">
                    <Form.Group>
                        <Form.Label size="sm">Стоимость закупки</Form.Label>
                        <Form.Control size="sm" type="text" value={price} placeholder={'Стоимость закупки'}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-3">
                    <Form.Group className="mb-3">
                        <Form.Label size="sm">Выберите дату закупки</Form.Label>
                        <DatePicker

                            dateFormat="dd.MM.yyyy"
                            selected={buyDate}
                            onChange={(date) => { date.setHours(15, 0, 0, 0); setBuyDate(date) }}
                            locale="ru"
                        />
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Фото (опционально)</Form.Label>
                        <Form.Control
                            type="file"
                            multiple="multiple"
                            size="sm"
                            style={{ width: '100%' }}
                            onChange={(e) => { calculateFileSize(e.target.files) }}
                            accept="/image*,.png,.jpg,.jpeg"
                        />


                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Комментарий (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={assetComment || ""}
                            size="sm"
                            onChange={(e) => setAssetComment(e.target.value)}
                        />
                    </Form.Group>
                </Col>

            </Row>
            <hr />
        </Form >
    );
}
export default AddWarehouseForm;
