import React from 'react'
import './Preloader.css'

const Preloader = ({leftCoord, centerTable, mini}) => {
  
    return (
        <div className="preloader" style={leftCoord && {left: '40%', right: '60%', bottom: centerTable? '300px': null, top: centerTable? 0: '30%'}}>
            <div className={!mini?"preloader__container": "preloader__container_mini"}>
                <span className={!mini? "preloader__round": "preloader__round_mini"}></span>
            </div>
        </div>
    )
};

export default Preloader;
