import Api from '.';

export default class EdlService {

    static async getList(filters, nextpage) {
        var url = 'api/v1/edl/access/'

        const method = 'GET';
        const params = {};

        if (filters) {

            if (filters.searchText) {
                params.search = filters.searchText;
            };
            if (filters.statusFilter) {
                params.is_active = filters.statusFilter === 'Активен' ? 'true' : 'false';
            }
            if(filters.is_trial === true) params.is_trial = true
            else params.is_trial = false
            if (filters.has_lists) params.has_lists = filters.has_lists;
        };
        if (nextpage) params.page = nextpage
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async newInstance(item) {
        const url = 'api/v1/edl/access/';
        const method = 'POST';
        const data = item;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async getEdlStats() {

        const url = `api/v1/edl/access/kfeed-info/`;
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }
    static async getItem(id) {

        const url = `api/v1/edl/access/${id}/`;
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }

    static async patchItem(item, id) {
        const url = `api/v1/edl/access/${id}/`;
        const data = item;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    static async switchKfeedSubscription(id, kfeed) {
        const url = `api/v1/edl/access/${id}/change-subscription-extras/`;
        const data = {kfeed: kfeed}
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    static async patchItemSub(item, id) {
        const url = `api/v1/edl/access/${id}/switch-subscription/`;
        const data = item;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    static async deleteItem(id) {
        const url = `api/v1/edl/access/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

    static async getSubsList(nextpage) {
        var url = 'api/v1/edl/subscriptions/'

        const method = 'GET';
        const params = {};

        if (nextpage) {
            url = process.env.REACT_APP_API_SERVER.includes('localhost') ? nextpage : nextpage.slice(23);
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async getSubsListDropdown() {
        var url = 'api/v1/edl/subscriptions/dropdown/'

        const method = 'GET';
        const params = {};

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async newSub(item) {

        const url = 'api/v1/edl/subscriptions/';
        const method = 'POST';
        const data = item;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async patchSub(item, id) {
        const url = `api/v1/edl/subscriptions/${id}/`;
        const data = item;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
}