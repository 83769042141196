import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import DocumentationService from "../../API/DocumentationService";
import ImgRemove from '../../icons/remove.png';
function AddDocumentationForm({ open, setSuccessAdd, successAdd }) {

    const [errors, setErrors] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [description, setDescription] = useState('');
    const [service, setService] = useState('url_f');
    const [files, setFiles] = useState([]);
    const [fileName, setFileName] = useState('');
    const [isPublic, setIsPublic] = useState(false)
    // const [filesCount, setFilesCount] = useState([]);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveInstance();

            })
            .catch(() => changeStatus())
    }


    const saveInstance = async event => {
        setIsPreloader(true);
        if (event) event.preventDefault();
        const formData = new FormData();
        formData.append('service', service);
        formData.append('description', description);
        formData.append('name', fileName);
        formData.append('doc_file', files);
        formData.append('is_public', isPublic)
        // if (files.length > 0) {
        //     for (let i = 0; i < files.length; i = i + 1) {
        //         const item = document.getElementById('nameFile' + i);

        //         formData.append(`files[${i}]name`, item.value)
        //         formData.append(`files[${i}]doc_file`, files[i].file)
        //     }
        // }
        await DocumentationService.newInstance(formData)
            .then(() => { open(false); setIsPreloader(false); setSuccessAdd(!successAdd) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })


    }
    // const nameFile = (index) => {
    //     const item = document.getElementById('nameFile' + index);

    //     return item.value;
    // }

    // const validateFiles = (value, index) => {
    //     let arr = [];
    //     arr = files?.filter((item) => item.field !== index);
    //     setFiles([...arr, { file: value, name: nameFile(index), field: index }])
    // }

    return (
        <Form id="form" onSubmit={saveInstance} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}

<Row>
<Col className="col-2 mb-3">
                    <div
                        className="form-check form-switch"
                        style={{
                            minHeight: 0, display: "flex", flexDirection: "column"
                        }}
                    >
                           <Form.Label size="sm" style={{marginLeft: '-40px'}}>Публичный</Form.Label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="statusChecks"
                            checked={isPublic}
                            style={{ height: "25px", width: "50px"}}
                            onChange={(evt) => setIsPublic(evt.target.checked)}
                        />
                    </div>
                </Col>
</Row>
            <Row>
                <Col className="col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Сервис</Form.Label>
                        <Form.Select size="sm" id="service" onChange={(e) => { setService(e.target.value) }} >
                            <option value={'url_f'}>url_f</option>
                            <option value={'dtl'}>dtl</option>
                            <option value={'sandbox'}>sandbox</option>
                            <option value={'common'}>common</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

            </Row>
            <Row >

                <Col className="mb-3 col-4">
                    <Form.Group>
                        <Form.Label size="sm">Название файла</Form.Label>
                        <Form.Control size="sm" type="text" id={'nameFile'} required={true} placeholder={'Введите название'}

                        onChange={(e)=> setFileName(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Файл</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            required={true}
                            style={{ width: '100%' }}
                            onChange={(e) => {setFiles(e.target.files[0])}}
                        // onChange={(e) => { validateFiles(e.target.files[0], index) }}
                        // setFiles([...files, { file: e.target.files, name: nameFile(index,), field: index }]) 
                        // accept="/image*,.png,.jpg,.jpeg"
                        />


                    </Form.Group>
                </Col>
                <Row>
                    <Col sm={4}>
                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Описание</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                type="text"
                                placeholder="Описание "
                                value={description || ""}
                                size="sm"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {/* <Col>
                    <img width="20" className="interactive_hover" alt={'delete'} src={ImgRemove} style={{ marginTop: '33px' }}
                        onClick={() => { setFilesCount(filesCount.filter((it) => it.count !== item.count)); setFiles(files.filter((it) => it.field + 1 !== item.count)) }} /></Col> */}
            </Row>
            {/* <Row>
                <Col className="mb-3 col-4">
                    <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                        onClick={() => { setFilesCount([...filesCount, { count: filesCount.length + 1 }]) }}
                    >Добавить документ</Button></Col></Row> */}
            <hr />
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            open(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Добавить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
    );
}
export default AddDocumentationForm;
