import axios from 'axios';
import Api from '.';

export default class PimService {
    //SERVERS
    static async getPimServersSoftList(page) {
        var url = 'api/v1/pim/server-release/'
        const method = 'GET';
        const params = {};
        params.limit = 10;
        params.page = page;
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async getPimServerDetail(id) {
        const url =  `api/v1/pim/server-release/${id}/`;
        const method = 'GET';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);
    
        return response.data;
    }
    static async newServersMajorRelease(formData) {
        const url = 'api/v1/pim/server-release/';
        const method = 'POST';
        const data = formData;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async newServersRelease(formData, id) {
        const url = `api/v1/pim/server-release/${id}/add-package/`;
        const method = 'POST';
        const data = formData;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async getPimServerMajorReleaseDropdown() {
        var url = 'api/v1/pim/server-release/major-versions-dropdown/'
        const method = 'GET';
        const params = {};
    
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async deleteServerInstance(id, type, idRelease) {
        const url =type==='major'? `api/v1/pim/server-release/${id}/` : `api/v1/pim/server-release/${id}/${idRelease}`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }
    static async patchInstanceServer(item, id) {
        const url = `api/v1/pim/server-release/${id}/`;
        const data = item;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }
    //DOCS
    static async getPimDocumentationList(page) {
        var url = 'api/v1/pim/docs/'
        const method = 'GET';
        const params = {};
        params.limit = 5;
        params.page  = page
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async newPimDocs(formData) {
        const url = 'api/v1/pim/docs/';
        const method = 'POST';
        const data = formData;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async deletePimDoc(id) {
        const url = `api/v1/pim/docs/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }
    static async downloadPimDocs(file) {
    
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            responseType: 'arraybuffer',
        };

        const response = await axios.get(file.doc_file, config);
        return response;
    }
    static async patchPimDoc(item, id) {
        const url = `api/v1/pim/docs/${id}/`;
        const data = item;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
    
        return response.data;
    }
//AGENTS
static async getPimAgentsSoftList(page) {
    var url = 'api/v1/pim/agent-release/'
    const method = 'GET';
    const params = {};
    params.limit = 10;
    params.page = page;
    const api = new Api({ url: url, params: params });
    const response = await api.request(method);
    return response.data;
};
static async getPimAgentsReleaseDropdown() {
    var url = 'api/v1/pim/agent-release/major-versions-dropdown/'
    const method = 'GET';
    const params = {};

    const api = new Api({ url: url, params: params });
    const response = await api.request(method);
    return response.data;
};
static async newAgentRelease(formData) {
    const url = 'api/v1/pim/agent-release/';
    const method = 'POST';
    const data = formData;
    const api = new Api({ url: url });
    const response = await api.request(method, data);
    return response.data;
};
static async addAgentReleasePackage(formData, id) {
    const url = `api/v1/pim/agent-release/${id}/add-package/`;
    const method = 'POST';
    const data = formData;
    const api = new Api({ url: url });
    const response = await api.request(method, data);
    return response.data;
};

static async deleteAgentInstance(id, type, major) {
    const url = type==='major'? `api/v1/pim/agent-release/${id}/`: `api/v1/pim/agent-release/${major}/${id}/`;
    const method = 'DELETE';
    const api = new Api({ url: url, headers: {}, params: {} });
    const response = await api.request(method);

    return response.data;
}
static async getPimDetail(id, type) {
    const url = type==='server'? `api/v1/pim/server-release/${id}/`: `api/v1/pim/agent-release/${id}/`;
    const method = 'GET';
    const api = new Api({ url: url, headers: {}, params: {} });
    const response = await api.request(method);

    return response.data;
}
static async patchInstanceAgent(item, id) {
    const url = `api/v1/pim/agent-release/${id}/`;
    const data = item;
    const method = 'PATCH';
    const api = new Api({ url: url, headers: {}, params: {} });
    const response = await api.request(method, data);

    return response.data;
}
//Compatible
static async getPimCompatibleList(page) {
    var url = 'api/v1/pim/compatibility/'
    const method = 'GET';
    const params = {};
    params.limit = 10;
    params.page = page;
    const api = new Api({ url: url, params: params });
    const response = await api.request(method);
    return response.data;
};
static async newPimCompatible(formData) {
    const url = 'api/v1/pim/compatibility/';
    const method = 'POST';
    const data = formData;
    const api = new Api({ url: url });
    const response = await api.request(method, data);
    return response.data;
};
static async patchPimCompatible(formData, id) {
    const url = `api/v1/pim/compatibility/${id}/`;
    const method = 'PATCH';
    const data = formData;
    const api = new Api({ url: url });
    const response = await api.request(method, data);
    return response.data;
};
static async deletePimCompatible(id) {
    const url = `api/v1/pim/compatibility/${id}/`;
    const method = 'DELETE';
    const api = new Api({ url: url });
    const response = await api.request(method);
    return response.data;
};


}