import React, { useEffect, useState, useContext } from "react";
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import User from "../../API/User";
import UserCreateForm from "./UserCreateForm";
import { UserContext } from "../../context";
import Preloader from "../UI/Preloader/Preloader";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import Row from "react-bootstrap/esm/Row";

function UserList(props) {
    // eslint-disable-next-line no-unused-vars
    const { userinfo, setUserinfo } = useContext(UserContext);
    const userRole = userinfo.role;
    const basicStyles = {
        "position": 'relative',
        "fontSize": 'smaller',
    }
    const [isPreloader, setIsPreloader] = useState(false)
    const [userList, setUserList] = useState();
    const [userChanged, setUserChanged] = useState(false);
    const [isUserAdding, setIsUserAdding] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    // const [searchText, setSearchText] = useState("");

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (!deleteItem) {
        (async () => {
            if (userChanged) {
                setUserChanged(false);
            };
            if (!props.orgId) {
                // const filters = {'searchText':searchText};
                const org = await OrgService.my();
                
                setUserList(org.users);
            } else {
                setIsPreloader(true);
                const org = await OrgService.getItem(props.orgId).finally(()=> setIsPreloader(false));
                setUserList(org.users);
            }

        })();}

    }, [userChanged, props.userChanged, props.orgId, deleteItem]);

    async function deactivate(id) {
        try {
            await User.deactivate(id);
            // setOrgAdded(true);
            setUserChanged(true);
            setErrors([]);
        } catch (e) {
            setErrors(e.message);

        };
    }

    async function activate(id) {
        try {
            await User.activate(id);
            // setOrgAdded(true);
            setUserChanged(true);
            setErrors([]);
        } catch (e) {
            setErrors(e.message);

        };
    }

    async function makePrimary(id) {
        try {
            await User.makePrimary(id);
            // setOrgAdded(true);
            setUserChanged(true);
            setErrors([]);
        } catch (e) {
            setErrors(e.message);

        };
    }
    const deleteUser = async (id) => {
        setIsPreloader(true);
        await User.deleteUser(id)
            .then(() => {
                setIsPreloader(false);
                setDeleteItem(false);
            })
            .catch((e) => {
                // if (e.message[0].includes("token")) refreshToken("delete");
                
                    setIsPreloader(false);
                    setErrors(e.message);
                
            });
    };

    return (
        <Col sm={10}>
            <div><h5>Список пользователей</h5></div>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {!props.orgId && userRole!=='MANAGER' && userRole!=='SALES' && userRole!=="USER" &&
                <Button variant="outline-primary" size="sm" onClick={() => setIsUserAdding(true)}>Добавить пользователя</Button>
            }
            {
                (isUserAdding)
                &&
                <UserCreateForm
                type={props.orgType}
                    setIsUserAdding={setIsUserAdding}
                    setUserChanged={setUserChanged}
                />
            }
            {deleteItem?.open && (
                <MyConfirm visible={deleteItem?.open} setVisible={setDeleteItem}>
                    <Row className="mb-4" style={{ position: "relative" }}>
                        {isPreloader && <Preloader leftCoord={true} />}
                        <h3 className="mb-4 mt-4">
                            Вы точно хотите удалить пользователя{' '}
                            {deleteItem?.user?.last_name}?
                        </h3>
                    </Row>
                    <Row>
                        {" "}
                        {errors?.length > 0 &&
                            errors?.map((error) => (
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            ))}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                type="submit "
                                style={{ width: "100%" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    deleteUser(deleteItem?.user?.id);
                                }}
                            >
                                Да
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setDeleteItem(false);
                                    setErrors([]);
                                }}
                                style={{ width: "100%" }}
                            >
                                нет
                            </Button>
                        </Col>
                    </Row>
                </MyConfirm>
            )}
                                  
            <Table striped bordered hover>
                <thead style={basicStyles}>
                    <tr>
                        <th>Фамилия</th>
                        <th>Имя</th>
                        <th>Email (логин)</th>
                        <th>Роль</th>

                        <th>Должность</th>
                        <th>Телефон</th>

                        <th>Передать права</th>
                        <th>Статус</th>
                        <th>Дата входа</th>
                        {props?.orgType === 'ENDUSER' || props?.orgType === 'PARTNER' && <th>Удалить</th>}
                    </tr>

                </thead>
                <tbody style={basicStyles}>
                    {userList?.map(user =>
                        <tr key={user.id}>
                            <td>
                                {user.last_name}
                            </td>
                            <td>
                                {user.first_name}
                            </td>
                            <td>
                                {user.email}
                            </td>
                            <td>
                                {user.role}
                            </td>

                            <td>
                                {user.job_title}
                            </td>
                            <td>
                                {user.phone}
                            </td>
                            <td>
                                {(user.is_active && user.role !== "PRIMARY_USER" && userRole === "PRIMARY_USER") &&user.role!=='MANAGER'&&
                                    <Button variant="outline-success" size="sm" onClick={async () => await makePrimary(user.id)} >Сделать основным</Button>
                                }
                            </td>
                            <td>
                                {(user.is_active && userRole === "PRIMARY_USER")
                                    ?
                                    <Button variant="outline-danger" size="sm" onClick={async () => await deactivate(user.id)} >Деактивировать</Button>
                                    :
                                    userRole === "PRIMARY_USER" &&
                                    <Button variant="outline-primary" size="sm" onClick={async () => await activate(user.id)} >Активировать</Button>
                                }
                            </td>
                            <td>
                                {user.last_login}
                            </td>
                                {props?.orgType==='ENDUSER' || props?.orgType === 'PARTNER'&& user.role!=='MANAGER' &&<td>
                                {
                                    <Button variant="outline-danger" size="sm" onClick={()=> setDeleteItem({open: true, user: user})} disabled={user.is_active}>Удалить</Button>
                                }
                            </td>}
                        </tr>
                    )}
                </tbody>

            </Table>
            {isPreloader &&  <Preloader />}
        </Col>
    );
}
export default UserList;