import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import UrlService from '../../../API/UrlService';
import Preloader from '../../UI/Preloader/Preloader';
function Graph({sn, visible, dateAfter=null, dateBefore= null}) {
  const [statsData, setStatsData] = useState([]);
  const [isPreloader, setIsPreloader] = useState(false);
  const [errors, setErrors] = useState(null);

  const getDataLabel=(after, before)=> {
    let str =''
    if (!after&&!before) str = 'за последние 30 дней'
    else str = (after? after.split('-')?.reverse().join('.') + ' - ': ' - ') + (before? before.split('-')?.reverse().join('.'): '')
    return str
  }
  useEffect(() => {
    (async () => {
  if (visible){
        setIsPreloader(true);
        await UrlService.getKfeedDataGraph(sn, dateAfter, dateBefore).then((res) => {
             setStatsData(res);
  
         }).catch((e) => setErrors(e.message)).finally(()=>  setIsPreloader(false))

       }

    })();
}, [sn, dateAfter, dateBefore]);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip, Filler,
    Legend,
  );
  const options = {
    plugins: {
        title: {
            display: false,
            text: 'Stacked Bar Chart',
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          itemSort: function(a, b) {
              const order = ['Прямые', 'Дополнительные'];
              return order.indexOf(a.dataset.label) - order.indexOf(b.dataset.label);
          },
          callbacks: {
              footer: (tooltipItems) => {
                  let sum = 0;
                  tooltipItems.forEach(function(tooltipItem) {
                      sum += tooltipItem.parsed.y;
                  });
                  return 'Всего попаданий: ' + sum;
              }
          }
      },

      legend: {
        position: 'top',
        reverse: true
    },
        datalabels: {
            display: false,
        },
      //   datalabels: {
      //     display: true,
      //     align: 'end',
      //     anchor: 'end',
      //     formatter: (value, context) => {
      //         const datasets = context.chart.data.datasets;
      //         const index = context.dataIndex;
      //         let sum = 0;
      //         datasets.forEach(dataset => {
      //             sum += dataset.data[index];
      //         });
      //         if (context.datasetIndex === datasets.length - 1) {
      //             return sum;
      //         }
      //         return '';
      //     }
      // }
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
            ticks: {
              font: {
                size: 8
              }
            }
        },
        y: {
            stacked: true,
        },
    },
};



  const data = {
    labels: statsData !== null ? statsData.map((item, index) => { return item.date }) : '',
    datasets: [
      {
          label: 'Дополнительные',
          data: statsData.map(item => item.secondary_cnt),
          backgroundColor: 'rgba(255, 206, 86, 0.8)',
          stack: 'Stack 0',
      },
      {
          label: 'Прямые',
          data: statsData.map(item => item.direct_cnt),
          backgroundColor: '#f5222d',
          stack: 'Stack 0',
      },
      
  ],

  };
  return (
    <div style={{ width: '1000px', position: 'relative'}}> 
{isPreloader && <Preloader/>}
       <div style={{  opacity: isPreloader? 0.1: 1 , }}> 
        <span style={{ fontSize: '18px', fontWeight: 600}}>Кол-во запросов по kfeed/день {getDataLabel(dateAfter, dateBefore)}</span>
        {visible && <Bar options={options} data={data} />}</div></div>
  )
}

export default Graph;